import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { NextButton } from 'components/next-button';
import { Typography } from 'components/typography';
import { ModalWindow } from 'components/modal-window';

import { TypographyVariants } from 'constants/typography-variants';

import { useStyles } from './styles';

// @ts-ignore
export const SkipUpgradeModalWindow: any = ({ handleClick }) => {
    const { t } = useTranslation();

    const { modalWrapper, title, graphBlock, discountBlock, discountTitle, continueButton, info } = useStyles();

    const items = ['svg/upgrade/graph.svg', 'svg/upgrade/discount.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <ModalWindow modalWindowFormClassName={modalWrapper} hasCloseIcon={false}>

            <Typography variant={TypographyVariants.h2} className={title}>
                <Trans i18nKey='upgrade-skip-upgrade-modal-title' components={[<span></span>]} />
            </Typography>

            <div className={graphBlock}>
                <img src={pageImages[0]} alt='Graph' />
            </div>

            <div className={discountBlock}>
                <img src={pageImages[1]} alt='Discount' />
                <Typography variant={TypographyVariants.h3} className={discountTitle}>
                    <Trans i18nKey='upgrade-skip-upgrade-modal-discount' components={[<span></span>]} />
                </Typography>
            </div>

            <NextButton
                className={continueButton}
                typographyText={t('upgrade-skip-upgrade-modal-button-next')}
                onClick={handleClick} />

            <Typography variant={TypographyVariants.h3} className={info}>
                {t('upgrade-skip-upgrade-modal-info')}
            </Typography>

        </ModalWindow>
    );
};
