import React from 'react';

import { TipPage } from 'components/tip-page';

export const BehaviourChangeTip = () => {
    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/praying-hands.svg'
            text={{
                description: 'behaviour-change-tip-description',
                next: 'behaviour-change-tip-button-next'
            }}
        />
    );
};
