import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const BodyBreastfeeding = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: bodyBreastfeeding, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyBreastfeeding],
        defaultValue: '',
    });

    const handleClick = (goal: ChoiceType) => {
        saveAnswers({
            key: 'body-breastfeeding',
            itemQuestion: { question: t('body-breastfeeding-title'), value: t(goal.value) },
        });

        setPageValue(goal.key);

        pageConfiguration.handleOnPageComplete(goal.key);
    };

    const breastfeedingAnswers: ChoiceType[] = [
        { key: 'yes', value: t('body-breastfeeding-yes') },
        { key: 'no', value: t('body-breastfeeding-no') },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                <Trans i18nKey={'body-breastfeeding-title'} />
            </Typography>
            {breastfeedingAnswers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === bodyBreastfeeding}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
