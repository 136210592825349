import { ChoiceType } from 'components/choice-button';

export const AGE_KEYS = {
    underTwenty: 'underTwenty',
    twenties: 'twentys',
    thirties: 'thirtys',
    forties: 'fortys',
    fifties: 'fiftys',
    sixtyPlus: 'sixtyPlus'
};

export const AGES: ChoiceType[] = [
    { key: AGE_KEYS.underTwenty, value: 'body-age-option-underTwenty' },
    { key: AGE_KEYS.twenties, value: 'body-age-option-twenties' },
    { key: AGE_KEYS.thirties, value: 'body-age-option-thirties' },
    { key: AGE_KEYS.forties, value: 'body-age-option-forties' },
    { key: AGE_KEYS.fifties, value: 'body-age-option-fifties' },
    { key: AGE_KEYS.sixtyPlus, value: 'body-age-option-sixtyPlus' },
];

export const UserAgeInputKey = 'userAgeInput';
