import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    popoverWrapper: {
        position: 'absolute',
        display: 'flex',
        columnGap: '10px',
        opacity: 0,
        animation: 'opacityAnimation 0.7s ease-out 0.7s forwards'
    },
    dotSymbol: {
        borderRadius: '50%',
        width: '13px',
        minWidth: '13px',
        height: '13px',
        backgroundColor: '#36B992',
        border: '3px solid #FFFFFF'
    },
    text: {
        color: '#36B992',
        fontFamily: 'SF Pro Text',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '14px',
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    popoverInSmallChartView: {},
    popoverOnPageWith24PaddingOnTheSidesView: {},
    l_p_2_0: {
        top: '69%',
        left: '53%',
        width: '28%'
    },
    l_p_3_0: {
        top: '7%',
        left: '10%',

        '&$popoverInSmallChartView': {
            top: '-0.5%',

            '& span[class^="text"]': {
                marginTop: '-5px'
            }
        }
    },
    l_p_3_1: {
        top: '69%',
        left: '26%',
        width: '29%',
        flexDirection: 'row-reverse',

        '&$popoverInSmallChartView': {
            top: '58%',
            left: '21%'
        }
    },
    l_p_4_1: {
        top: '50%',
        left: '38%',

        '&$popoverInSmallChartView': {
            top: '44%'
        }
    },
    l_p_4_2: {
        top: '81%',
        left: '41%',
        width: '29%',
        flexDirection: 'row-reverse',

        '& span[class^="text"]': {
            marginTop: '5px'
        },

        '&$popoverInSmallChartView': {
            top: '77.5%',

            '& span[class^="text"]': {
                marginTop: 0
            }
        }
    },
    l_p_5_1: {
        top: '39%',
        left: '31.5%',

        '&$popoverInSmallChartView': {
            left: '34.5%',

            '& span[class^="text"]': {
                fontSize: '13px'
            }
        }
    },
    l_p_5_2: {
        top: '68%',
        left: '27%',
        width: '28%',
        flexDirection: 'row-reverse',

        '&$popoverInSmallChartView': {
            top: '64%',

            '& span[class^="text"]': {
                marginTop: '5px',
            },
        }
    },
    l_p_5_3: {
        top: '85%',
        left: '50%',
        width: '28%',
        flexDirection: 'row-reverse',

        '& span[class^="text"]': {
            marginTop: '5px'
        },

        '&$popoverInSmallChartView': {
            top: '81%',
            left: '47%',

            '& span[class^="text"]': {
                marginTop: '3px',
                fontSize: '11px'
            },
        }
    },
    l_p_6_1: {
        top: '35%',
        left: '28%',

        '&$popoverInSmallChartView': {
            top: '27%'
        }
    },
    l_p_6_2: {
        top: '58%',
        left: '44%',

        '&$popoverInSmallChartView': {
            top: '54%',

            '& span[class^="text"]': {
                fontSize: '12px',
                marginTop: '-18px',
                marginLeft: '-20px',
            }
        }
    },
    l_p_6_3: {
        top: '76%',
        left: '35%',
        width: '28%',
        flexDirection: 'row-reverse',

        '&$popoverInSmallChartView': {
            top: '73%'
        }
    },
    l_p_6_4: {
        top: '87%',
        left: '57%',
        width: '27%',
        flexDirection: 'row-reverse',

        '& span[class^="text"]': {
            marginTop: '6px'
        },

        '&$popoverInSmallChartView': {
            top: '84.5%'
        }
    },
    l_p_12_1: {
        top: '16%',
        left: '18%',

        '&$popoverInSmallChartView': {
            top: '10%',
        }
    },
    l_p_12_3: {
        top: '49%',
        left: '38%',

        '&$popoverInSmallChartView': {
            top: '45%',
        }
    },
    l_p_12_5: {
        top: '70%',
        left: '27%',
        width: '29%',
        flexDirection: 'row-reverse',

        '&$popoverInSmallChartView': {
            left: '31%',
            flexDirection: 'row-reverse',

            '& span[class^="text"]': {
                marginTop: '5px'
            }
        }
    },
    '@media (max-width: 540px)' : {
        l_p_3_1: {
            top: '69.5%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '69%'
            }
        },
        l_p_4_2: {
            top: '81.5%'
        },
        l_p_5_1: {

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '39%'
            }
        },
        l_p_5_2: {
            left: '27%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '27%'
            }
        },
        l_p_5_3: {

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '86%',
                left: '53%'
            }
        },
        l_p_6_1: {
            left: '29%'
        },
        l_p_6_3: {
            top: '77%',
            width: '29%',
        },
        l_p_6_4: {
            top: '86%',
            left: '55%'
        },
        l_p_12_5: {
            left: '25%',
            width: '31%',
        },
    },
    '@media (max-width: 500px)' : {},
    '@media (max-width: 480px)' : {},
    '@media (max-width: 460px)' : {},
    '@media (max-width: 440px)' : {},
    '@media (max-width: 428px)' : {
        l_p_2_0: {
            left: '25%',
            width: '32%',
            flexDirection: 'row-reverse'
        },
        l_p_3_0: {
            top: '5%'
        },
        l_p_3_1: {
            left: '19%',
            width: '39%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '70%'
            }
        },
        l_p_4_1: {
            top: '48%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '47%'
            }
        },
        l_p_4_2: {
            top: '79%',
            left: '30%',
            width: '39%'
        },
        l_p_5_1: {
            top: '37%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '37%'
            }
        },
        l_p_5_2: {
            left: '20%',
            width: '37%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '20%',

                '& span[class^="text"]': {
                    fontSize: '13px'
                }
            }
        },
        l_p_5_3: {
            top: '84.5%',
            left: '42%',
            width: '38%',

            '& span[class^="text"]': {
                marginTop: '3px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '84.5%',
                left: '42%',

                '& span[class^="text"]': {
                    fontSize: '11px'
                }
            }
        },
        l_p_6_1: {
            left: '30%'
        },
        l_p_6_2: {
            left: '10%',
            flexDirection: 'row-reverse',
            width: '38%',

            '& span[class^="text"]': {
                fontSize: '13px'
            }
        },
        l_p_6_3: {
            left: '29%',
            width: '37%'
        },
        l_p_6_4: {
            top: '85%',
            left: '43%',
            width: '38%',

            '& span[class^="text"]': {
                marginTop: '2px'
            }
        },
        l_p_12_1: {
            top: '9%',
            left: '15%'
        },
        l_p_12_3: {
            left: '38%'
        },
        l_p_12_5: {
            left: '20%',
            width: '38%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '68%',
                left: '19%'
            }
        },
    },
    '@media (max-width: 414px)' : {
        l_p_2_0: {
            left: '24%',
            width: '33%',
        },
        l_p_3_1: {
            left: '17%',
            width: '41%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '69%'
            }
        },
        l_p_4_2: {
            top: '80%',
            width: '41%',
        },
        l_p_5_1: {
            top: '36%'
        },
        l_p_5_2: {
            left: '19%',
            width: '38%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '19%',

                '& span[class^="text"]': {
                    fontSize: '12px'
                }
            }
        },
        l_p_5_3: {

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '84%',
                left: '41%'
            }
        },
        l_p_6_2: {
            left: '10%',
            width: '39%',
        },
        l_p_6_3: {
            left: '27%',
            width: '39%',
        },
        l_p_6_4: {
            left: '42%'
        },
        l_p_12_5: {
            top: '67%',
            left: '15%',
            width: '40%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '70%'
            }
        },
    },
    '@media (max-width: 393px)' : {
        l_p_2_0: {
            top: '68.5%',
            left: '22%',
            width: '35%',
        },
        l_p_3_0: {
            top: '4.5%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {

                '& span[class^="text"]': {
                    marginTop: '-5px'
                }
            }
        },
        l_p_3_1: {
            left: '16%',
            width: '42%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '69%'
            }
        },
        l_p_4_1: {
            top: '47%',

            '& span[class^="text"]': {
                marginTop: '-10px',
                marginLeft: '-10px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                '& span[class^="text"]': {
                    marginTop: '-10px',
                    marginLeft: '-10px'
                }
            }
        },
        l_p_4_2: {
            top: '79%',
            left: '27%',
            width: '44%'
        },
        l_p_5_1: {
            top: '37%'
        },
        l_p_5_2: {
            left: '17%',
            width: '41%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '17%'
            }
        },
        l_p_5_3: {
            top: '82%',
            left: '38%',

            '& span[class^="text"]': {
                marginTop: '3px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '82.5%',
                left: '38%'
            }
        },
        l_p_6_1: {
            left: '30%'
        },
        l_p_6_2: {
            left: '7%',
            width: '42%'
        },
        l_p_6_3: {
            top: '76%',
            left: '24%',
            width: '41%'
        },
        l_p_6_4: {
            top: '84.5%',
            left: '40%',
            width: '40%',
        },
        l_p_12_1: {
            top: '8%'
        },
        l_p_12_3: {
            left: '39%',

            '& span[class^="text"]': {
                marginTop: '-15px',
                marginLeft: '-15px'
            }
        },
        l_p_12_5: {
            left: '13%',
            width: '42%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '68%',
                left: '16%'
            }
        },
    },
    '@media (max-width: 390px)' : {
        l_p_2_0: {
            top: '69%'
        },
        l_p_4_2: {
            left: '26%'
        },
        l_p_5_2: {

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '16.5%'
            }
        },
        l_p_6_4: {
            top: '83.5%',

            '& span[class^="text"]': {
                fontSize: '13px'
            }
        },
        l_p_12_5: {
            left: '14%'
        }
    },
    '@media (max-width: 375px)' : {
        l_p_2_0: {
            left: '21%',
            width: '37%',
        },
        l_p_3_0: {
            top: '4.5%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '3.5%',

                '& span[class^="text"]': {
                    marginTop: '-5px'
                }
            }
        },
        l_p_3_1: {
            left: '14%',
            width: '45%'
        },
        l_p_4_1: {
            top: '46%',

            '& span[class^="text"]': {
                marginTop: '-5px',
                marginLeft: '-5px'
            }
        },
        l_p_4_2: {
            top: '80%',
            width: '46%',
        },
        l_p_5_1: {
            top: '34%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '36%'
            }
        },
        l_p_5_2: {
            left: '15%',
            width: '43%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '16%'
            }
        },
        l_p_5_3: {
            top: '83%',
            width: '41%',

            '& span[class^="text"]': {
                fontSize: '12px',
                marginTop: '2px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '82%',
                left: '36%'
            }
        },
        l_p_6_1: {
            left: '30%'
        },
        l_p_6_2: {
            left: '8%',
            width: '41%',

            '& span[class^="text"]': {
                fontSize: '12px'
            }
        },
        l_p_6_3: {
            top: '75%',
            left: '22%',
            width: '43%',
        },
        l_p_6_4: {
            left: '39%',
            width: '42%',

            '& span[class^="text"]': {
                fontSize: '10px',
                marginTop: '6px'
            }
        },
        l_p_12_3: {

            '& span[class^="text"]': {
                marginTop: '-15px',
                marginLeft: '-15px'
            }
        },
        l_p_12_5: {
            top: '66%',
            left: '10%',
            width: '45%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '13.5%'
            }
        },
    },
    '@media (max-width: 360px)' : {
        l_p_2_0: {
            left: '20%',
            width: '38%',
        },
        l_p_3_0: {
            top: '3.5%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '3.5%',
            }
        },
        l_p_3_1: {
            top: '67%',
            left: '9%',
            width: '48%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '67%'
            }
        },
        l_p_4_1: {
            top: '46%',

            '& span[class^="text"]': {
                marginTop: '-10px',
                marginLeft: '-10px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '46%',

                '& span[class^="text"]': {
                    marginTop: '-15px',
                    marginLeft: '-15px'
                },
            }
        },
        l_p_4_2: {
            top: '78%',
            left: '22%',
            width: '47%'
        },
        l_p_5_1: {
            top: '34%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '35%'
            }
        },
        l_p_5_2: {
            top: '70%',
            left: '16%',
            width: '44%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '17%',

                '& span[class^="text"]': {
                    fontSize: '12px'
                },
            }
        },
        l_p_5_3: {
            top: '83%',
            left: '36%',
            width: '43%',

            '& span[class^="text"]': {
                fontSize: '11px',
                marginTop: '2px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '82%',
                left: '34%',

                '& span[class^="text"]': {
                    marginTop: '2px'
                },
            }
        },
        l_p_6_1: {
            left: '31%',
        },
        l_p_6_2: {
            left: '10%',
            width: '40%',

            '& span[class^="text"]': {
                fontSize: '11px'
            }
        },
        l_p_6_3: {
            top: '74%',
            left: '19%',
            width: '45%'
        },
        l_p_6_4: {
            left: '36%',
            width: '45%',

            '& span[class^="text"]': {
                fontSize: '10px',
                marginTop: '5px'
            }
        },
        l_p_12_1: {
            top: '7%'
        },
        l_p_12_3: {
            left: '39%',

            '& span[class^="text"]': {
                marginTop: '-15px',
                marginLeft: '-15px',
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {

                '& span[class^="text"]': {
                    marginTop: '-20px',
                    marginLeft: '-20px'
                }
            }
        },
        l_p_12_5: {
            top: '66%',
            left: '10%',
            width: '45%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '13.5%'
            }
        },
    },
    '@media (max-width: 320px)' : {
        l_p_2_0: {
            left: '16%',
            width: '43%',
        },
        l_p_3_0: {
            top: '3.5%',

            '& span[class^="text"]': {
                marginTop: '-6px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '3.5%',
            }
        },
        l_p_3_1: {
            left: '10%',

            '& span[class^="text"]': {
                fontSize: '12px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '66%'
            }
        },
        l_p_4_1: {
            top: '45%',

            '& span[class^="text"]': {
                marginTop: '-20px',
                marginLeft: '-20px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '44%'
            }
        },
        l_p_4_2: {
            top: '77%',
            left: '15%',
            width: '55%'
        },
        l_p_5_1: {
            top: '33%',

            '& span[class^="text"]': {
                marginTop: '-15px',
                marginLeft: '-15px',
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '33%'
            }
        },
        l_p_5_2: {
            left: '11%',
            width: '51%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '10.5%'
            }
        },
        l_p_5_3: {
            top: '82%',
            left: '29%',
            width: '50%',

            '& span[class^="text"]': {
                marginTop: '-2px',
                fontSize: '10px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '80%',
                left: '26%',

                '& span[class^="text"]': {
                    fontSize: '9px'
                }
            }
        },
        l_p_6_1: {
            left: '32%',

            '& span[class^="text"]': {
                fontSize: '12px',
                marginTop: '-10px',
                marginLeft: '-10px'
            }
        },
        l_p_6_2: {
            left: '11%',

            '& span[class^="text"]': {
                fontSize: '10px',
            }
        },
        l_p_6_3: {
            left: '14%',
            width: '51%'
        },
        l_p_6_4: {
            top: '83%',
            left: '30%',
            width: '52%',
        },
        l_p_12_1: {
            top: '6%'
        },
        l_p_12_3: {
            left: '10%',
            flexDirection: 'row-reverse',
            width: '34%',

            '& span[class^="text"]': {
                fontSize: '8.5px',
                marginTop: 0,
                marginLeft: 0,
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '40%',
                flexDirection: 'row'
            }
        },
        l_p_12_5: {
            top: '64%',
            left: '10%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '14.5%'
            },

            '& span[class^="text"]': {
                fontSize: '12px'
            }
        },
    },
    '@media (max-width: 280px)' : {
        l_p_2_0: {
            width: '45%',

            '& span[class^="text"]': {
                fontSize: '12px'
            }
        },
        l_p_3_0: {
            top: '1.5%',

            '& span[class^="text"]': {
                marginTop: '-5px',
                marginLeft: '-5px',
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '1.5%',

                '& span[class^="text"]': {
                    marginTop: '-8px',
                },
            }
        },
        l_p_3_1: {
            top: '64%',
            left: '12%',
            width: '45%',

            '& span[class^="text"]': {
                fontSize: '9px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '64.5%',
                left: '14%',

                '& span[class^="text"]': {
                    fontSize: '10px'
                },
            }
        },
        l_p_4_1: {
            top: '42%',

            '& span[class^="text"]': {
                fontSize: '10px',
                marginTop: '-15px',
                marginLeft: '-20px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '42%',

                '& span[class^="text"]': {
                    marginTop: '-25px',
                    marginLeft: '-25px'
                },
            }
        },
        l_p_4_2: {
            top: '76%',

            '& span[class^="text"]': {
                fontSize: '12px',
                marginTop: 0
            }
        },
        l_p_5_1: {
            top: '32%',

            '& span[class^="text"]': {
                fontSize: '13px',
                marginTop: '-17px',
                marginLeft: '-15px',
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '32%'
            }
        },
        l_p_5_2: {
            left: '12%',
            width: '52%',

            '& span[class^="text"]': {
                fontSize: '12px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '13%',

                '& span[class^="text"]': {
                    fontSize: '11px'
                }
            }
        },
        l_p_5_3: {
            top: '81%',
            left: '22%',
            width: '57%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '79%',
                left: '18%'
            }
        },
        l_p_6_1: {
            left: '33%',

            '& span[class^="text"]': {
                marginTop: '-20px',
                marginLeft: '-20px',
            }
        },
        l_p_6_2: {
            left: '11%',
            width: '42%',

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                left: '12%'
            }
        },
        l_p_6_3: {
            top: '71%',
            left: '12%',

            '& span[class^="text"]': {
                fontSize: '12px'
            }
        },
        l_p_6_4: {
            left: '23%',
            width: '60%'
        },
        l_p_12_1: {
            top: '4%'
        },
        l_p_12_3: {
            left: '12%',

            '& span[class^="text"]': {
                fontSize: '7px'
            }
        },
        l_p_12_5: {
            top: '63%',
            left: '13.5%',
            width: '43%',

            '& span[class^="text"]': {
                fontSize: '9px'
            },

            '&$popoverOnPageWith24PaddingOnTheSidesView': {
                top: '63%'
            }
        },
    },
    futureText: {
        width: 'auto',
        top: '0%',
        right: '0%',
        left: 'unset',

        '@media (max-width: 280px)' : {
            '& span[class^="text"]': {
                fontSize: '12px'
            }
        }
    }
});
