import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { UserVerificationKeys } from 'constants/user-verification';
import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';
import { PASSWORD, USER_STATUS } from 'constants/payment';
import { BreadcrumbsNavigationKeys } from 'constants/breadcrumbs-navigation';

import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';
import { detectIosDevice, isDesktop } from 'helpers/detect-device-type';
import { getAppType } from 'helpers/get-app-type';

import { MobileContent } from 'pages/welcome/mobile-content';

import { Typography } from 'components/typography';
import { BreadcrumbsNavigation } from 'components/breadcrumbs-navigation';

import ableLogo from 'assets/images/svg/welcome/able-logo.svg';
import vibrantLogo from 'assets/images/svg/welcome/vibrant-logo.svg';
import trainerizeLogo from 'assets/images/svg/welcome/trainerize-logo.svg';
import qrCode from 'assets/images/png/welcome/qr-code.png';
import qrCodeTrainerize from 'assets/images/png/welcome/qr-code-trainerize.png';

import { CopyToClipboardButton } from 'pages/welcome/copy-to-clipboard-button';
import { TrainerizeLinks, VibrantLifeLinks } from 'pages/welcome/app-links/app-links';

import { useStyles } from './styles';

export const Welcome = () => {
    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    const { t } = useTranslation();

    const {
        logosWrapper,
        welcomeDesktopInfoWrapper,
        welcomeDesktopTitle,
        welcomeDesktopSubtitle,
        withoutMaxWidth,
        qrCodeImage,
        biggerWidth,
        listWrapper,
        list,
        listTitle,
        listItem,
        darkLink,
    } = useStyles();

    const email = localStorage.getItem(LocalStorageKeys[RoutePath.Email]) as string;
    const isUserExists = localStorage.getItem(UserVerificationKeys.isUserExists) === 'true';

    const pwaNativeSplitVariation = localStorage.getItem(
        ExperimentsKeys.pwaNativeSplitVariationVersion2
    ) as string;
    const userApp = getAppType(pwaNativeSplitVariation);
    const trainerizeApp = pwaNativeSplitVariation === '2';
    const appName = t('welcome-list-text-mobile-download-app-name-trainerize');

    const handleClick = () => {
        const linkDownload = `${
            trainerizeApp ? TrainerizeLinks.deepLink : VibrantLifeLinks.deepLink
        }?deep_link_value=ableapp://email_login?email=${encodeURIComponent(email)}`;

        window.open(linkDownload, '_blank');

        pageConfiguration.handleOnPageComplete({ userApp });
    };

    const isIosDevice = detectIosDevice();

    const appStoreLinkComponent = (
        <a
            href={trainerizeApp ? TrainerizeLinks.appStore : VibrantLifeLinks.appStore}
            target="_blank"
            rel="noreferrer nofollow"
        >
            AppStore
        </a>
    );
    const googlePlayLinkComponent = (
        <a
            href={trainerizeApp ? TrainerizeLinks.googlePlay : VibrantLifeLinks.googlePlay}
            target="_blank"
            rel="noreferrer nofollow"
        >
            Google Play
        </a>
    );

    const TrainerizePwdLinkComponent = (
        <a href={TrainerizeLinks.trainerizePWDReset} target="_blank" rel="noreferrer nofollow">
            Here
        </a>
    );

    const ableAppName = t('welcome-instructions-title-personalized');
    const password = localStorage.getItem(PASSWORD) as string;
    const status = localStorage.getItem(USER_STATUS) as string;

    const wl_post_paywall_progress_bar =
        localStorage.getItem(ExperimentsKeys.wl_post_paywall_progress_bar) === '1';

    return (
        <>
            {wl_post_paywall_progress_bar && (
                <div style={{ padding: '0 16px', maxWidth: '560px', margin: '0 auto' }}>
                    <BreadcrumbsNavigation
                        activeSection={BreadcrumbsNavigationKeys.downloadApp}
                        completedSections={[
                            BreadcrumbsNavigationKeys.healthPlan,
                            BreadcrumbsNavigationKeys.addToPlan,
                        ]}
                    />
                </div>
            )}

            {(isIosDevice || isDesktop() || trainerizeApp) && (
                <div className={logosWrapper}>
                    <img src={ableLogo} alt="Able Logo" />
                    <img src={trainerizeApp ? trainerizeLogo : vibrantLogo} alt="App Logo" />
                </div>
            )}

            {isDesktop() ? (
                <>
                    <Typography
                        className={welcomeDesktopTitle}
                        variant={TypographyVariants.h1}
                        typographyNext
                    >
                        {!isUserExists && t('welcome-title')}
                        {isUserExists && t('welcome-title-if-user-exists')}
                    </Typography>
                    <Typography
                        className={`${welcomeDesktopSubtitle} ${
                            trainerizeApp ? withoutMaxWidth : ''
                        }`}
                        variant={TypographyVariants.h2}
                    >
                        {t(trainerizeApp ? 'welcome-subtitle-ios' : 'welcome-desktop-info', {
                            appName,
                        })}
                    </Typography>

                    <div className={welcomeDesktopInfoWrapper}>
                        <div className={listWrapper}>
                            <Typography className={listTitle} variant={TypographyVariants.h2}>
                                {!isUserExists && t('welcome-instructions-title', { ableAppName })}
                                {isUserExists && t('welcome-subtitle-if-user-exists')}
                            </Typography>
                            <ol className={list}>
                                <Typography className={listItem} variant={TypographyVariants.h2}>
                                    <li>{t('welcome-list-text-desktop-scan-qr')}</li>
                                </Typography>
                                <Typography
                                    className={`${listItem} ${trainerizeApp ? darkLink : ''}`}
                                    variant={TypographyVariants.h2}
                                >
                                    <li>
                                        <Trans
                                            i18nKey="welcome-list-text-desktop-store-redirect"
                                            components={[
                                                appStoreLinkComponent,
                                                googlePlayLinkComponent,
                                            ]}
                                        />
                                    </li>
                                </Typography>
                                <Typography className={listItem} variant={TypographyVariants.h2}>
                                    <li>{t('welcome-list-text-desktop-download-app')}</li>
                                </Typography>
                                <Typography className={listItem} variant={TypographyVariants.h2}>
                                    <li>
                                        {!isUserExists &&
                                            t('welcome-list-text-desktop-open-app', { email })}
                                        {isUserExists &&
                                            t('welcome-list-text-desktop-open-app-if-user-exists', {
                                                email,
                                            })}
                                    </li>
                                </Typography>

                                {!isUserExists && !trainerizeApp && (
                                    <Typography
                                        className={listItem}
                                        variant={TypographyVariants.h2}
                                    >
                                        <li>{t('welcome-list-text-desktop-create-password')}</li>
                                    </Typography>
                                )}

                                {trainerizeApp && password && !status && (
                                    <div>
                                        <Typography
                                            className={listItem}
                                            variant={TypographyVariants.h2}
                                        >
                                            <li>
                                                {t('welcome-list-text-mobile-copy-password', {
                                                    password,
                                                })}
                                            </li>
                                        </Typography>
                                        <CopyToClipboardButton text={password} />
                                    </div>
                                )}

                                {trainerizeApp && status && (
                                    <div>
                                        <Typography
                                            className={listItem}
                                            variant={TypographyVariants.h2}
                                        >
                                            <li>
                                                <Trans
                                                    i18nKey="welcome-list-text-mobile-copy-password-link"
                                                    components={[TrainerizePwdLinkComponent]}
                                                />
                                            </li>
                                        </Typography>
                                    </div>
                                )}
                            </ol>
                        </div>

                        <img
                            className={`${qrCodeImage} ${trainerizeApp ? biggerWidth : ''}`}
                            src={trainerizeApp ? qrCodeTrainerize : qrCode}
                            alt="QR Code"
                        />
                    </div>
                </>
            ) : (
                <MobileContent
                    status={status}
                    isIosDevice={isIosDevice}
                    isUserExists={isUserExists}
                    trainerizeApp={trainerizeApp}
                    email={email}
                    password={password}
                    handleClick={handleClick}
                />
            )}
        </>
    );
};
