import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { UNIT_SYSTEM } from 'constants/body-height-weight';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

import fish_healthy from 'assets/images/webp/fish_healthy.webp';
import logo from 'assets/images/svg/logo-black.svg';

// import { HappyWeight } from './happy-weight';

import { useStyles as ContentUseStyles } from 'containers/content/styles';

import { useStyles } from './styles';

export const MainGoalPageComponent = () => {
    const { t } = useTranslation();

    const { mainContent, minHeight } = ContentUseStyles({ scrollableContent: false });
    const { button, link, fishImage, ableLogo, wrapper, btnWrapper, extraInfo } = useStyles();

    const saveAnswers = useSaveAnswers();

    const { pageConfiguration, countryCode } = useContext(BaseContext);

    const { pageValue: mainGoal, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.MainGoal],
        defaultValue: '',
    });

    const { pageValue: unitSystem, setPageValue: setUnitSystem } = useLocalStorage({
        key: MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
        defaultValue: '',
    });

    useEffect(() => {
        if (countryCode && !unitSystem) {
            setUnitSystem(countryCode === 'US' || countryCode === 'CA' ? UNIT_SYSTEM.imperial : UNIT_SYSTEM.metric)
        }
    }, [countryCode]);

    const handleClick = (goal: ChoiceType) => {
        saveAnswers({
            key: 'main-goal',
            itemQuestion: { question: t('main-goal-title'), value: goal.value },
        });

        setPageValue(goal.key);
        pageConfiguration.handleOnPageComplete(goal.key);
    };

    const weightUnit = unitSystem === UNIT_SYSTEM.imperial ? 'lbs' : 'kg';

    const smallTo = unitSystem === UNIT_SYSTEM.imperial ? '20' : '10';
    const mediumFrom = unitSystem === UNIT_SYSTEM.imperial ? '21' : '11';
    const mediumTo = unitSystem === UNIT_SYSTEM.imperial ? '50' : '20';
    const over = unitSystem === UNIT_SYSTEM.imperial ? '50' : '20';

    const goals: ChoiceType[] = [
        { key: 'lose-weight-small', value: t('main-goal-option-lose-weight-small', { from: '1', to: smallTo, weightUnit }) },
        { key: 'lose-weight-medium', value: t('main-goal-option-lose-weight-medium', { from: mediumFrom, to: mediumTo, weightUnit }) },
        { key: 'lose-weight-over', value: t('main-goal-option-lose-weight-over', { over, weightUnit }) },
        { key: 'get-fit', value: t('main-goal-option-get-fit') },
        { key: 'no-decision', value: t('main-goal-option-no-decision') },
    ];

    const switchUnitSystem = () => {
        const newUnitSystem = unitSystem === UNIT_SYSTEM.imperial ? UNIT_SYSTEM.metric : UNIT_SYSTEM.imperial;
        setUnitSystem(newUnitSystem);
    };

    let companyMentionVariationExp = false;
    // const companyMentionVariationExp = useExperiment(process.env.REACT_APP_GOOGLE_OPTIMIZE_COMPANY_MENTION_EXPERIMENT_ID as string) === '1';

    const showExtraInfo = localStorage.getItem('utm_source') === 'tiktok';

    return (
        <>
            <div className={`${mainContent} ${companyMentionVariationExp && minHeight}`}>
                <img className={fishImage} src={fish_healthy} alt="Fish" />
                <img className={ableLogo} src={logo} alt='Logo' />

                <div className={wrapper}>

                    <Typography variant={TypographyVariants.h1}>
                        <Trans i18nKey='main-goal-title' components={[<span></span>]} />
                    </Typography>

                    {goals.map((v: ChoiceType) => (
                        <ChoiceButton
                            key={v.key}
                            center={true}
                            selected={v.key === mainGoal}
                            typographyText={v.value}
                            onClick={() => handleClick(v)}
                        />
                    ))}

                    <div className={btnWrapper}>
                        <button className={button} onClick={switchUnitSystem}>
                            <Typography variant={TypographyVariants.h2} className={link}>
                                {unitSystem === UNIT_SYSTEM.imperial ? t('main-goal-button-switch-to-metric') : t('main-goal-button-switch-to-imperial')}
                            </Typography>
                        </button>
                    </div>
                </div>

                {showExtraInfo && <Typography className={extraInfo} variant={TypographyVariants.h3}>
                    Quiz results will be available after payment
                </Typography>}
            </div>
        </>
    );
};
