import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const AverageBusy = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageValue: averageBusy, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.AverageBusy],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);
        saveAnswers({
            key: 'average-busy',
            itemQuestion: { question: t('average-busy-title'), value: t(answer.value) },
        });

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        {
            key: 'hardly-have-time-for-myself',
            value: t('average-busy-option-myself'),
        },
        {
            key: 'busy-but-try-put-time-to-recharge',
            value: t('average-busy-option-recharge'),
        },
        {
            key: 'open-flexible',
            value: t('average-busy-option-flexible'),
        },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                <Trans i18nKey="average-busy-title" />
            </Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === averageBusy}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
