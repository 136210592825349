import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

interface TitleProps {
    userGender: string;
    userAge: string;
}

export const Title: FC<TitleProps> = ({ userGender, userAge }): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Typography variant={TypographyVariants.h1}>
            {t('helped-thousands-title', {
                userGender,
                userAge: t(userAge as string).toLowerCase(),
            })}
        </Typography>
    );
};
