import React from 'react';

import { TipPage } from 'components/tip-page';

export const BodyRiskTip = () => {
    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/plane.svg'
            text={{
                description: 'body-risk-tip-description',
                next: 'body-risk-tip-button-next'
            }}
        />
    );
};
