import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    pageWrapper: {
        margin: '0 auto',
        maxWidth: '560px',
        padding: '0 16px 32px',
    },
    title: {
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '28px',
        margin: '16px auto 24px',
    },
    info: {
        color: '#5E626B',
        textAlign: 'left',
        marginTop: '16px',
        padding: '0px 7.5px',
        fontSize: '12px',
        lineHeight: '16px',

        '&:last-of-type': {
            marginTop: 0
        }
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '32px',
        gap: '16px',
    },
    skipButtonWrapper: {
        flexBasis: '25%',
    },
    startButtonWrapper: {
        flexBasis: '72%',
    },
    skipButton: {
        ...nextButton.nextButton,
        margin: 0,
        background: '#FFEEDE',
        color: '#EB731A',
    },
    startButton: {
        ...nextButton.nextButton,
        margin: 0,
        padding: '12px 7.5px',

        '&:active': {
            ...nextButton.nextButtonHovered
        },

        '& span': {
            lineHeight: '24px',
            whiteSpace: 'nowrap'
        }
    },
    discountBlock: {
        background: '#DDF3ED',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        padding: '16px',
        gap: '16px',
        borderRadius: '8px',
        marginTop: '16px',
    },
    discountTitle: {
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'left',
        '& span': {
            fontWeight: 600,
        }
    },
    minHeight: {
        minHeight: '70%'
    },
    '@media screen and (min-width: 768px)': {
        pageWrapper: {
            padding: 0,
        },
        title: {
            margin: '80px 0 40px',
            fontSize: '28px',
            lineHeight: '36px',
        },
        buttonsWrapper: {
            marginTop: '40px',
        },
        info: {
            textAlign: 'center',
            fontSize: '16px',
            lineHeight: '26px',
        },
        discountBlock: {
            marginTop: '24px',
            padding: '18px',
        },
        discountTitle: {
            fontSize: '20px',
            lineHeight: '28px',
            margin: '0',
        }
    },
    '@media (hover: hover)': {
        nextButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            },
        }
    }
}));
