import i18n from 'i18next';
import dayjs from 'dayjs';

import { initReactI18next } from 'react-i18next';

export const DEFAULT_LANG = 'en';

export const DEFAULT_VARIABLES = {
    able: 'Able',
};

export const listOfTranslations = {
    'ar': { translation: require('./ar/ar.json') },
    'en': { translation: require('./en/en.json') },
    'en-GB': { translation: require('./en_GB/en_GB.json') },
    'en-CA': { translation: require('./en_CA/en_CA.json') },
    'en-AU': { translation: require('./en_AU/en_AU.json') },
    'en-IN': { translation: require('./en_IN/en_IN.json') },
    'it': { translation: require('./it/it.json') },
    'it-IT': { translation: require('./it/it.json') },
    'it-CH': { translation: require('./it/it.json') },
};

const params = new URLSearchParams(document.location.search);
const language = params.get('language');

i18n.use(initReactI18next).init({
    supportedLngs: ['ar', 'en', 'en-GB', 'en-CA', 'en-AU', 'en-IN', 'it', 'it-IT', 'it-CH'],
    returnObjects: true,
    fallbackLng: DEFAULT_LANG,
    lng: language || window.navigator.language?.toLowerCase() || window.navigator.userLanguage?.toLowerCase(),
    resources: {
        ...listOfTranslations,
    },
    interpolation: {
        defaultVariables: {
            ...DEFAULT_VARIABLES,
            escapeValue: false,
        },
    },
    react: {
        transWrapTextNodes: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'em', 's', 'b'],
    },
    debug: false,
    returnEmptyString: false
});

require(`dayjs/locale/en.js`);

dayjs.locale(i18n.resolvedLanguage);

export default i18n;
