import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    button: {
        background: 'none',
        border: 'none',
        float: 'right',
    },
    link: {
        fontWeight: 600,
        margin: '6px 0 0px',
        color: '#1375D6',
        cursor: 'pointer'
    },
    fishImage: {
        width: '92%',
        marginTop: '-30%',
        position: 'absolute',
    },
    ableLogo: {
        position: 'absolute',
        left: '43%',
        marginTop: '32%',
    },
    wrapper: {
        paddingTop: '35%',
        '& h1': {
            '& span': {
                color: '#1375D6'
            }
        }
    },
    btnWrapper: {
        display: 'flex',
        justifyContent: 'right',
    },
    extraInfo: {
        margin: '24px 0',
        color: '#5E626B'
    },
    '@media (min-width: 768px)': {
        fishImage: {
            width: '94%',
        }
    }
});
