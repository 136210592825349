export type BackArrowButtonType = {
    backButtonWrapper: {
        flex: number,
    },
    backButtonIconInnerWrapper: {
        display: string,
        alignItems: string,
        width: string,
    },
    backButton: {
        width: string,
        height: string,
        marginLeft: string,
        cursor: string,
    },
}

export const BackArrowButtonStyles: BackArrowButtonType = {
    backButtonWrapper: {
        flex: 1,
    },
    backButtonIconInnerWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '50px',
    },
    backButton: {
        width: '14px',
        height: '14px',
        marginLeft: '16px',
        cursor: 'pointer',
    },
};
