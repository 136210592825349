import i18n from 'translations/i18n';

export const ageValidationQuery: any = {
    type: {
        value: 'number',
        moreThen: {
            value: 17,
            errorMessage: i18n.t('age-validation-error-message-under-18'),
        },
        lessThen: {
            value: 101,
            errorMessage: i18n.t('age-validation-error-message'),
        },
    },
};
