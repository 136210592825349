import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';
import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';

import { RoutePath } from 'routes/route-path.constant';

import { CfApiResponse, UpgradeCustomerSubscriptionApiResponse } from 'api/sign-up.api';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useBodyPlanData } from 'hooks/use-body-plan-data.hook';
import { useUpgradeInfo } from './use-upgrade-info.hook';
import { useConvertPrice } from 'hooks/use-convert-price.hook';

import { getPrice } from 'helpers/payment';
import { getAppType } from 'helpers/get-app-type';

import { LocalStorageKeys } from 'constants/local-storage';
import { PLANCODE_PRIMERIO } from 'constants/trial-pay';
import { ExperimentsKeys } from 'constants/experiments';
import { EBOOK_TOOLKIT } from 'constants/ebook-plan';
import { PlanForUpdate, UPGRADE_PLAN } from 'constants/upgrade-plan';
import { CUSTOMER_ID, SUBSCRIPTION_ID } from 'constants/payment';

import { Content } from 'pages/upgrade-plan/content';

export const UpgradePlan = () => {
    const navigate = useNavigate();

    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    // @TODO: We set default our, but if experiment will be the winner (or we'll change the PLANCODES, need additional logic)
    const defaultPlan = PLANCODE_PRIMERIO[0];
    const { pageValue: plan } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.TrialPay],
        defaultValue: defaultPlan,
    });

    const { earlierInitialMonthsData, adjustedGoalWeight } = useBodyPlanData();

    const monthsCountToReachGoalWeight: number = earlierInitialMonthsData.length;

    const planForUpdate = UPGRADE_PLAN;

    const {
        UpgradeCustomerSubscription,
    } = useUpgradeInfo();

    const [showSkipTrialModalWindow, setSkipTrialModalWindow] = useState(false);
    const [loading, setLoading] = useState(false);
    let errorOccurred = false;

    const redirect_to_ebook_if_upsell_fails = useFeatureIsOn(ExperimentsKeys.redirect_to_ebook_if_upsell_fails as any);

    const pwaNativeSplitVariation = localStorage.getItem(ExperimentsKeys.pwaNativeSplitVariationVersion2) as string;

    const upgradeSubscription = (event: string) => {
        const startTime = performance.now();

        setLoading(true);

        let customerId: string;
        let subscriptionId: string;

        UpgradeCustomerSubscription(planForUpdate as PlanForUpdate)
            .then((data: UpgradeCustomerSubscriptionApiResponse | CfApiResponse) => {
                if ('customerId' in data) {
                    customerId = data.customerId;
                    subscriptionId = data.subscriptionId;
                } else {
                    customerId = localStorage.getItem(CUSTOMER_ID) as string;
                    customerId = localStorage.getItem(SUBSCRIPTION_ID) as string;
                }

                pageConfiguration.event(event, {
                    user_id: customerId,
                    subscription_id: subscriptionId,
                    user_app: getAppType(pwaNativeSplitVariation),
                });

                pageConfiguration.event('EBookUpsellPurchasedGifted');

            }).catch((e) => {
            pageConfiguration.event('PlanUpgradePurchaseError', {
                error: e?.type,
                message: e?.message,
            });

            errorOccurred = true;
        }).finally(() => {
            const endTime = performance.now();
            const executionTimeMs = endTime - startTime;
            pageConfiguration.event('PlanUpgradePurchaseExecutionTime', {
                executionTimeMs: executionTimeMs,
            });

            setLoading(false);

            if (redirect_to_ebook_if_upsell_fails && errorOccurred) {
                navigate(`..${RoutePath.Upgrade}`);
            } else {
                navigate(`..${RoutePath.UpgradeTip}`);
            }
        });
    };

    const handleUpgradeSubscription = () => {
        upgradeSubscription('PlanUpgradePurchased');
    };

    const handleSkipUpgradeSubscription = (event: string) => {
        pageConfiguration.event(event);
        setSkipTrialModalWindow(true);
    };

    const handleSkipTrialModalWindow = () => {
        pageConfiguration.event('PlanUpgradePopUpSkipClicked');
        navigate(`..${RoutePath.Upgrade}`);
    };

    const handleUpgradeTrialModalWindow = () => {
        setSkipTrialModalWindow(false);
        upgradeSubscription('PlanUpgradePopUpStartClicked');
    };

    useEffect(() => {
        if (planForUpdate?.key) {
            pageConfiguration.event('PlanUpgradeViewed');
        }
    }, [planForUpdate]);

    const rounded = localStorage.getItem(ExperimentsKeys.wl_price_rounding) as string;
    const upgradeSubscriptionTotalPrice = planForUpdate?.price - (planForUpdate?.discount?.amount || 0);
    const convertedUpgradeSubscriptionTotalPrice = useConvertPrice(getPrice(upgradeSubscriptionTotalPrice as number), rounded);
    const finalEbookPrice = useConvertPrice(getPrice(EBOOK_TOOLKIT.price), rounded).convertedPriceWithTax;

    return (
        <Content loading={loading}
                 showSkipTrialModalWindow={showSkipTrialModalWindow}
                 monthsCountToReachGoalWeight={monthsCountToReachGoalWeight}
                 adjustedGoalWeight={adjustedGoalWeight}
                 handleSkipTrialModalWindow={handleSkipTrialModalWindow}
                 handleUpgradeTrialModalWindow={handleUpgradeTrialModalWindow}
                 handleSkipUpgradeSubscription={handleSkipUpgradeSubscription}
                 handleUpgradeSubscription={handleUpgradeSubscription}
                 originalPrice={getPrice(plan.subscriptionAmount)}
                 trialPrice={getPrice(plan.trialAmount)}
                 currencyLabel={plan.currencyLabel}
                 totalPrice={convertedUpgradeSubscriptionTotalPrice.convertedPriceWithTax}
                 ebookPrice={finalEbookPrice} />
    );
};
