import { AGE_KEYS } from 'constants/body-age';

export function mapAgeToCategory(age: number): string {
    if (age < 20) {
        return AGE_KEYS.underTwenty;
    } else if (age < 30) {
        return AGE_KEYS.twenties;
    } else if (age < 40) {
        return AGE_KEYS.thirties;
    } else if (age < 50) {
        return AGE_KEYS.forties;
    } else if (age < 60) {
        return AGE_KEYS.fifties;
    } else {
        return AGE_KEYS.sixtyPlus;
    }
}
