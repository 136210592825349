import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';
import { NextButton } from 'components/next-button';
import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

export const WellnessGoal = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.WellnessGoal],
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.WellnessGoal]}-userAnswer`,
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'stronger',
            value: t('wellness-goal-option-stronger'),
        },
        {
            key: 'active',
            value: t('wellness-goal-option-active'),
        },
        {
            key: 'toned',
            value: t('wellness-goal-option-toned'),
        },
        {
            key: 'recover',
            value: t('wellness-goal-option-recover'),
        },
        {
            key: 'fun',
            value: t('wellness-goal-option-fun'),
        },
        {
            key: 'other',
            value: t('wellness-goal-option-other'),
            userAnswer, setUserAnswer
        },
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: LocalStorageKeys[RoutePath.WellnessGoal],
            itemQuestion: { question: t('wellness-goal-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue.includes('other') ? {
            pageValue,
            userAnswer: userAnswer.trim()
        } : { pageValue });
    };


    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>{t('wellness-goal-title')}</Typography>

            <Typography variant={TypographyVariants.h2}>{t('wellness-goal-subtitle')}</Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
