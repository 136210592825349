import { RoutePath } from 'routes/route-path.constant';

export const COMPANY_MENTION_FOOTER_NOT_VISIBLE_LOCATIONS = [
    RoutePath.BodyAgeTip,
    RoutePath.BodyRiskTip,
    RoutePath.AverageBusyTip,
    RoutePath.SleepHoursTip,
    RoutePath.GlassesWaterTip,
    RoutePath.SpecificDietTip,
    RoutePath.FoodRestrictionsTip,
    RoutePath.IntermittentFastingTip,
    RoutePath.WithoutWorkoutTip,
    RoutePath.PhysicalLimitationsTip,
    RoutePath.WorkoutPlaceTip,
    RoutePath.HitGoalChartTip,
    RoutePath.PastTrialsTip,
    RoutePath.HelpedThousands,
    RoutePath.PlanLoader,
    RoutePath.PersonalizedProgram,
    RoutePath.YouAreAble,
    RoutePath.Paywall,
    RoutePath.UpgradeTip,
    RoutePath.Congrats,
    RoutePath.Welcome
];
