import { SlackWorkflowAPI } from '@ruby-labs/ui-core-kit';

import { countryISOMapping } from 'constants/country-iso-mapping';

import { getParameterizedCoachImage } from 'helpers/get-parameterized-coach-image';

class Coach extends SlackWorkflowAPI {

    constructor() {
        super({
            webHookUrl: process.env.REACT_APP_SLACK_WEBHOOK_URL as string
        });
    }

    getCoachData(timezone: string, countryCode?: string, userGender?: string, userAge?: string) {
        const limit = 3;
        const baseGenders = ['male', 'female'];
        const baseAges = ['underTwenty', 'twentys', 'thirtys', 'fortys', 'fiftys', 'sixtyPlus'];
        const adjustedCountryCode = countryCode && countryCode !== 'BS' ? countryCode : 'US';
        const adjustedTimezone = timezone === 'America/Ciudad_Juarez' ? 'Mexico/General' : timezone;
        let url = `${process.env.REACT_APP_COACH_API_URL}?limit=${limit}&country=${(countryISOMapping as any)[adjustedCountryCode]}&timezone=${adjustedTimezone}`;

        if (userGender && baseGenders.includes(userGender)) {
            url += `&userGender=${userGender}`;
        }

        if (userAge && baseAges.includes(userAge)) {
            url += `&userAge=${userAge}`;
        }

        return fetch(url, { method: 'GET', mode: 'cors' })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                return response
                    .json()
                    .then(json => Promise.reject(json));
            })
            .then((coaches) => {
                if (coaches.length < limit) {
                    this.sendMessageToWebhook({
                        available_coaches: coaches.length.toString(),
                        api_url_path: url,
                        response: JSON.stringify(coaches)
                    });
                }
                return coaches;
            })
            .then((coaches) => {
                coaches.forEach((coach: any) => {
                    // @description: Preload coach image
                    const link = document.createElement('link');
                    link.rel = 'preload';
                    link.as = 'image';
                    link.href = getParameterizedCoachImage(coach.photo);
                    document.head.appendChild(link);
                });

                return coaches;
            })
            .catch((error) => {

                if (error instanceof Error) {
                    return [];
                }

                this.sendMessageToWebhook({
                    available_coaches: 0,
                    api_url_path: url,
                    response: JSON.stringify(error)
                });

                return [];
            });
    }
}

export const CoachProvider = new Coach();
