import React from 'react';

import { TipPage } from 'components/tip-page';

export const WorkoutPlaceTip = () => {

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/medal.svg'
            text={{
                description: 'workout-place-tip-title',
                next: 'workout-place-tip-button-next'
            }}
        />
    );
};
