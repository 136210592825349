import React from 'react';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';

import { useStyles } from './styles';

export const BloodTypeTip = () => {
    const { list } = useStyles();

    const userBloodType = localStorage.getItem(LocalStorageKeys[RoutePath.BloodType]);

    const getDescriptionTextKey = () => {
        let description;
        if (userBloodType === 'type-a') {
            description = 'blood-type-tip-a-description';
        } else if (userBloodType === 'type-b') {
            description = 'blood-type-tip-b-description';
        } else if (userBloodType === 'type-ab') {
            description = 'blood-type-tip-ab-description';
        } else {
            description = 'blood-type-tip-o-description';
        }

        return description;
    };

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/food-tray.svg'
            text={{
                description: getDescriptionTextKey(),
                components: [
                    <strong></strong>,
                    <ul className={list}>{[''].map(item => (
                        <li key={item}>
                            {item}
                        </li>
                    ))}
                    </ul>
                ],
                next: 'blood-type-tip-button-next',
            }}
        />
    );
};
