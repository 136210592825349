import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ animatedPage }: ThemeProps) => ({
    pageWrapper: {
        ...animatedPage.animationPageWrapper,
        justifyContent: 'flex-start',
    },
    pageContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '560px',

        '& h2': {
            fontSize: '20px',
            lineHeight: '28px',
        },

        '& ul': {
            listStyle: 'disc',

            '& li': {
                marginLeft: '1em',
                marginBottom: '24px',

                '&:last-child': {
                    marginBottom: '28px',
                }
            }
        }
    },
    title: {
        fontSize: '22px',
        lineHeight: '32px',
        margin: '32px 0 16px',
    },
    blueText: {
        display: 'block',
        lineHeight: '22px',
        color: '#1375D6',
        marginTop: '8px',
    },
    description: {
        margin: '0 0 20px',
    },
    listTitle: {
        textAlign: 'left',
        fontWeight: 600,
        margin: '0 0 16px',
        alignSelf: 'flex-start'
    },
    listItem: {
        textAlign: 'left',
        margin: 0,
    },
    '@media (min-width: 768px)': {
        pageContent: {

            '& ul': {

                '& li': {
                    marginBottom: '16px',

                    '&:last-child': {
                        marginBottom: '60px',
                    }
                }
            }
        },
        title: {
            fontSize: '28px',
            margin: '32px 0 24px',
        },
        blueText: {
            lineHeight: '28px',
        },
        description: {
            margin: '0 0 60px',
        },
    }
}));
