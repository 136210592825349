import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const PastTrials = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.PastTrials],
        defaultValue: [],
    });

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'fitness',
            value: t('past-trials-option-fitness'),
        },
        {
            key: 'calorie',
            value: t('past-trials-option-calorie'),
        },
        {
            key: 'gym-exercise',
            value: t('past-trials-option-gym-exercise'),
        },
        {
            key: 'meal',
            value: t('past-trials-option-meal'),
        },

        {
            key: 'restrictive-diet',
            value: t('past-trials-option-restrictive-diet'),
        },
        {
            key: 'nutritionist',
            value: t('past-trials-option-nutritionist'),
        },
        {
            key: 'prescription-medication',
            value: t('past-trials-option-prescription-medication'),
        },
        {
            key: 'food',
            value: t('past-trials-option-food'),
        },
        {
            key: 'therapy',
            value: t('past-trials-option-therapy'),
        },
        {
            key: 'none',
            value: t('past-trials-option-none'),
        },
    ];

    const handleClick = () => {
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.key === item);

            return currentOption?.value;
        });
        saveAnswers({
            key: 'past-trials',
            itemQuestion: { question: t('past-trials-title'), value: selectOption },
        });
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('past-trials-title')}
            </Typography>
            <Typography variant={TypographyVariants.h2}>{t('past-trials-subtitle')}</Typography>
            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
