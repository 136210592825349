export const CurrencyLabels = {
    AU: 'A$',
    CA: 'C$',
    EU: '€',
    US: '$',
    GB: '£',
    IN: '₹',
    SAR: 'SAR',
    AED: 'AED',
    NZ: 'NZ$'
};
