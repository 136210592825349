import React from 'react';

import { TipPage } from 'components/tip-page';

export const PastTrialsTip = () => {
    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/cup.svg'
            text={{
                description: 'past-trials-tip-title',
                next: 'past-trials-tip-button-next'
            }}
        />
    );
};
