import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';
import { FloatedButton } from 'components/floated-button';

export const WorkoutType = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.WorkoutType],
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.WorkoutType]}-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: MultiChoiceType[] = [
        { key: 'cardio', value: t('workout-type-option-cardio') },
        { key: 'strength', value: t('workout-type-option-strength') },
        { key: 'intensity', value: t('workout-type-option-intensity') },
        { key: 'yoga', value: t('workout-type-option-yoga') },
        { key: 'stretch', value: t('workout-type-option-stretch') },
        { key: 'swim', value: t('workout-type-option-swim') },
        { key: 'other', value: t('workout-type-option-other'), userAnswer, setUserAnswer },
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: LocalStorageKeys[RoutePath.WorkoutType],
            itemQuestion: { question: t('workout-type-title'), value: selectOption },
        });
        pageConfiguration.handleOnPageComplete(pageValue.includes('other') ? {
            pageValue,
            userAnswer: userAnswer.trim()
        } : { pageValue });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('workout-type-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2}>{t('workout-type-subtitle')}</Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
