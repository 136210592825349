import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    graphicInfoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '@media (max-width: 560px)' : {
            padding: '0 26px',
        },
    },
    title: {
        fontSize: '24px',
        lineHeight: '28.64px',
        fontWeight: 600,
        margin: '32px 0',
        textAlign: 'left',
    },
    graphicTitle: {
        textAlign: 'left',
        margin: '0 0 16px',
        alignSelf: 'flex-start',
    },
    centered: {
        textAlign: 'center',
        alignSelf: 'center',
    },
    smallerWidth: {
        width: '90%',
        margin: '0 auto',
    },
    colored: {
        color: '#EB731A',
    },
    '@media (min-width: 768px)': {
        smallerWidth: {
            width: '70%',
        }
    }
});
