import React, { FC } from 'react';

import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { LinksKeys } from 'constants/links';

import linkedin from './icons/linkedin.svg';
import instagram from './icons/instagram.svg';
import twitter from './icons/twitter.svg';
import facebook from './icons/facebook.svg';
import logo from './icons/logo.svg';

import { useStyles } from './styles';

interface FooterProps {
    footerAtTheBottom: boolean;
}

export const Footer: FC<FooterProps> = ({ footerAtTheBottom }) => {
    const { footer, footerContent, smallLogo, row, links, socialMedia, phoneNumber, atTheBottom, textWithBottomMargin, mobileBlock, smallerBottomMargin } = useStyles();

    const isDesktop = useDesktopMediaWatcher();

    const socialLinksElementsList =
        <>
            <a href="https://www.linkedin.com/company/ablehealth/" target="_blank" rel="nofollow noopener noreferrer">
                <img src={linkedin} height="20" width="20" alt="linkedin-logo" />
            </a>
            <a href="https://www.instagram.com/myableapp/" target="_blank" rel="nofollow noopener noreferrer">
                <img src={instagram} height="20" width="20" alt="instagram-logo" />
            </a>
            <a href="https://twitter.com/myableapp" target="_blank" rel="nofollow noopener noreferrer">
                <img src={twitter} height="20" width="20" alt="twitter-logo" />
            </a>
            <a href="https://www.facebook.com/Able.Health.Coaching.Official" target="_blank" rel="nofollow noopener noreferrer">
                <img src={facebook} height="20" width="20" alt="facebook-logo" />
            </a>
        </>;

    const userPolicyLinksList =
        <>
            <a href={LinksKeys.TermsAndCondition} target="_blank" rel="noreferrer">Terms and Conditions</a>
            <a href={LinksKeys.PrivacyPolicy} target="_blank" rel="noreferrer">Privacy Policy</a>
        </>;

    const companyRightsInfo = '© 2024 DBA Able All Rights Reserved';
    const companyAddress = '353 Kearny St, San Francisco CA 94108';
    const companyPhoneNumber = '+1 (646) 810-5749';


    return (
        <footer className={`${footer} ${footerAtTheBottom && atTheBottom}`}>
            <div className={footerContent}>
                {isDesktop ?
                    <>
                        <div className={row}>
                            <img src={logo} alt={'Able Logo'}/>
                            <div className={socialMedia}>
                                {socialLinksElementsList}
                            </div>
                        </div>

                        <div className={row}>
                            <div>
                                <div className={textWithBottomMargin}>{companyRightsInfo}</div>
                                <div className={links}>
                                    {userPolicyLinksList}
                                </div>
                            </div>
                            <div>
                                <div className={textWithBottomMargin}>{companyAddress}</div>
                                <div className={phoneNumber}>{companyPhoneNumber}</div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <img className={`${smallLogo} ${mobileBlock}`} src={logo} alt={'Able Logo'} />
                        <div className={mobileBlock}>{companyRightsInfo}</div>
                        <div className={`${mobileBlock} ${smallerBottomMargin}`}>{companyAddress}</div>
                        <div className={`${phoneNumber} ${mobileBlock}`}>{companyPhoneNumber}</div>
                        <div className={`${socialMedia} ${mobileBlock}`}>
                            {socialLinksElementsList}
                        </div>
                        <div className={links}>
                            {userPolicyLinksList}
                        </div>
                    </>
                }
            </div>
        </footer>
    );
};
