import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { getFtAndInchesFromInches } from 'helpers/unit-converter';

import { UNIT_SYSTEM } from 'constants/body-height-weight';

import { useStyles } from './styles';

interface IHeightRangeProps {
    bodyHeightWeightUnitSystem: string;
    bodyHeightCm: string;
    bodyHeightFt: string;
    bodyHeightInches: string;
    bodyHeightOnlyInches: string;
    setBodyHeightCm: (value: string) => void;
    setBodyHeightFt: (value: string) => void;
    setBodyHeightInches: (value: string) => void;
    setBodyHeightOnlyInches: (value: string) => void;
}

export const HeightRange: FC<IHeightRangeProps> = ({
                                                       bodyHeightWeightUnitSystem,
                                                       bodyHeightCm,
                                                       bodyHeightFt,
                                                       bodyHeightInches,
                                                       bodyHeightOnlyInches,
                                                       setBodyHeightCm,
                                                       setBodyHeightFt,
                                                       setBodyHeightInches,
                                                       setBodyHeightOnlyInches,
                                                   }) => {
    const { t } = useTranslation();
    const isDesktop = useDesktopMediaWatcher();

    const { rangeWrapper, rangeBlock, bubble } = useStyles();

    const bodyHeightWeightUnitSystemLabel = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? t('ft') : t('cm');

    const RANGE_CM = {
        min: 109,
        max: 211,
        value: bodyHeightCm,
        showValue: `${bodyHeightCm} ${t('cm')}`,
        unit: t('cm'),
    };

    const RANGE_INCHES = {
        min: 43,
        max: 83,
        value: bodyHeightOnlyInches,
        showValue: `${bodyHeightFt} ${t('ft')} ${bodyHeightInches !== '0' ? `${bodyHeightInches} ${t('in')}` : ''}`,
        unit: t('ft'),
    };

    const currentRange = bodyHeightWeightUnitSystem === UNIT_SYSTEM.metric ? RANGE_CM : RANGE_INCHES;

    const inputEl = document.getElementById('rangeInput') as HTMLInputElement;
    const bubbleEl = document.querySelector('span[class^="bubble"]');
    const bubbleArrowEl = document.querySelector('span[class^="bubble"] + span');

     const calculateBubbleElPosition = () => {
        if (inputEl && inputEl.nextElementSibling) {
            const inputValue = Number(inputEl.value);
            const inputMin = Number(inputEl.min);
            const inputMax = Number(inputEl.max);

            const baseWidthProgress = ((inputValue - inputMin) / (inputMax - inputMin)) * 100;

            const nextElement = inputEl.nextElementSibling as HTMLElement;
            nextElement.style.width = `${baseWidthProgress}%`;

            if (bubbleEl) {
                const bubbleElement = bubbleEl as HTMLElement;

                if (baseWidthProgress <= 5 && !isDesktop) {
                    bubbleElement.style.left = '10%';
                } else if (baseWidthProgress >= 90 && !isDesktop) {
                    bubbleElement.style.left = '90%';
                } else {
                    bubbleElement.style.left = `${baseWidthProgress}%`;
                }
            }

            if (bubbleArrowEl) {
                const bubbleArrowElement = bubbleArrowEl as HTMLElement;

                if (baseWidthProgress <= 5 && !isDesktop) {
                    bubbleArrowElement.style.left = '5%';
                } else if (baseWidthProgress >= 99 && !isDesktop) {
                    bubbleArrowElement.style.left = '95%';
                } else {
                    bubbleArrowElement.style.left = `${baseWidthProgress}%`;
                }
            }
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bodyHeightWeightUnitSystem === UNIT_SYSTEM.metric) {
            setBodyHeightCm(event.target.value);
        } else {
            const { ft, in_ } = getFtAndInchesFromInches(Number(event.target.value));
            setBodyHeightFt(ft.toString());
            setBodyHeightInches(in_.toString());
            setBodyHeightOnlyInches(event.target.value);
        }
    };

    useEffect(() => {
        calculateBubbleElPosition();
    }, [inputEl, bodyHeightCm, bodyHeightOnlyInches, bodyHeightWeightUnitSystem]);

    return (
        <div className={rangeWrapper}>
            <div>
                <span>{t('height')}</span>
                <span>
                    {bodyHeightWeightUnitSystem === UNIT_SYSTEM.metric ?
                        `${currentRange.max} ${currentRange.unit}` :
                        `${getFtAndInchesFromInches(RANGE_INCHES.max).ft} ${RANGE_INCHES.unit} ${getFtAndInchesFromInches(RANGE_INCHES.max).in_} ${t('in')}`}
                </span>
            </div>
            <div className={rangeBlock}>
                <input type='range' id='rangeInput' min={currentRange.min} max={currentRange.max}
                       value={currentRange.value} onChange={handleInputChange}
                       data-units={bodyHeightWeightUnitSystemLabel} step={1} />
                <span></span>
                <span className={bubble}>{currentRange.showValue}</span>
                <span></span>
            </div>
        </div>
    );
};
