import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { mapAgeToCategory } from 'helpers/map-age-to-category';

import { AGES, UserAgeInputKey } from 'constants/body-age';
import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';
import { AgeInput } from 'components/age-input';
import { NextButton } from 'components/next-button';

type ErrorType = {
    error: boolean;
    key: string;
}

export const BodyAge = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: bodyAge, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyAge],
        defaultValue: '',
    });

    const { pageValue: userAgeInput, setPageValue: setUserAgeInput } = useLocalStorage({
        key: UserAgeInputKey,
        defaultValue: '',
    });

    const onChangeAge = (e: any) => {
        setUserAgeInput(e.target.value);
    };

    const [pageError, setPageError] = useState(false);
    const [errors, setError] = useState({});

    const onError = (error: boolean, key: string) => {
        setError((prevState: ErrorType) => ({
            ...prevState,
            [key]: error,
        }));
    };

    useEffect(() => {

        setPageError(() => {
            // @ts-ignore
            return Object.keys(errors).some((key) => errors[key]);
        });

    }, [JSON.stringify(errors)]);

    const wl_questions_update = localStorage.getItem(ExperimentsKeys.wl_questions_update) === '1';

    const handleClick = (age: ChoiceType) => {
        let userAge = age;

        if (wl_questions_update) {
            const userAgeCategory = mapAgeToCategory(userAgeInput);
            userAge = AGES.find((ageOption) => userAgeCategory === ageOption.key) || AGES[1];
        }

        saveAnswers({
            key: 'body-age',
            itemQuestion: { question: t('body-age-title'), value: t(userAge.value) },
        });
        setPageValue(userAge.key);

        pageConfiguration.handleOnPageComplete(userAge.key);
    };

    const bodyAgeTitleKey = wl_questions_update ? 'body-age-experiment-title' : 'body-age-title';

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={wl_questions_update}>
                {t(bodyAgeTitleKey)}
            </Typography>

            {wl_questions_update && <Typography variant={TypographyVariants.h2}>{t('body-age-subtitle')}</Typography>}

            {wl_questions_update ?
                <AgeInput value={userAgeInput} onChange={onChangeAge} onError={onError} />
                :
                AGES.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key === bodyAge}
                        typographyText={t(v.value)}
                        onClick={() => handleClick(v)}
                    />
                ))}

            {wl_questions_update && <NextButton onClick={handleClick} disabled={pageError || !userAgeInput} />}
        </>
    );
};
