import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    numbersClass: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginRight: '8px',
        marginTop: '5px',
        fontFamily: 'SF Pro Text',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#6B6B6B'
    },
    reversedMargin: {
        marginTop: '-5px',
    },
    monthClass: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '7%',
        paddingTop: '8px',
        fontFamily: 'SF Pro Text',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#6B6B6B',
        height: '25px',
    },
    weightGraphic: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        '& svg': {
            display: 'block',
        }
    },
    popoverWrapper: {
        top: '67%',
        left: '81%',

        '&$desktopSmallViewChartPopoverWrapper': {
            top: '60%',
        }
    },
    desktopSmallViewChartPopoverWrapper: {},
    popoverWrapperImportantDate: {},
    chartWith24PaddingOnTheSidesClass: {},
    '@media (max-width: 540px)' : {
        popoverWrapper: {
            top: '65%',

            '&$popoverWrapperImportantDate': {
                top: '64%'
            }
        }
    },
    '@media (max-width: 530px)' : {
        popoverWrapper: {
            top: '64%',
            left: '80.5%',
        }
    },
    '@media (max-width: 500px)' : {
        popoverWrapper: {
            top: '62%',
            left: '80.5%',
        }
    },
    '@media (max-width: 480px)' : {
        popoverWrapper: {
            top: '60%',
            left: '78.5%',
        }
    },
    '@media (max-width: 460px)' : {
        popoverWrapper: {
            top: '58%',
            left: '75.5%',
        }
    },
    '@media (max-width: 440px)' : {
        popoverWrapper: {
            top: '57%',
            left: '75.5%',

            '&$popoverWrapperImportantDate': {
                top: '55%'
            }
        }
    },
    '@media (max-width: 414px)' : {
        popoverWrapper: {
            top: '55%',
            left: '75.5%',

            '&$popoverWrapperImportantDate': {
                top: '54%'
            }
        }
    },
    '@media (max-width: 393px)' : {
        popoverWrapper: {
            top: '53%',
            left: '75.5%',

            '&$popoverWrapperImportantDate': {
                top: '51%'
            }
        }
    },
    '@media (max-width: 390px)' : {
        popoverWrapper: {
            top: '53%',
            left: '75.5%',
        }
    },
    '@media (max-width: 375px)' : {
        popoverWrapper: {
            top: '51%',
            left: '75.5%',

            '&$popoverWrapperImportantDate': {
                top: '49%'
            }
        }
    },
    '@media (max-width: 360px)' : {
        popoverWrapper: {
            top: '50%',
            left: '75.5%',

            '&$popoverWrapperImportantDate': {
                top: '47%'
            }
        },
    },
    '@media (max-width: 320px)' : {
        popoverWrapper: {
            top: '43%',
            left: '75%',

            '&$popoverWrapperImportantDate': {
                top: '46%',

                '& p': {
                    fontSize: '12px',
                    textAlign: 'center',
                    padding: '4px 8px'
                }
            }
        }
    },
    '@media (max-width: 280px)' : {
        popoverWrapper: {
            top: '35%',

            '&$popoverWrapperImportantDate': {
                top: '38%'
            }
        }
    }
});
