export const PAYMENT_METHODS = {
    creditCard: 'card',
    applePay: 'Apple Pay',
    payPal: 'PayPal', // paypal_express_checkout
    googlePay: 'Google Pay',
    primerIO: 'PrimerIO',
};

export const SUBSCRIPTION_ID = 'subscriptionid';

export const CUSTOMER_ID = 'customerid';

export const PASSWORD = 'password';
export const USER_STATUS = 'USERSTATUS';

export enum BILLING_PLATFORM {
    Primer = 'Primer.io',
}

export const BanPrepaidCardsKeys = {
    banPrepaidCards: 'banPrepaidCards',
    banPrepaidCardsEnabled: 'banPrepaidCardsEnabled',
};

export const SubscriptionTypeKeys = {
    loseWeight: 'loseWeight',
    loseWeightUpsell: 'loseWeightUpsell',
};

export const CheckoutBillingAddressExperimentKeys = {
    checkoutBillingAddress: 'checkoutBillingAddress',
    checkoutBillingAddressEnabled: 'checkoutBillingAddressEnabled',
};
