import { useEffect, useState } from 'react';
import { CurrencyProvider } from 'api/currency.api';
import { countryToCurrencyData } from 'helpers/country-to-currency-data';

type ConvertedValue = { convertedPriceWithTax: number; currencyLabel: string };

export const useConvertPrice = (price: number, rounded: string): ConvertedValue => {
    const US_KEY = 'US';

    const countryCode = localStorage.getItem('countryCode') || US_KEY;

    const currencyData = countryToCurrencyData(countryCode);

    const [convertedValue, setConvertedValue] = useState<ConvertedValue>({
        convertedPriceWithTax: price,
        currencyLabel: currencyData.currencyLabel,
    });

    useEffect(() => {
        if (!isNaN(price)) {
            CurrencyProvider.convert(currencyData.currencyCode, [price], rounded)
                .then((res) => {
                    const initialConvertedPrice = parseFloat(res[0]);
                    const convertedPriceWithTax = initialConvertedPrice + initialConvertedPrice * currencyData.tax;

                    setConvertedValue({
                        convertedPriceWithTax,
                        currencyLabel: currencyData.currencyLabel,
                    });
                })
                .catch(() => {
                    setConvertedValue({
                        convertedPriceWithTax: price,
                        currencyLabel: currencyData.currencyLabel,
                    });
                });
        }
    }, [price, countryCode]);

    return convertedValue;
};
