import { isString, uuidv4 } from 'helpers';

import { FUNNEL_VERSION } from 'constants/app-name';

const USER_ID_KEY = 'userID';

export interface GTagServiceInterface {
    event: (name: string, params: any) => void;
    getUserId: () => string;
}

export class AnalyticsService implements GTagServiceInterface {

    private userId = localStorage.getItem(USER_ID_KEY) || uuidv4();

    private useLog: boolean = window.location.origin.includes('localhost');

    constructor() {
        localStorage.setItem(USER_ID_KEY, this.userId);
    }

    private logEvent(name: string, data = {}) {
        console.log(
            `%c[FIRED CLIENT EVENT] ${name}`,
            "color:orange; font-size: 16px; text-transform: capitalize;"
        );
        console.table({
            "EVENT NAME": name,
            "EVENT DATA": Object.keys(data).length ? JSON.stringify(data) : '-'
        });
        console.log(" ------------------------------ ");
    }

    private trackGTag(name: string, data: {} | any[] | string = '') {

        if (isString(data)) {
            data = { value: data };
        }

        if (Array.isArray((data))) {
            data = { value: data };
        }

        const params = Object.assign({
            'event_category': '<WebOnboarding>',
            'event_label': '<WeightLoss>',
            'event_funnel_version': FUNNEL_VERSION,
            'event_release_version': process.env.REACT_APP_RELEASE_VERSION
        }, data);

        try {
            window.gtag('event', name, params);
        } catch (e: any) {
            console.error(`Failed to sent gtag event ${name}`, e);
        }
    }

    public getUserId() {
        return this.userId;
    }

    public event(name: string, params: {} | string = '') {
        if (this.useLog) {
            return;
        }

        this.logEvent(name, params);

        try {
            this.trackGTag(name, params);
        } catch (e) {
            console.log(`gtag`, e);
        }
    }
}
