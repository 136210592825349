import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    rangeWrapper: {
        width: '100%',
        paddingTop: '32px',

        '& > div:first-child': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#5E626B'
        }
    },
    rangeBlock: {
        width: '100%',
        position: 'relative',
        '-webkit-tap-highlight-color': 'transparent',

        '& input[type=range]': {
            '-webkit-appearance': 'none',
            width: '100%',
            height: '8px',
            background: '#E0E3EB',
            outline: 'none',
            borderRadius: '8px'
        },

        '& input[type=range] + span': {
            content: '',
            position: 'absolute',
            top: '6px',
            left: '2px',
            width: '100%',
            height: '8px',
            background: '#1375D6',
            borderRadius: '8px',
            zIndex: 2,
            pointerEvents: 'none'
        },

        '& input[type=range]::-webkit-slider-thumb': {
            '-webkit-appearance': 'none',
            position: 'relative',
            appearance: 'none',
            width: '30px',
            height: '30px',
            background: '#FFFFFF',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            borderRadius: '50%',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            zIndex: 3
        }
    },
    bubble: {
        position: 'absolute',
        bottom: 'calc(100% + 15px)',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#202B47',
        padding: '10px',
        backgroundColor: '#E8F5FD',
        borderRadius: '8px',
        whiteSpace: 'nowrap',

        '& + span': {
            position: 'absolute',
            content: '',
            display: 'block',
            height: '10px',
            width: '10px',
            backgroundColor: '#E8F5FD',
            clipPath: 'polygon(0 0, 0 100%, 100% 100%)',
            bottom: 'calc(100% + 11px)',
            left: '50%',
            transform: 'translateX(-50%) rotate(315deg)',
        }
    }
});

