// Food icons
import sweet from '../assets/images/svg/food-icons/sweet.svg';
import sugaryDrink from '../assets/images/svg/food-icons/sugary-drink.svg';
import fries from '../assets/images/svg/food-icons/fries.svg';
import alcohol from '../assets/images/svg/food-icons/alcohol.svg';
import salt from '../assets/images/svg/food-icons/salt.svg';

// Body types images
import menInvTriangle from '../assets/images/svg/body-type/male/inv-triangle.svg';
import menRectangle from '../assets/images/svg/body-type/male/rectangle.svg';
import menRounded from '../assets/images/svg/body-type/male/rounded.svg';

import invTriangle from '../assets/images/svg/body-type/female/inv-triangle.svg';
import triangle from '../assets/images/svg/body-type/female/triangle.svg';
import rectangle from '../assets/images/svg/body-type/female/rectangle.svg';
import rounded from '../assets/images/svg/body-type/female/rounded.svg';
import hourglass from '../assets/images/svg/body-type/female/hourglass.svg';

// Graphics
import weightLossGraphic from '../assets/images/svg/weight-loss/weight-loss-graphic.svg';

// Reinforcement icons
import apple from '../assets/images/svg/reinforcement-icons/apple.svg';
import appleWithGlass from '../assets/images/svg/reinforcement-icons/apple-with-glass.svg';
import armWithHeart from '../assets/images/svg/reinforcement-icons/arm-with-heart.svg';
import bones from '../assets/images/svg/reinforcement-icons/bones.svg';
import brain from '../assets/images/svg/reinforcement-icons/brain.svg';
import chat from '../assets/images/svg/reinforcement-icons/chat.svg';
import clock from '../assets/images/svg/reinforcement-icons/clock.svg';
import congratulations from '../assets/images/svg/reinforcement-icons/congratulations.svg';
import cup from '../assets/images/svg/reinforcement-icons/cup.svg';
import foodTray from '../assets/images/svg/reinforcement-icons/food-tray.svg';
import glassWater from '../assets/images/svg/reinforcement-icons/glass-water.svg';
import ladderWithStar from '../assets/images/svg/reinforcement-icons/ladder-with-star.svg';
import medal from '../assets/images/svg/reinforcement-icons/medal.svg';
import moon from '../assets/images/svg/reinforcement-icons/moon.svg';
import mountainWithFlag from '../assets/images/svg/reinforcement-icons/mountain-with-flag.svg';
import plane from '../assets/images/svg/reinforcement-icons/plane.svg';
import scales from '../assets/images/svg/reinforcement-icons/scales.svg';
import star from '../assets/images/svg/reinforcement-icons/star.svg';
import sunWithCloud from '../assets/images/svg/reinforcement-icons/sun-with-cloud.svg';
import checkedStar from 'assets/images/svg/reinforcement-icons/checked-star.svg';
import prayingHands from 'assets/images/svg/reinforcement-icons/praying-hands.svg';

// Testimonials
import jeans from 'assets/images/webp/testimonials/jeans.webp';
import testimonialUserAvatar from 'assets/images/webp/testimonials/testimonial-image.webp';
import female from 'assets/images/webp/testimonials/answer-female.webp';
import male from 'assets/images/webp/testimonials/answer-male.webp';

// Testimonials Google Optimize experiment
import femaleUnderTwentyTwenties from '../assets/images/jpg/testimonials/answer-female-underTwenty-twenties.jpg';
import femaleThirtiesForties from '../assets/images/jpg/testimonials/answer-female-thirties-forties.jpg';
import femaleFiftiesSixtyPlus from '../assets/images/jpg/testimonials/answer-female-fifties-sixtyPlus.jpg';
import maleUnderTwentyTwenties from '../assets/images/jpg/testimonials/answer-male-underTwenty-twenties.jpg';
import maleThirtiesForties from '../assets/images/jpg/testimonials/answer-male-thirties-forties.jpg';
import maleFiftiesSixtyPlus from '../assets/images/jpg/testimonials/answer-male-fifties-sixtyPlus.jpg';

// Paywall weight loss program
import fiveStars from 'assets/images/svg/paywall/weight-loss-program/reviews-banner/five-stars.svg';
import femaleBefore from 'assets/images/webp/paywall/weight-loss-program/female-before.webp';
import femaleAfter from 'assets/images/svg/paywall/weight-loss-program/female-after.svg';
import maleBefore from 'assets/images/webp/paywall/weight-loss-program/male-before.webp';
import maleAfter from 'assets/images/svg/paywall/weight-loss-program/male-after.svg';
import info from 'assets/images/svg/paywall/weight-loss-program/info.svg';

// Paywall what you will receive list icons
import orangeScale from 'assets/images/svg/paywall/what-you-will-receive-list/scale.svg';
import orangeCoach from 'assets/images/svg/paywall/what-you-will-receive-list/coach.svg';
import orangeSalad from 'assets/images/svg/paywall/what-you-will-receive-list/salad.svg';
import orangeHeart from 'assets/images/svg/paywall/what-you-will-receive-list/heart.svg';

// Paywall payment info icons
import guaranteeBadge from 'assets/images/svg/paywall/payment/payment-info/guarantee-badge.svg';

// Paywall video player icons
import cross from 'assets/images/svg/paywall/video-player/cross.svg';
import play from 'assets/images/svg/paywall/video-player/play.svg';
import prevButton from 'assets/images/svg/paywall/video-customer-reviews/prev-button.svg';
import nextButton from 'assets/images/svg/paywall/video-customer-reviews/next-button.svg';

// Paywall keep on track icons
import audioCoaching from 'assets/images/webp/paywall/keep-on-track/audio-coaching.webp';
import textCoaching from 'assets/images/webp/paywall/keep-on-track/text-coaching.webp';
import videoCoaching from 'assets/images/webp/paywall/keep-on-track/video-coaching.webp';

// Paywall papers reviews icons
import forbesPaper from 'assets/images/svg/paywall/papers-reviews/forbes.svg';
import eveningStandardPaper from 'assets/images/svg/paywall/papers-reviews/evening-standard.svg';
import womenHealthPaper from 'assets/images/svg/paywall/papers-reviews/women-health.svg';
import mirrorPaper from 'assets/images/svg/paywall/papers-reviews/mirror.svg';
import yahooPaper from 'assets/images/svg/paywall/papers-reviews/yahoo.svg';
import dailyMailPaper from 'assets/images/svg/paywall/papers-reviews/daily-mail.svg';

// Paywall body goals path icons
import body from 'assets/images/svg/paywall/body-goals-path/body.svg';
import coaching from 'assets/images/svg/paywall/body-goals-path/coaching.svg';
import community from 'assets/images/svg/paywall/body-goals-path/community.svg';

// Paywall weight loss program reviews icons
import weightLossProgramRating from 'assets/images/svg/paywall/weight-loss-program-reviews/weight-loss-program-reviews-slide/rating.svg';

// Paywall customer reviews icons
import verifiedCustomerIcon from 'assets/images/svg/paywall/customer-reviews/verified-customer-icon.svg';
import customerReviewsRating from 'assets/images/svg/paywall/customer-reviews/customer-reviews-rating.svg';
import loadMoreIcon from 'assets/images/svg/paywall/customer-reviews/load-more-icon.svg';

// Paywall info block icons
import greatJobBlockStar from 'assets/images/svg/paywall/info-block-icons/star.svg';
import infoIcon from 'assets/images/svg/paywall/info-block-icons/info.svg';
import disclaimer from 'assets/images/svg/paywall/info-block-icons/disclaimer.svg';

// Body gender images
import maleGender from '../assets/images/svg/body-gender/male-gender.svg';
import femaleGender from '../assets/images/svg/body-gender/female-gender.svg';

// Important date calendar
import nextArrow from 'assets/images/svg/important-date-calendar/next-arrow.svg';
import prevArrow from 'assets/images/svg/important-date-calendar/prev-arrow.svg';
import calendarIcon from 'assets/images/svg/important-date-calendar/calendar-icon.svg';
import crossIcon from 'assets/images/svg/important-date-calendar/cross-icon.svg';

import partyIcon from 'assets/images/svg/party-icon.svg';

// Upgrade plan skip trial modal window
import coach from 'assets/images/svg/upgrade-plan/skip-trial-modal-window/coach.svg';
import cutlery from 'assets/images/svg/upgrade-plan/skip-trial-modal-window/cutlery.svg';
import graph from 'assets/images/svg/upgrade-plan/skip-trial-modal-window/graph.svg';
import weights from 'assets/images/svg/upgrade-plan/skip-trial-modal-window/weights.svg';

// Upgrade plan content
import commitmentGraph from 'assets/images/svg/upgrade-plan/content/commitment-graph.svg';
import biohacks from 'assets/images/webp/upgrade-plan/content/ebook-gift-block/biohacks.webp';
import styleGuide from 'assets/images/webp/upgrade-plan/content/ebook-gift-block/style-guide.webp';

// Upgrade
import upgradeGraphIcon from 'assets/images/svg/upgrade/graph.svg';
import upgradeDiscountIcon from 'assets/images/svg/upgrade/discount.svg';
import sandClock from 'assets/images/svg/reinforcement-icons/sand-clock.svg';

// Email
import mountainIcon from '../assets/images/svg/email/mountain-icon.svg';

// How Trial Pay works
import lockIcon from '../assets/images/svg/trial-timeline/lock.svg';
import ringIcon from '../assets/images/svg/trial-timeline/ring.svg';
import calendarHowIcon from '../assets/images/svg/trial-timeline/calendar.svg';

// Scientific Proof
import scientificProofGraphic from 'assets/images/svg/scientific-proof/scientific-proof-graphic.svg';
import columbiaUniversityIcon from 'assets/images/svg/scientific-proof/columbia-university.svg';
import mayoClinicIcon from 'assets/images/svg/scientific-proof/mayo-clinic.svg';

// Info block icons
import commas from 'assets/images/svg/info-block-icons/commas.svg';
import starIcon from 'assets/images/svg/info-block-icons/star.svg';

// Breadcrumbs navigation
import navigationIconDown from 'assets/images/svg/breadcrumbs-navigation/navigation-icon-down.svg';
import navBarCheckIcon from 'assets/images/svg/breadcrumbs-navigation/check-icon.svg';

// Personality Quiz
import histogram from 'assets/images/svg/personality-quiz/histogram.svg';
import lamp from 'assets/images/svg/personality-quiz/lamp.svg';

// Weight metabolic rate graphic
import weightMetabolicRateGraphic from 'assets/images/svg/weight-metabolic-rate/weight-metabolic-rate-graphic.svg';

export const BackgroundLoadingImages = () => {
    return {
        images: [
            { key:'svg/body-gender/male-gender.svg', pathUrl: maleGender },
            { key:'svg/body-gender/female-gender.svg', pathUrl: femaleGender },

            { key:'svg/food-icons/sweet.svg', pathUrl: sweet },
            { key:'svg/food-icons/sugary-drink.svg', pathUrl: sugaryDrink },
            { key:'svg/food-icons/fries.svg', pathUrl: fries },
            { key:'svg/food-icons/alcohol.svg', pathUrl: alcohol },
            { key:'svg/food-icons/salt.svg', pathUrl: salt },
            { key:'svg/body-type/male/inv-triangle.svg', pathUrl: menInvTriangle },
            { key:'svg/body-type/male/rectangle.svg', pathUrl: menRectangle },
            { key:'svg/body-type/male/rounded.svg', pathUrl: menRounded },
            { key:'svg/body-type/female/inv-triangle.svg', pathUrl: invTriangle },
            { key:'svg/body-type/female/triangle.svg', pathUrl: triangle },
            { key:'svg/body-type/female/rectangle.svg', pathUrl: rectangle },
            { key:'svg/body-type/female/rounded.svg', pathUrl: rounded },
            { key:'svg/body-type/female/hourglass.svg', pathUrl: hourglass },
            { key:'svg/weight-loss/weight-loss-graphic.svg', pathUrl: weightLossGraphic },
            { key:'svg/reinforcement-icons/apple.svg', pathUrl: apple },
            { key:'svg/reinforcement-icons/bones.svg', pathUrl: bones },
            { key:'svg/reinforcement-icons/brain.svg', pathUrl: brain },
            { key:'svg/reinforcement-icons/chat.svg', pathUrl: chat },
            { key:'svg/reinforcement-icons/cup.svg', pathUrl: cup },
            { key:'svg/reinforcement-icons/food-tray.svg', pathUrl: foodTray },
            { key:'svg/reinforcement-icons/glass-water.svg', pathUrl: glassWater },
            { key:'svg/reinforcement-icons/medal.svg', pathUrl: medal },
            { key:'svg/reinforcement-icons/plane.svg', pathUrl: plane },
            { key:'svg/reinforcement-icons/arm-with-heart.svg', pathUrl: armWithHeart },
            { key:'svg/reinforcement-icons/scales.svg', pathUrl: scales },
            { key:'svg/reinforcement-icons/mountain-with-flag.svg', pathUrl: mountainWithFlag },
            { key:'svg/reinforcement-icons/clock.svg', pathUrl: clock },
            { key:'svg/reinforcement-icons/apple-with-glass.svg', pathUrl: appleWithGlass },
            { key:'svg/reinforcement-icons/moon.svg', pathUrl: moon },
            { key:'svg/reinforcement-icons/sun-with-cloud.svg', pathUrl: sunWithCloud },
            { key:'svg/reinforcement-icons/congratulations.svg', pathUrl: congratulations },
            { key:'svg/reinforcement-icons/ladder-with-star.svg', pathUrl: ladderWithStar },
            { key:'svg/reinforcement-icons/star.svg', pathUrl: star },
            { key:'webp/testimonials/jeans.webp', pathUrl: jeans },
            { key:'webp/testimonials/testimonial-image.webp', pathUrl: testimonialUserAvatar },
            { key:'webp/testimonials/answer-female.webp', pathUrl: female },
            { key:'webp/testimonials/answer-male.webp', pathUrl: male },
            { key:'jpg/testimonials/answer-female-underTwenty-twenties.jpg', pathUrl: femaleUnderTwentyTwenties },
            { key:'jpg/testimonials/answer-female-thirties-forties.jpg', pathUrl: femaleThirtiesForties },
            { key:'jpg/testimonials/answer-female-fifties-sixtyPlus.jpg', pathUrl: femaleFiftiesSixtyPlus },
            { key:'jpg/testimonials/answer-male-underTwenty-twenties.jpg', pathUrl: maleUnderTwentyTwenties },
            { key:'jpg/testimonials/answer-male-thirties-forties.jpg', pathUrl: maleThirtiesForties },
            { key:'jpg/testimonials/answer-male-fifties-sixtyPlus.jpg', pathUrl: maleFiftiesSixtyPlus },
            { key:'svg/paywall/what-you-will-receive-list/scale.svg', pathUrl: orangeScale },
            { key:'svg/paywall/what-you-will-receive-list/coach.svg', pathUrl: orangeCoach },
            { key:'svg/paywall/what-you-will-receive-list/salad.svg', pathUrl: orangeSalad },
            { key:'svg/paywall/what-you-will-receive-list/heart.svg', pathUrl: orangeHeart },
            { key:'svg/paywall/payment/payment-info/guarantee-badge.svg', pathUrl: guaranteeBadge },
            { key:'svg/paywall/weight-loss/reviews-banner/five-stars.svg', pathUrl: fiveStars },
            { key:'webp/paywall/weight-loss-program/female-before.webp', pathUrl: femaleBefore },
            { key:'svg/paywall/weight-loss-program/female-after.svg', pathUrl: femaleAfter },
            { key:'webp/paywall/weight-loss-program/male-before.webp', pathUrl: maleBefore },
            { key:'svg/paywall/weight-loss-program/male-after.svg', pathUrl: maleAfter },
            { key:'svg/paywall/weight-loss-program/info.svg', pathUrl: info },
            { key:'svg/paywall/video-player/play.svg', pathUrl: play },
            { key:'svg/paywall/video-player/cross.svg', pathUrl: cross },
            { key:'svg/paywall/video-customer-reviews/prev-button.svg', pathUrl: prevButton },
            { key:'svg/paywall/video-customer-reviews/next-button.svg', pathUrl: nextButton },
            { key:'webp/paywall/keep-on-track/audio-coaching.webp', pathUrl: audioCoaching },
            { key:'webp/paywall/keep-on-track/text-coaching.webp', pathUrl: textCoaching },
            { key:'webp/paywall/keep-on-track/video-coaching.webp', pathUrl: videoCoaching },
            { key:'svg/paywall/info-block-icons/info.svg', pathUrl: infoIcon },
            { key:'svg/paywall/papers-reviews/forbes.svg', pathUrl: forbesPaper },
            { key:'svg/paywall/papers-reviews/evening-standard.svg', pathUrl: eveningStandardPaper },
            { key:'svg/paywall/papers-reviews/women-health.svg', pathUrl: womenHealthPaper },
            { key:'svg/paywall/papers-reviews/mirror.svg', pathUrl: mirrorPaper },
            { key:'svg/paywall/papers-reviews/daily-mail.svg', pathUrl: dailyMailPaper },
            { key:'svg/paywall/papers-reviews/yahoo.svg', pathUrl: yahooPaper },
            { key:'svg/paywall/info-block-icons/disclaimer.svg', pathUrl: disclaimer },
            { key:'svg/paywall/weight-loss-program-reviews/weight-loss-program-reviews-slide/rating.svg', pathUrl: weightLossProgramRating },
            { key:'svg/paywall/body-goals-path/coaching.svg', pathUrl: coaching },
            { key:'svg/paywall/body-goals-path/body.svg', pathUrl: body },
            { key:'svg/paywall/body-goals-path/community.svg', pathUrl: community },
            { key:'svg/paywall/customer-reviews/verified-customer-icon.svg', pathUrl: verifiedCustomerIcon },
            { key:'svg/paywall/customer-reviews/customer-reviews-rating.svg', pathUrl: customerReviewsRating },
            { key:'svg/paywall/customer-reviews/load-more-icon.svg', pathUrl: loadMoreIcon },
            { key:'svg/important-date-calendar/next-arrow.svg', pathUrl: nextArrow },
            { key:'svg/important-date-calendar/prev-arrow.svg', pathUrl: prevArrow },
            { key:'svg/important-date-calendar/calendar-icon.svg', pathUrl: calendarIcon },
            { key:'svg/important-date-calendar/cross-icon.svg', pathUrl: crossIcon },
            { key:'svg/party-icon.svg', pathUrl: partyIcon },
            { key:'svg/upgrade-plan/skip-trial-modal-window/coach.svg', pathUrl: coach },
            { key:'svg/upgrade-plan/skip-trial-modal-window/cutlery.svg', pathUrl: cutlery },
            { key:'svg/upgrade-plan/skip-trial-modal-window/graph.svg', pathUrl: graph },
            { key:'svg/upgrade-plan/skip-trial-modal-window/weights.svg', pathUrl: weights },
            { key:'svg/upgrade-plan/content/commitment-graph.svg', pathUrl: commitmentGraph },
            { key:'svg/upgrade/graph.svg', pathUrl: upgradeGraphIcon },
            { key:'svg/upgrade/discount.svg', pathUrl: upgradeDiscountIcon },
            { key:'svg/reinforcement-icons/sand-clock.svg', pathUrl: sandClock },
            { key:'svg/email/mountain-icon.svg', pathUrl: mountainIcon },
            { key:'svg/trial-timeline/lock.svg', pathUrl: lockIcon },
            { key:'svg/trial-timeline/ring.svg', pathUrl: ringIcon },
            { key:'svg/trial-timeline/calendar.svg', pathUrl: calendarHowIcon },
            { key:'svg/reinforcement-icons/checked-star.svg', pathUrl: checkedStar },
            { key:'svg/scientific-proof/scientific-proof-graphic.svg', pathUrl: scientificProofGraphic },
            { key:'svg/scientific-proof/columbia-university.svg', pathUrl: columbiaUniversityIcon },
            { key:'svg/scientific-proof/mayo-clinic.svg', pathUrl: mayoClinicIcon },
            { key:'svg/paywall/info-block-icons/star.svg', pathUrl: greatJobBlockStar },
            { key:'webp/upgrade-plan/content/ebook-gift-block/biohacks.webp', pathUrl: biohacks },
            { key:'webp/upgrade-plan/content/ebook-gift-block/style-guide.webp', pathUrl: styleGuide },

            { key:'svg/info-block-icons/commas.svg', pathUrl: commas },
            { key:'svg/info-block-icons/star.svg', pathUrl: starIcon },

            { key:'svg/reinforcement-icons/praying-hands.svg', pathUrl: prayingHands },

            { key:'svg/breadcrumbs-navigation/navigation-icon-down.svg', pathUrl: navigationIconDown },
            { key:'svg/breadcrumbs-navigation/check-icon.svg', pathUrl: navBarCheckIcon },

            { key:'svg/personality-quiz/histogram.svg', pathUrl: histogram },
            { key:'svg/personality-quiz/lamp.svg', pathUrl: lamp },

            { key:'svg/weight-metabolic-rate/weight-metabolic-rate-graphic.svg', pathUrl: weightMetabolicRateGraphic },
        ]
    }
};
