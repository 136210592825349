export const FEMALE_TYPE = [
    {
        key: 'rounded',
        text: 'body-type-option-female-type-rounded',
        image: 'svg/body-type/female/rounded.svg',
    },
    {
        key: 'rectangle',
        text: 'body-type-option-female-type-rectangle',
        image: 'svg/body-type/female/rectangle.svg',
    },
    {
        key: 'inv-triangle',
        text: 'body-type-option-female-type-inv-triangle',
        image: 'svg/body-type/female/inv-triangle.svg',
    },
    {
        key: 'triangle',
        text: 'body-type-option-female-type-triangle',
        image: 'svg/body-type/female/triangle.svg',
    },
    {
        key: 'hourglass',
        text: 'body-type-option-female-type-hourglass',
        image: 'svg/body-type/female/hourglass.svg',
    },
];

export const MALE_TYPE = [
    {
        key: 'rounded',
        text: 'body-type-option-male-type-rounded',
        image: 'svg/body-type/male/rounded.svg'
    },
    {
        key: 'rectangle',
        text: 'body-type-option-male-type-rectangle',
        image: 'svg/body-type/male/rectangle.svg'
    },
    {
        key: 'inv-triangle',
        text: 'body-type-option-male-type-inv-triangle',
        image: 'svg/body-type/male/inv-triangle.svg'
    }
];
