import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(() => ({
    logosWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '32px',
        margin: '24px 0'
    },
    welcomeDesktopInfoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '34px',
        padding: '0 16px',
    },
    welcomeDesktopTitle: {
        fontSize: '28px',
        lineHeight: '33px',
        margin: '80px 0 8px',
    },
    welcomeDesktopSubtitle: {
        fontSize: '16px',
        lineHeight: '20px',
        color: '#5E626B',
        margin: '0 auto 40px',
        maxWidth: '544px'
    },
    withoutMaxWidth: {
        maxWidth: 'none'
    },
    qrCodeImage: {
       width: '200px',
       height: '200px',
       alignSelf: 'flex-start',
       marginLeft: '20px',
    },
    biggerWidth: {
        width: '306px',
        height: '306px',
    },
    listWrapper: {
        maxWidth: '913px',
    },
    list: {
        listStyle: 'decimal',
        listStylePosition: 'outside',

        '& > div:last-child': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',

            '& > h2': {
                margin: 0
            },

            '& > div': {
                flexBasis: 0
            }
        },
    },
    listTitle: {
        fontSize: '20px',
        lineHeight: '28px',
        textAlign: 'left',
        margin: '0 0 16px',
    },
    listItem: {
        fontSize: '20px',
        lineHeight: '28px',
        textAlign: 'left',
        wordBreak: 'break-word',
        margin: '0 0 8px',

        '& li': {
            marginLeft: '28px',
        },

        '@media (max-width: 500px)' : {
            '& li': {
                marginLeft: '36px',
            }
        },

        '& a': {
            color: '#1375D6',
            textDecoration: 'underline',
            textUnderlineOffset: '4px',
        }
    },
    darkLink: {

        '& > li': {

            '& > a': {
                color: '#010101',
                textDecoration: 'underline',
                textUnderlineOffset: '4px',
            }
        }
    },
    '@media screen and (min-width: 768px)': {
        logosWrapper: {
            margin: '80px 0'
        }
    }
}));
