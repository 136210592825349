import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';
import { BreadcrumbsNavigationKeys } from 'constants/breadcrumbs-navigation';

import { SkipTrialModalWindow } from 'pages/upgrade-plan/skip-trial-modal-window';
import { LoadingModalWindow } from 'pages/paywall/payment/loading-modal-window';
import { EbookGiftBlock } from 'pages/upgrade-plan/content/ebook-gift-block';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { BreadcrumbsNavigation } from 'components/breadcrumbs-navigation';

import { useStyles } from './styles';

interface ContentProps {
    loading: boolean;
    showSkipTrialModalWindow: boolean;
    monthsCountToReachGoalWeight: number;
    adjustedGoalWeight: number;
    handleSkipTrialModalWindow: () => void;
    handleUpgradeTrialModalWindow: () => void;
    handleSkipUpgradeSubscription: (event: string) => void;
    handleUpgradeSubscription: () => void;
    originalPrice: number;
    trialPrice: number;
    currencyLabel: string;
    totalPrice: number;
    ebookPrice: number;
}

export const Content: FC<ContentProps> = ({
                                              loading,
                                              showSkipTrialModalWindow,
                                              monthsCountToReachGoalWeight,
                                              adjustedGoalWeight,
                                              handleSkipTrialModalWindow,
                                              handleUpgradeTrialModalWindow,
                                              handleSkipUpgradeSubscription,
                                              handleUpgradeSubscription,
                                              originalPrice,
                                              trialPrice,
                                              currencyLabel,
                                              totalPrice,
                                              ebookPrice,
                                          }) => {
    const { t } = useTranslation();

    const {
        pageWrapper,
        skipUpgradeButton,
        title,
        description,
        image,
        offerWrapper,
        calculationBlock,
        savingPercentWrapper,
        savingPercent,
        blue,
        totalWrapper,
        infoBlock,
        upgradePlanButton,
        continueWithTrialButton,
    } = useStyles();

    const pageImages = useBackgroundImageService(['svg/upgrade-plan/content/commitment-graph.svg']);

    const subscriptionPeriod = t('paywall-payment-info-subscription-duration-week');

    const originalPriceValue = Number.isInteger(originalPrice) ? originalPrice : originalPrice.toFixed(2);
    const saving = originalPrice - totalPrice;
    const savingValue = Number.isInteger(saving) ? saving : saving.toFixed(2);
    const savingInPercent = Math.ceil((saving * 100) / originalPrice);
    const trialPriceValue = Number.isInteger(trialPrice) ? trialPrice : trialPrice.toFixed(2);
    const todayTotalPrice = originalPrice + ebookPrice;
    const todayTotalPriceValue = Number.isInteger(todayTotalPrice) ? todayTotalPrice : todayTotalPrice.toFixed(2);
    const todayTotalPriceWithDiscount = totalPrice - trialPrice;
    const todayTotalPriceWithDiscountValue = Number.isInteger(todayTotalPriceWithDiscount) ? todayTotalPriceWithDiscount : todayTotalPriceWithDiscount.toFixed(2);

    const wl_post_paywall_progress_bar = localStorage.getItem(ExperimentsKeys.wl_post_paywall_progress_bar) === '1';

    return (
        <div className={pageWrapper}>
            {wl_post_paywall_progress_bar && <BreadcrumbsNavigation activeSection={BreadcrumbsNavigationKeys.addToPlan}
                                                                    completedSections={[BreadcrumbsNavigationKeys.healthPlan]} />}

            <NextButton className={skipUpgradeButton}
                        typographyText={t('upgrade-plan-skip-button')}
                        onClick={() => handleSkipUpgradeSubscription('PlanUpgradeSkippedOnTop')} />

            <LoadingModalWindow loading={loading} />

            <SkipTrialModalWindow showSkipTrialModalWindow={showSkipTrialModalWindow}
                                  monthsCountToReachGoalWeight={monthsCountToReachGoalWeight}
                                  adjustedGoalWeight={adjustedGoalWeight}
                                  subscriptionPeriod={subscriptionPeriod}
                                  handleSkipClick={handleSkipTrialModalWindow}
                                  handleUpgradeClick={handleUpgradeTrialModalWindow}
            />

            <Typography className={title} variant={TypographyVariants.h1} typographyNext>
                <Trans i18nKey='upgrade-plan-title' />
            </Typography>

            <Typography className={description} variant={TypographyVariants.h2}>
                <Trans i18nKey='upgrade-plan-description' components={[<strong></strong>]} />
            </Typography>

            <img className={image} src={pageImages[0]} alt='Commitment Graph' />

            <div className={offerWrapper}>
                <Typography variant={TypographyVariants.h1}>
                    <Trans i18nKey='upgrade-plan-price-breakdown-title' />
                </Typography>

                <div className={calculationBlock}>
                    <Typography variant={TypographyVariants.h3}>
                        <Trans i18nKey='upgrade-plan-week-plan-with-trial' values={{ subscriptionPeriod }} />
                    </Typography>
                    <Typography variant={TypographyVariants.h3}>
                        {currencyLabel}{originalPriceValue}
                    </Typography>
                </div>

                <div className={calculationBlock}>
                    <Typography className={blue} variant={TypographyVariants.h3}>
                        <Trans i18nKey='upgrade-plan-skip-trial' />
                    </Typography>

                    <div className={savingPercentWrapper}>
                        <div className={savingPercent}>
                            <Typography variant={TypographyVariants.h3}>
                                <Trans i18nKey='upgrade-plan-saving-per-week-in-percent'
                                       values={{ savingInPercent }} />
                            </Typography>
                        </div>

                        <Typography className={blue} variant={TypographyVariants.h3}>
                            <Trans i18nKey='upgrade-plan-saving-per-week'
                                   values={{ currencyLabel, savingValue }} />
                        </Typography>
                    </div>
                </div>


                <div className={calculationBlock}>
                    <Typography variant={TypographyVariants.h3}>
                        <Trans i18nKey='upgrade-plan-trial-deduction' />
                    </Typography>
                    <Typography variant={TypographyVariants.h3}>
                        -{currencyLabel}{trialPriceValue}
                    </Typography>
                </div>

                <EbookGiftBlock ebookPrice={ebookPrice} currencyLabel={currencyLabel} />

                <div className={calculationBlock}>
                    <Typography variant={TypographyVariants.h3}>
                        <Trans i18nKey='upgrade-plan-total' />
                    </Typography>
                    <div className={totalWrapper}>
                        <Typography variant={TypographyVariants.h3}>
                            {currencyLabel}{todayTotalPriceWithDiscountValue}
                        </Typography>
                        <Typography variant={TypographyVariants.h3}>
                            {currencyLabel}{todayTotalPriceValue}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className={infoBlock}>
                <ul>
                    <li>
                        <Typography variant={TypographyVariants.h3}>
                            <Trans i18nKey='upgrade-plan-subscription-info' />
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.h3}>
                            <Trans i18nKey='upgrade-plan-charge-info'
                                   values={{ currencyLabel, todayTotalPriceWithDiscountValue, subscriptionPeriod, originalPrice }} />
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.h3}>
                            <Trans i18nKey='upgrade-plan-cancel-info' />
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.h3}>
                            <Trans i18nKey='upgrade-plan-refund-info' />
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.h3}>
                            <Trans i18nKey='upgrade-plan-multicurrency-info' />
                        </Typography>
                    </li>
                </ul>
            </div>

            <NextButton className={upgradePlanButton}
                        typographyText={t('upgrade-plan-skip-trial-button', { currencyLabel, savingPerWeek: saving, subscriptionPeriod })}
                        onClick={handleUpgradeSubscription} />
            <NextButton className={continueWithTrialButton}
                        typographyText={t('upgrade-plan-continue-with-trial-button')}
                        onClick={() => handleSkipUpgradeSubscription('PlanUpgradeSkipped')} />
        </div>
    );
};
