import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { FEMALE_TYPE, MALE_TYPE } from 'constants/body-type';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { VerticalCard } from 'components/vertical-card';

import { useStyles } from './styles';

type TypeData = {
    key: string;
    text: string;
    image: string;
    description?: string;
};

interface BodyTypeDataInterface {
    male: TypeData[];
    female: TypeData[];
}

export const BodyType = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { menCards, womenCards } = useStyles();

    const { pageValue: bodyType, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyType],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const BodyGender = (localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]) || 'female') as keyof BodyTypeDataInterface;

    const onClick = (value: TypeData) => {
        setPageValue(value.key);

        saveAnswers({
            key: LocalStorageKeys[RoutePath.BodyType],
            itemQuestion: { question: t('body-type-title'), value: t(value.text) },
        });

        pageConfiguration.handleOnPageComplete(value.key);
    };

    const bodyTypeData: BodyTypeDataInterface = { male: MALE_TYPE, female: FEMALE_TYPE };
    const className = BodyGender === 'male' ? menCards : womenCards;
    return (
        <>
            <Typography variant={TypographyVariants.h1}>{t('body-type-title')}</Typography>

            <div className={className}>
                {bodyTypeData[BodyGender].map((data) => {
                    return (
                        <VerticalCard
                            onClick={onClick}
                            key={data.text}
                            data={data}
                            isSelected={bodyType === data.key}
                        />
                    );
                })}
            </div>
        </>
    );
};
