import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';
import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

export const FoodRestrictions = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.FoodRestrictions],
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.FoodRestrictions]}-other-userAnswer`,
        defaultValue: '',
    });

    const specificDiet = localStorage.getItem(LocalStorageKeys[RoutePath.SpecificDiet]);

    const multiChoicesControl: MultiChoiceType[] = [
        { key: 'all', value: t('food-restrictions-option-eat-everything') },
        { key: 'nuts', value: t('food-restrictions-option-nuts') },
        { key: 'shellfish', value: t('food-restrictions-option-shellfish') },
        { key: 'gluten', value: t('food-restrictions-option-gluten') },
        { key: 'milk', value: t('food-restrictions-option-milk') },
        { key: 'eggs', value: t('food-restrictions-option-eggs') },
        { key: 'soybeans', value: t('food-restrictions-option-soybeans') },
        { key: 'other', value: t('food-restrictions-option-other'), userAnswer, setUserAnswer },
    ];

    const multiChoicesVersion2: MultiChoiceType[] = [
        { key: 'fish', value: t('food-restrictions-option-version-2-fish') },
        { key: 'eggs', value: t('food-restrictions-option-eggs') },
        { key: 'shellfish', value: t('food-restrictions-option-shellfish') },
        { key: 'dairy', value: t('food-restrictions-option-version-2-dairy') },
        { key: 'soy', value: t('food-restrictions-option-version-2-soy') },
        { key: 'gluten', value: t('food-restrictions-option-version-2-gluten') },
        { key: 'tree-nuts', value: t('food-restrictions-option-version-2-tree-nuts') },
        { key: 'peanuts', value: t('food-restrictions-option-version-2-peanuts') },
        { key: 'none', value: t('food-restrictions-option-version-2-none') },
    ];

    if (specificDiet === 'vegetarian') {
        multiChoicesControl.splice(3, 1); // gluten
    }

    if (specificDiet === 'vegan') {
        multiChoicesControl.splice(6, 1); // soybeans
        multiChoicesControl.splice(5, 1); // eggs
        multiChoicesControl.splice(3, 1); // gluten
    }

    if (specificDiet === 'paleo') {
        multiChoicesControl.splice(7, 1); // other
        multiChoicesControl.splice(4, 1); // milk
    }

    const wl_questions_update = localStorage.getItem(ExperimentsKeys.wl_questions_update) === '1';
    const multiChoices = wl_questions_update ? multiChoicesVersion2 : multiChoicesControl;

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: LocalStorageKeys[RoutePath.FoodRestrictions],
            itemQuestion: { question: t('food-restrictions-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue.includes('other') ? {
                pageValue,
                userAnswer: userAnswer.trim()
            } : { pageValue }
        );
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('food-restrictions-title')}
            </Typography>
            <Typography variant={TypographyVariants.h2}>{t('food-restrictions-subtitle')}</Typography>
            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                    disabled={pageValue[0] === 'all' && v.key !== 'all'}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
