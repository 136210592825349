import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    mainContent: {
        maxWidth: 560,
        margin: '0 auto',
        position: 'relative',
        padding: ({ scrollableContent }: any) => scrollableContent ? '0px 16px 20px' : '0px 16px',

        '@media only screen and (min-width: 768px)' : {
            padding: ({ scrollableContent }: any) => scrollableContent ? '0px 0px 20px' : '0px',
        }
    },
    minHeight: {
        minHeight: '70%',

        '@media only screen and (min-width: 768px)' : {
            minHeight: 'calc(75% - 60px)', // 60px - header and progress bar height
        },

        '@media only screen and (min-width: 1900px)' : {
            minHeight: 'calc(85% - 60px)', // 60px - header and progress bar height
        }
    }
});
