import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    modalWrapper: {
        maxWidth: '560px',
        padding: '24px 16px',
        margin: '0 16px',
    },
    title: {
        margin: '0 0 24px',
        '& span': {
            fontWeight: 600,
        }
    },
    graphBlock: {
        textAlign: 'center',
        margin: '24px auto',
        '& img': {
            width: '262px',
            height: '109px',
        }
    },
    discountBlock: {
        background: '#DDF3ED',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        padding: '12px 16px',
        gap: '12px',
        borderRadius: '8px',
        '& img': {
            width: '24px'
        }
    },
    discountTitle: {
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'inherit',
        '& span': {
            fontWeight: 600,
        }
    },
    continueButton: {
        ...nextButton.nextButton,
        marginTop: '24px',
        marginBottom: '16px',
        maxWidth: '100%',
    },
    info: {
        color: '#5E626B',
        textAlign: 'left',
        fontSize: '12px',
        lineHeight: '16px',
    },
    '@media screen and (min-width: 768px)': {
        modalWrapper: {
            padding: '40px 24px',
            margin: '0 8px',
        },
        title: {
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '28px',
        },
        graphBlock: {
            margin: '32px auto',
            '& img': {
                width: '364px',
                height: '151px',
            }
        },
        discountBlock: {
            padding: '16px',
            '& img': {
                width: '32px'
            }
        },
        discountTitle: {
            fontSize: '20px',
            lineHeight: '28px',
        },
        continueButton: {
            marginTop: '40px',
        },
        info: {
            textAlign: 'center',
            fontSize: '16px',
            lineHeight: '26px',
        }
    },
    '@media screen and (max-width: 320px)': {
        graphBlock: {
            margin: '32px auto',
            '& img': {
                width: '209px',
                height: '100px',
            }
        },
    },
}));
