import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const WorkoutTimes = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageValue: workoutTimes, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.WorkoutTimes],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);
        saveAnswers({
            key: 'workout-times',
            itemQuestion: { question: t('workout-times-title'), value: t(answer.value) },
        });

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        {
            key: 'not-much',
            value: t('workout-times-option-do-not-exercise'),
        },
        {
            key: '1-2-times',
            value: t('workout-times-option-1-2-times'),
        },
        {
            key: '3-5-times',
            value: t('workout-times-option-3-5-times'),
        },
        {
            key: '5-7-times',
            value: t('workout-times-option-5-7-times'),
        },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                <Trans i18nKey='workout-times-title' components={[<i></i>]}/>
            </Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === workoutTimes}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
