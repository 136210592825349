import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    womenCards: {
        textAlign: 'center',
        '& div[class^="buttonWrapper"]': {
          display: 'inline',
        },
        '& button': {
            display: 'inline-block',
            maxWidth: '31%',
            width: '100%',
            alignSelf: 'center',
            padding: '12px',
            margin: '0 6px 12px',
            '&:nth-child(1)': {
                marginLeft: 0
            },
            '&:nth-child(3n + 3)': {
                marginRight: 0
            },
            '& > div': {
                height: '40px',
                marginBottom: '12px'
            }
        }
    },
    menCards: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        margin: '0 auto',
        width: '100%',
        '& div[class^="buttonWrapper"]': {
            display: 'contents',
        },
        '& button': {
            width: '31%',
            padding: '12px',
            justifyContent: 'space-between',
            '& > div': {
                height: '40px',
                marginBottom: '12px'
            }
        }
    },
    '@media (max-width: 365px)' : {
        womenCards: {
            '& button': {
                maxWidth: '88px',
                margin: '0 6px 6px',
                '&:nth-child(4n + 4)': {
                    marginLeft: 6
                },
                '&:nth-child(3n + 3)': {
                    marginRight: 0
                }
            }
        }
    },
    '@media (max-width: 250px)' : {
        menCards: {
            justifyContent: 'center',
            '& button': {
                width: '100%'
            }
        }
    },
});
