import { SignUpApiProvider } from 'api/sign-up.api';

import { SUBSCRIPTION_ID, CUSTOMER_ID, BILLING_PLATFORM, SubscriptionTypeKeys } from 'constants/payment';
import { PlanForUpdate } from 'constants/upgrade-plan';
import { ExperimentsKeys } from 'constants/experiments';

export const useUpgradeInfo = () => {

    const UpgradeCustomerSubscription = (plan: PlanForUpdate) => {
        const subscriptionId = localStorage.getItem(SUBSCRIPTION_ID) as string || '';
        const customerId = localStorage.getItem(CUSTOMER_ID) as string || '';
        const useCfWorker = localStorage.getItem(ExperimentsKeys.pwaNativeSplitVariationVersion2) === '2';

        // Buying upgrade plan trial is free
        const trialAmount = 0;
        const trialPeriodDays = 0;

        const paymentType = BILLING_PLATFORM.Primer;

        const cfData = {
            plan: {
                code: plan.key,
                currencyCode: plan.currency,
                price: plan.price,
                chargePeriodDays: plan.chargePeriod,
                trialPrice: trialAmount,
                trialPeriodDays: trialPeriodDays,
            },
            discount: plan?.discount,
        };

        if (useCfWorker) {
            return SignUpApiProvider.upgradeSubscriptionCf(cfData);
        } else {
            return SignUpApiProvider.upgradeSubscription({
                customerId,
                subscriptionId,
                plan,
                amount: trialAmount,
                trialPeriodDays,
                paymentType,
                subscriptionType: SubscriptionTypeKeys.loseWeightUpsell,
            });
        }
    };

    return {
        UpgradeCustomerSubscription,
    };
};
