import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { NextButton } from 'components/next-button';
import { Typography } from 'components/typography';

import logo2 from 'assets/images/svg/logo-black.svg';

import { useStyles } from './styles';

export const PersonalityQuizResults = () => {
    const { t } = useTranslation();
    const { pageConfiguration } = useContext(BaseContext);

    const { pageWrapper,
        pageContent,
        title,
        blueText,
        description,
        listTitle,
        listItem
    } = useStyles();

    const quizPages = [
        RoutePath.PersonalityQuizEnjoy,
        RoutePath.PersonalityQuizFocus,
        RoutePath.PersonalityQuizMakeDecisions,
        RoutePath.PersonalityQuizPrefer,
    ];

    const quizResult = quizPages
        .map((page: string) => localStorage.getItem(LocalStorageKeys[page]) as string)
        .map((pageResult: string) => pageResult[0])
        .join('').toUpperCase();

    const listItems = [];

    for (let i = 1; i <= 4; i++) {
        const translationKey = `personality-quiz-results-tips-option-${quizResult.toLowerCase()}-${i}`;
        const translation = t(translationKey);

        if (translation !== translationKey) {
            listItems.push(
                <li key={i}>
                    <Typography variant={TypographyVariants.h2} className={listItem}>
                        <Trans i18nKey={translationKey} />
                    </Typography>
                </li>
            );
        }
    }

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete(quizResult);
    };

    return (
        <div className={pageWrapper}>
            <div className={pageContent}>
                <img src={logo2} alt='Able Logo' />

                <Typography variant={TypographyVariants.h1} className={title}>
                    <Trans i18nKey='personality-quiz-results-title'
                           components={[<strong className={blueText}></strong>]}
                           values={{ quizResult }} />
                </Typography>

                <Typography variant={TypographyVariants.h2} className={description}>
                    <Trans i18nKey={`personality-quiz-results-description-${quizResult.toLowerCase()}`} />
                </Typography>

                <Typography variant={TypographyVariants.h2} className={listTitle}>
                    <Trans i18nKey='personality-quiz-results-tips-title' />
                </Typography>

                <ul>
                    {listItems}
                </ul>
            </div>

            <NextButton onClick={handleClick} />
        </div>
    );
};
