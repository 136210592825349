import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { AGES } from 'constants/body-age';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const DietActivity = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: dietActivity, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.DietActivity],
        defaultValue: '',
    });

    const userGender =
        localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]) === 'female'
            ? t('women')
            : t('men');

    const bodyAge = localStorage.getItem(LocalStorageKeys[RoutePath.BodyAge] as string);
    const userAge = AGES.find((age) => age.key === bodyAge)?.value;

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);
        saveAnswers({
            key: 'diet-activity',
            itemQuestion: {
                question: t('diet-activity-subtitle', {
                    userGender,
                    userAge: t(userAge as string).toLowerCase(),
                }),
                value: t(answer.value),
            },
        });

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        {
            key: 'need-work',
            value: t('diet-activity-option-need-work'),
        },
        {
            key: 'some-healthy-habits',
            value: t('diet-activity-option-some-healthy-habits'),
        },
        {
            key: 'eat-well-stay-active',
            value: t('diet-activity-option-eat-well-stay-active'),
        },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('diet-activity-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2}>
                {t('diet-activity-subtitle', {
                    userGender,
                    userAge: t(userAge as string).toLowerCase(),
                })}
            </Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === dietActivity}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
