import * as React from 'react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/button';

import checkIcon from './svg/check.svg';

import { useStyles } from './styles';

interface CopyToClipboardButtonProps {
    text: string;
}

export const CopyToClipboardButton: FC<CopyToClipboardButtonProps> = ({ text }) => {
    const { t } = useTranslation();

    const { button, copied } = useStyles();

    const [textCopied, setTextCopied] = useState<boolean>(false);

    const handleOldSafariWorkaround = () => {
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        document.body.appendChild(el);

        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        setTextCopied(true);
    };

    const handleNewSafariWorkaround = () => {
        const clipboardItem = new ClipboardItem({
            'text/plain': new Promise(async (resolve) => {
                try {
                    resolve(new Blob([text]));
                } catch (error) {
                    console.error('Error creating ClipboardItem in Safari:', error);
                    resolve(new Blob(['']));
                }
            }),
        });

        navigator.clipboard.write([clipboardItem]).then(() => {
            setTextCopied(true);
        }).catch((error) => {
            console.error('Error writing to clipboard in Safari:', error);
        });
    };

    const copyToClipboard = async () => {
        try {
            if (navigator.clipboard) {
                try {
                    const permissions = await navigator.permissions.query({ name: 'clipboard-write' } as unknown as PermissionDescriptor);
                    if (permissions.state === 'granted' || permissions.state === 'prompt') {
                        await navigator.clipboard.writeText(text);
                        setTextCopied(true);
                    } else {
                        console.error('Clipboard permissions denied.');
                        handleNewSafariWorkaround();
                    }
                } catch (permissionError) {
                    handleNewSafariWorkaround();
                }
            } else {
                handleOldSafariWorkaround();
            }
        } catch (error) {
            console.error('Error copying to clipboard:', error);
        }
    };

    return (
        <Button className={`${button} ${textCopied ? copied : ''}`}
                typographyText={textCopied ? t('welcome-copy-to-clipboard-button-copied') : t('welcome-copy-to-clipboard-button-copy')}
                onClick={copyToClipboard}>
            {textCopied && <img src={checkIcon} alt='Check' />}
        </Button>
    );
};
