import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '560px',
        padding: '0 16px 32px',
        margin: '0 auto',

        '& > div:first-child': {
            justifyContent: 'flex-end'
        }
    },
    skipUpgradeButton: {
        ...nextButton.nextButton,
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        background: 'none',
        color: '#EB731A',
        padding: 0,
        margin: '24px 0 0',
    },
    title: {
        margin: '24px 0 16px'
    },
    description: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: '0 0 24px'
    },
    image: {
        width: '100%',
        maxWidth: '358px'
    },
    offerWrapper: {
        width: '100%',
        margin: '24px 0',

        '& h1': {
            textAlign: 'left',
            margin: 'unset',
            padding: '0 0 16px',
            borderBottom: '1px solid #C5C5D1'
        },

        '& h2': {
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 600,
            textAlign: 'left',
        }
    },
    calculationBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',

        '&:first-of-type': {
            marginTop: '16px',
        },

        '&:last-of-type': {
            margin: '24px 0 0',

            '& h3': {
                fontWeight: 600
            },

            '& h3:last-child': {
                fontWeight: 400
            }
        },

        '& h3': {
            fontSize: '16px',
            margin: 'unset'
        }
    },
    savingPercentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '12px'
    },
    savingPercent: {
        background: '#DDF3ED',
        borderRadius: '100px',
        padding: '4px 10px',

        '& h3': {
            fontSize: '16px',
            lineHeight: '18px',
            fontWeight: 600,
            color: '#206F58',
        }
    },
    blue: {
        color: '#1375D6'
    },
    totalWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '12px',

        '& h3:last-child': {
            fontWeight: 400,
            color: '#5E626B',
            textDecoration: 'line-through'
        }
    },
    infoBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '24px 16px',
        background: '#F6F6F6',
        borderRadius: '8px',
        marginBottom: '4px',

        '& ul': {
            listStyle: 'disc',
            listStylePosition: 'outside',
            paddingLeft: '16px',

            '& li': {

                '& h3': {
                    textAlign: 'left',
                    margin: '0 0 12px'
                },

                '&:last-child h3': {
                    margin: 'unset'
                }
            }
        }
    },
    upgradePlanButton: {
        ...nextButton.nextButton,
        height: '100%',
        padding: '16px',
        margin: '24px 0 16px',

        '&:active': {
            ...nextButton.nextButtonFocus,
        }
    },
    continueWithTrialButton: {
        ...nextButton.nextButton,
        height: '100%',
        background: 'none',
        color: '#EB731A',
        padding: '16px',
        margin: 0,
        borderRadius: '8px',
        border: '2px solid #EB731A'
    },
    '@media screen and (min-width: 768px)': {
        pageWrapper: {
            padding: '0 0 40px',
        },
        skipUpgradeButton: {
            margin: '80px 0 0',
        },
        title: {
            lineHeight: '32px',
            margin: '32px 0 16px'
        },
        description: {
            fontSize: '18px',
            lineHeight: '26px',
            margin: '0 0 40px'
        },
        offerWrapper: {
            margin: '40px 0',

            '& h1': {
                lineHeight: '32px'
            },

            '& h2': {
                fontSize: '18px',
                lineHeight: '26px'
            },
        },
        calculationBlock: {

            '&:last-of-type': {
                margin: '16px 0 0',
            },

            '& h3': {
                fontSize: '18px',
                lineHeight: '20px'
            }
        },
        infoBlock: {
            marginBottom: '20px',

            '& ul': {

                '& li': {

                    '& h3': {
                        fontSize: '16px',
                        lineHeight: '20px'
                    }
                }
            }
        },
        upgradePlanButton: {
            minHeight: '56px',
            margin: '20px 0 16px',

            '& span': {
                lineHeight: '24px'
            }
        },
        continueWithTrialButton: {

            '& span': {
                lineHeight: '24px'
            }
        },
    },
    '@media (hover: hover)': {
        upgradePlanButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            },
        }
    }
}));
