import React from 'react';

import { RoutePath } from 'routes/route-path.constant';

import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';

export const BodyAgeTip = () => {
    // @TODO: Move keys - underTwenty and others to enum
    const description = localStorage.getItem(LocalStorageKeys[RoutePath.BodyAge] as string) === 'underTwenty'
        ? 'body-age-tip-young-description'
        : 'body-age-tip-old-description';

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/arm-with-heart.svg'
            text={{
                description: description,
                next: 'body-age-tip-button-next'
            }}
        />
    );
};
