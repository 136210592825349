import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    infoBlock: {
        padding: '16px',
        background: ({ background }: any) => background ? background : '#E8F5FD',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'start',
        columnGap: '12px',

        '& h3': {
            textAlign: 'left',
            margin: 'unset'
        }
    },
    iconWrapper: {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        background: ({ iconBackground }: any) => iconBackground ? iconBackground : '#1375D6',
    },
    infoBlockTitle: {
        fontSize: '16px',
        lineHeight: '18px',
        fontWeight: 600,
        textAlign: 'left',
        margin: '0 0 12px',
    },
    '@media (min-width: 768px)': {
        infoBlock: {
            padding: '20px',
            maxWidth: '610px',
            margin: '0 auto',

            '& img': {
                width: '24px',
                height: '24px'
            },

            '& h3': {
                fontSize: '16px',
                lineHeight: '20px',
            }
        },
        infoBlockTitle: {
            fontSize: '18px',
            lineHeight: '26px',
            margin: '0 0 8px',
        }
    }
});
