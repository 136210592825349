import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutePath } from 'routes/route-path.constant';

import { useUTMSearch } from 'hooks/use-encharge-search.hook';

export const MainGoal = () => {
    const navigate = useNavigate();

    // @description: Add timeout to prevent blink the first screen
    const [isVariantLoaded, setIsVariantLoaded] = useState(false);

    // @TODO: Do we need this exp?
    let progressBarVariation = '0';

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsVariantLoaded(true);
        }, 200);

        return () => clearTimeout(timeoutId);
    }, []);

    const UTMSearch = useUTMSearch();

    useEffect(() => {
        if (isVariantLoaded) {

            if (progressBarVariation === '0') {
                navigate(`../${RoutePath.Goal}${UTMSearch}`);
            }

            if (progressBarVariation === '1') {
                navigate(`../${RoutePath.GoalWithProgressBar}${UTMSearch}`);
            }

        }
    }, [progressBarVariation, isVariantLoaded, navigate]);

    return null;
};
