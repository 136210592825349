import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { RoutePath } from 'routes/route-path.constant';
import { UNIT_SYSTEM } from 'constants/body-height-weight';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { PLANCODE_PRIMERIO } from 'constants/trial-pay';
import { CUSTOMER_ID, SUBSCRIPTION_ID } from 'constants/payment';

import { ABPagesConfiguration } from 'services/ab-pages-configuration.service';

const getNextRoute = (routePath: string) => {
    const routesList = ABPagesConfiguration();

    return routesList[routePath].route.next();
};

const getRoutePathBySearch = (searchParams: URLSearchParams) => {

    const isAllRequired = isAllRequiredExists(searchParams);
    const position = searchParams.get('position');

    if (position === 'afteremail' && isAllRequired) {
        const route = getNextRoute(getNextRoute(RoutePath.Email));
        return route;
    }

    if (position === 'trialpay' && isAllRequired) {
        return RoutePath.TrialPay;
    }

    if (position === 'paywall' && isAllRequired) {
        return RoutePath.Paywall;
    }

    if (position === 'upgrade-plan') {
        if (isAllRequiredForUpgradePlanExists(searchParams)) {
            return RoutePath.UpgradePlan;
        }
    }

    if (position === 'upgrade') {
        if (isAllRequiredForUpgradeExists(searchParams)) {
            return RoutePath.Upgrade;
        }
    }

    return null;
};

// description: Need to check also searchParams since we need it at first run
const isAllRequiredExists = (searchParams: URLSearchParams): boolean => {
    const email =
        Boolean(localStorage.getItem(LocalStorageKeys[RoutePath.Email])) ||
        Boolean(searchParams.get('email'));

    const age =
        Boolean(localStorage.getItem(LocalStorageKeys[RoutePath.BodyAge])) ||
        Boolean(searchParams.get('userage')) ||
        Boolean(searchParams.get('age'));

    const gender =
        Boolean(localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender])) ||
        Boolean(searchParams.get('gender'));

    const unit =
        Boolean(localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem)) ||
        Boolean(searchParams.get('bodyweightmetricsystem')) ||
        Boolean(searchParams.get('bodyheightmetricsystem'));

    const height =
        Boolean(localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm) || localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt)) ||
        Boolean(searchParams.get('currentbodyheight'));

    const weight =
        Boolean(localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg) || localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs)) ||
        Boolean(searchParams.get('currentbodyweight'));

    const weightGoal =
        Boolean(localStorage.getItem(LocalStorageKeys[RoutePath.BodyIdealWeight])) ||
        Boolean(searchParams.get('goalbodyweight')) ||
        Boolean(searchParams.get('targetweight'));

    return email && age && gender && unit && height && weight && weightGoal;
};

const isAllRequiredForUpgradePlanExists = (searchParams: URLSearchParams): boolean => {
    const email =
        Boolean(localStorage.getItem(LocalStorageKeys[RoutePath.Email])) ||
        Boolean(searchParams.get('email'));

    const plan =
        Boolean(localStorage.getItem(LocalStorageKeys[RoutePath.UpgradePlan])) ||
        Boolean(searchParams.get('newplan'));

    const customerId =
        Boolean(localStorage.getItem(CUSTOMER_ID)) ||
        Boolean(searchParams.get('customerid'));

    const subscriptionId =
        Boolean(localStorage.getItem(SUBSCRIPTION_ID)) ||
        Boolean(searchParams.get('subscriptionid'));

    const unit =
        Boolean(localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem)) ||
        Boolean(searchParams.get('bodyweightmetricsystem'));

    const weight =
        Boolean(localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg) || localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs)) ||
        Boolean(searchParams.get('currentbodyweight'));

    const height =
        Boolean(localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm) || localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt)) ||
        Boolean(searchParams.get('currentbodyheight'));

    const weightGoal =
        Boolean(localStorage.getItem(LocalStorageKeys[RoutePath.BodyIdealWeight])) ||
        Boolean(searchParams.get('goalbodyweight')) ||
        Boolean(searchParams.get('targetweight'));

    return email && plan && weight && height && weightGoal && unit && (customerId || subscriptionId);
};

const isAllRequiredForUpgradeExists = (searchParams: URLSearchParams): boolean => {
    const email =
        Boolean(localStorage.getItem(LocalStorageKeys[RoutePath.Email])) ||
        Boolean(searchParams.get('email'));

    const customerId =
        Boolean(localStorage.getItem(CUSTOMER_ID)) ||
        Boolean(searchParams.get('customerid'));

    return email && customerId;
};

const buildUTMSearch = (searchParams: URLSearchParams): string => {
    const search = {
        utm_medium: searchParams.get('utm_medium') as string,
        utm_source: searchParams.get('utm_source') as string,
        utm_campaign: searchParams.get('utm_campaign') as string,
        utm_campaign_id: searchParams.get('utm_campaign_id') as string,
        utm_content: searchParams.get('utm_content') as string,
        utm_term: searchParams.get('utm_term') as string,
        utm_client_id: searchParams.get('utm_client_id') as string,
        utm_adgroup_id: searchParams.get('utm_adgroup_id') as string,
        utm_adgroup: searchParams.get('utm_adgroup') as string,
        utm_content_id: searchParams.get('utm_content_id') as string,
        gclid: searchParams.get('gclid') as string,
        orig_client_id: searchParams.get('orig_client_id') as string,
        affise_clickid: searchParams.get('affise_clickid') as string,
        affid: searchParams.get('affid') as string,
    };

    // @ts-ignore
    Object.keys(search).forEach(k => !search[k] && delete search[k]);

    if (Object.keys(search).length) {
        return `?${(new URLSearchParams(search)).toString()}`;
    }

    return '';
};

export const useUTMSearch = () => {
    const { search } = useLocation();

    const searchParams = new URLSearchParams(search);

    return buildUTMSearch(searchParams);
};

export const useEnchargeSearch = () => {
    const { search } = useLocation();

    const searchParams = new URLSearchParams(search?.toLowerCase());
    const routePath = getRoutePathBySearch(searchParams);

    const UTMSearch = buildUTMSearch(searchParams);

    const isRequiredKeysExists = (requiredKeys: string[]): boolean => {
        if (!requiredKeys || !requiredKeys.length) {
            return true;
        }

        return requiredKeys.every(key => {
            if (Array.isArray(key)) {
                return key.some(k => Boolean(localStorage.getItem(k)));
            }

            return Boolean(localStorage.getItem(key));
        });
    };

    useEffect(() => {

        //@description: Encharge - fill the storage and redirect user back to the onboarding
        if (search) {
            const urlSearchParams = new URLSearchParams(search); // @description: DB - case sensitive
            for (let param of urlSearchParams) {
                const key = param[0]?.toLowerCase();

                if (key === 'gender') {
                    localStorage.setItem(LocalStorageKeys[RoutePath.BodyGender], param[1]);
                } else if (key === 'userage' || key === 'age') {
                    localStorage.setItem(LocalStorageKeys[RoutePath.BodyAge], param[1]);
                } else if (key === 'bodyweightmetricsystem') {
                    const unitSystem = param[1].toLowerCase() === 'kg' ? UNIT_SYSTEM.metric : UNIT_SYSTEM.imperial;

                    localStorage.setItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem, unitSystem);
                } else if (key === 'bodyheightmetricsystem') {
                    const unitSystem = param[1].toLowerCase() === 'cm' ? UNIT_SYSTEM.metric : UNIT_SYSTEM.imperial;

                    localStorage.setItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem, unitSystem);
                } else if (key === 'currentbodyweight') {
                    const tab = searchParams.get('bodyweightmetricsystem')?.toLowerCase() === 'kg'; // true for metric; false for imperial

                    if (tab) {
                        localStorage.setItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, param[1]);
                    } else {
                        localStorage.setItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs, param[1]);
                    }
                } else if (key === 'currentbodyheight') {
                    const tab = searchParams.get('bodyheightmetricsystem')?.toLowerCase() === 'cm'; // true for metric; false for imperial

                    if (tab) {
                        localStorage.setItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, param[1]);
                    } else {
                        const height = param[1]?.split('.');
                        localStorage.setItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt, height[0]);
                        localStorage.setItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightInches, height[1] || '0');
                    }
                } else if (key === 'goalbodyweight' || key === 'targetweight') {
                    localStorage.setItem(LocalStorageKeys[RoutePath.BodyIdealWeight], param[1]);
                } else if (key === 'email') {
                    localStorage.setItem(LocalStorageKeys[RoutePath.Email], param[1]);
                } else if (key === 'price') {
                    const currency = searchParams.get('currency');
                    const planObject = PLANCODE_PRIMERIO.find(plan => {
                        const priceInBasicUnit = plan.trialAmount / 100;
                        const priceForAnalytics = Number.isInteger(priceInBasicUnit) ?
                            String(priceInBasicUnit) :
                            String(priceInBasicUnit.toFixed(2));
                        return priceForAnalytics === param[1] && plan.currencyCode === currency?.toUpperCase();
                    });

                    localStorage.setItem(LocalStorageKeys[RoutePath.TrialPay], JSON.stringify(planObject));
                } else {
                    localStorage.setItem(key, param[1]);
                }
            }
        }

    }, [search]);

    return {
        routePath,
        UTMSearch,
        isRequiredKeysExists,
    };
};
