import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    text2: {
        margin: '0 0 12px 0',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    bar: {
        height: '48px !important',
        borderRadius: '8px',
        '& > div': {
            borderRadius: '8px'
        }
    }
});
