import { DEFAULT_PAGES_CONFIGURATION, PageConfigurationType } from 'services/default-pages-configuration.service';
import { ExperimentsKeys } from 'constants/experiments';
import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';

export const ABPagesConfiguration = () => {
    let finalConfiguration = Object.assign({}, DEFAULT_PAGES_CONFIGURATION);
    const wl_personality_quiz = localStorage.getItem(ExperimentsKeys.wl_personality_quiz);
    const wl_metabolic_rate_screen = localStorage.getItem(ExperimentsKeys.wl_metabolic_rate_screen);

    if (wl_personality_quiz === '1') {
        const requiredKeys = [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ];

        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.PastTrials]: {
                ...finalConfiguration[RoutePath.PastTrials],
                route: {
                    next: () => (JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.PastTrials]) as string)[0]) === 'none' ? RoutePath.PersonalityQuiz : RoutePath.PastTrialsTip,
                },
            },
            [RoutePath.PastTrialsTip]: {
                ...finalConfiguration[RoutePath.PastTrialsTip],
                route: {
                    next: () => RoutePath.PersonalityQuiz,
                },
            },
            [RoutePath.PersonalityQuiz]: {
                customWrapper: true,
                header: {
                    email: false,
                    logo: false,
                    progress: null,
                },
                route: {
                    next: () => RoutePath.PersonalityQuizEnjoy,
                },
                events: {
                    onEnter: () => 'OnboardingWeightPersonalityIntroViewed',
                    onLeave: () => 'OnboardingWeightPersonalityIntroClicked',
                },
                requiredKeys: requiredKeys
            },
            [RoutePath.PersonalityQuizEnjoy]: {
                header: {
                    email: true,
                    logo: false,
                    progress: {
                        percents: 72,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.PersonalityQuizFocus,
                },
                events: {
                    onEnter: () => 'OnboardingWeightPersonalityQuestionOneViewed',
                    onLeave: () => 'OnboardingWeightPersonalityQuestionOneClicked',
                },
                requiredKeys: requiredKeys
            },
            [RoutePath.PersonalityQuizFocus]: {
                header: {
                    email: true,
                    logo: false,
                    progress: {
                        percents: 74,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.PersonalityQuizMakeDecisions,
                },
                events: {
                    onEnter: () => 'OnboardingWeightPersonalityQuestionTwoViewed',
                    onLeave: () => 'OnboardingWeightPersonalityQuestionTwoClicked',
                },
                requiredKeys: requiredKeys
            },
            [RoutePath.PersonalityQuizMakeDecisions]: {
                header: {
                    email: true,
                    logo: false,
                    progress: {
                        percents: 76,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.PersonalityQuizPrefer,
                },
                events: {
                    onEnter: () => 'OnboardingWeightPersonalityQuestionThreeViewed',
                    onLeave: () => 'OnboardingWeightPersonalityQuestionThreeClicked',
                },
                requiredKeys: requiredKeys
            },
            [RoutePath.PersonalityQuizPrefer]: {
                header: {
                    email: true,
                    logo: false,
                    progress: {
                        percents: 78,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.PersonalityQuizLoading,
                },
                events: {
                    onEnter: () => 'OnboardingWeightPersonalityQuestionFourViewed',
                    onLeave: () => 'OnboardingWeightPersonalityQuestionFourClicked',
                },
                requiredKeys: requiredKeys
            },
            [RoutePath.PersonalityQuizLoading]: {
                customWrapper: true,
                header: {
                    email: false,
                    logo: false,
                    progress: null,
                },
                route: {
                    next: () => RoutePath.PersonalityQuizResults,
                },
                events: {
                    onEnter: () => 'OnboardingWeightPersonalityLoading',
                },
                requiredKeys: requiredKeys
            },
            [RoutePath.PersonalityQuizResults]: {
                customWrapper: true,
                header: {
                    email: false,
                    logo: false,
                    progress: null,
                },
                route: {
                    next: () => RoutePath.BehaviourChangeSelf,
                },
                events: {
                    onEnter: () => 'OnboardingWeightPersonalityResultsViewed',
                    onLeave: () => 'OnboardingWeightPersonalityResultsClicked',
                },
                requiredKeys: requiredKeys
            },
        }) as PageConfigurationType;
    }

    if (wl_metabolic_rate_screen === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.BodyWeight]: {
                ...finalConfiguration[RoutePath.BodyWeight],
                route: {
                    next: () => RoutePath.WeightMetabolicRate,
                },
            },
            [RoutePath.WeightMetabolicRate]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: null,
                        label: '',
                    },
                },
                route: {
                    next: () => RoutePath.BodyIdealWeight,
                },
                events: {
                    onEnter: () => 'OnboardingMetabolicRateViewed',
                    onLeave: () => 'OnboardingMetabolicRateClicked'
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyGender],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
        }) as PageConfigurationType;
    }

    return finalConfiguration;
};
