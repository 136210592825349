import React, { useContext, useMemo } from 'react';

import { useTranslation, Trans } from 'react-i18next';

import dayjs from 'dayjs';

import { BaseContext } from 'context/base.context';

import { useBodyPlanData } from 'hooks/use-body-plan-data.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { WeightChart } from 'components/charts/weight-chart';

import { useStyles } from './styles';

export const GoalChart = () => {
    const { t } = useTranslation();

    const { text, goalTitleClass, goalTextClass } = useStyles();
    const { pageConfiguration } = useContext(BaseContext);

    const {
        finalDate,
        weightPlan,
        monthsPlan,
        goal,
        unit,
        lossDay,
        lossMonth,
        adjustedGoalWeight
    } = useBodyPlanData();

    const daysToWeightGoal = finalDate ? Math.round(finalDate.diff(dayjs(), 'day', true)) : 0;
    const monthsToWeightGoal = finalDate ? Math.round(finalDate.diff(dayjs(), 'months', true)) : 0;
    const tenMonthsExceeded = monthsToWeightGoal >= 10;

    const textDynamicProps = useMemo(() => ({ lossMonth, lossDay }), [lossMonth, lossDay]);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete({ days_to_weight_goal: daysToWeightGoal, ...(tenMonthsExceeded) && { ten_month_exceeded: tenMonthsExceeded } });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h3} className={goalTitleClass}>
                {t('goal-chart-title')}
            </Typography>

            <Typography variant={TypographyVariants.span} className={goalTextClass}>
                {`${adjustedGoalWeight} ${t(unit)} ${t('by')} ${lossMonth}, ${lossDay}`}
            </Typography>

            <WeightChart
                weightPlan={weightPlan}
                monthsPlan={monthsPlan}
                goal={goal}
                unit={unit}
                goalWeight={adjustedGoalWeight}
                earlierFinalDateChart={false}
            />

            <Typography variant={TypographyVariants.h2} className={text}>
                <Trans i18nKey='goal-chart-text' values={textDynamicProps} />
            </Typography>

            <NextButton onClick={handleClick} />
        </>
    );
};
