import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { isNumber } from 'helpers';
import { setGtagClientId } from 'helpers/gtag-client-id';

import { useEnchargeSearch } from 'hooks/use-encharge-search.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { COMPANY_MENTION_FOOTER_NOT_VISIBLE_LOCATIONS } from 'constants/company-mention-footer-not-visible-locations';

import { ThinProgressBar } from 'components/progress-bar-thin';
import { ProgressBarStep } from 'components/progress-bar-step';

import { CompanyMentionFooter } from 'containers/company-mention-footer';

import { LogoHeader } from '../header/logo';
import { EmailHeader } from '../header/email';
import { Footer } from '../footer';

import { useStyles } from './styles';

export const Content = ({ children }: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { routePath, UTMSearch, isRequiredKeysExists } = useEnchargeSearch();

    const { pageConfiguration } = useContext(BaseContext);

    //@TODO: [clean] Do we need it?
    let progressBarExperiment = false;

    const page = pageConfiguration
        .setNavigate(navigate)
        .setConfigurationByLocation(location)
        .getPageConfiguration();

    let backButtonNotAvailableLocations = [
        RoutePath.GoalWithProgressBar,
        RoutePath.MainGoal,
        RoutePath.HitGoalChartImportantEvent,
        RoutePath.TrialPay,
        RoutePath.UpgradePlan,
        RoutePath.Upgrade,
        RoutePath.Congrats,
        RoutePath.Welcome
    ];

    if (location.pathname === RoutePath.UpgradeTip) {
        const isBoughtEbook = localStorage.getItem(LocalStorageKeys[RoutePath.Upgrade]) === 'true';
        if (isBoughtEbook) {
            backButtonNotAvailableLocations.push(RoutePath.UpgradeTip);
        }
    }

    const backButtonAvailable = !backButtonNotAvailableLocations.includes(location.pathname);

    useEffect(() => {
        pageConfiguration.handleOnPageEnter();
    }, [page]);

    useEffect(() => {
        if (routePath) {
            navigate(`..${routePath}${UTMSearch}`);

            // @description: GA set a new client_id after redirect
            setTimeout(() => {
                setGtagClientId();
            }, 100);
        }
    }, [routePath]);

    const { mainContent, minHeight } = useStyles({
        scrollableContent: page.scrollableContent,
    });

    const mainFooterVisible = [RoutePath.MainGoal, RoutePath.Paywall].includes(location.pathname);

    let companyMentionVariationExp = false;
    // const companyMentionVariationExp = useExperiment(process.env.REACT_APP_GOOGLE_OPTIMIZE_COMPANY_MENTION_EXPERIMENT_ID as string) === '1';
    const companyMentionFooterVisible = companyMentionVariationExp && !COMPANY_MENTION_FOOTER_NOT_VISIBLE_LOCATIONS.includes(location.pathname);

    if (routePath !== null && routePath !== location.pathname) {
        return null;
    }

    if (page?.requiredKeys && !isRequiredKeysExists(page?.requiredKeys)) {

        navigate(`${RoutePath.MainGoal}${UTMSearch}`, { replace: true });

        // @description: GA set a new client_id after redirect
        setTimeout(() => {
            setGtagClientId();
        }, 100);
        return null;
    }

    return (
        <>
            {page.header.email ? <EmailHeader backButtonAvailable={backButtonAvailable} /> : null}
            {page.header.logo ? <LogoHeader backButtonAvailable={backButtonAvailable} /> : null}
            {
                <>
                    {!progressBarExperiment && page.header.progress &&
                        <ThinProgressBar progress={page.header.progress} />}
                    {page.customWrapper ? children : (
                        <div className={`${mainContent} ${companyMentionVariationExp && minHeight}`}>
                            {progressBarExperiment && page.header.progress && isNumber(page.header.progress.percents) &&
                                <ProgressBarStep progress={page.header.progress} />}
                            {children}
                        </div>)}
                </>
            }
            {mainFooterVisible && <Footer footerAtTheBottom={page.footerAtTheBottom} />}
            {companyMentionFooterVisible && <CompanyMentionFooter />}
        </>
    );
};
