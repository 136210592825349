import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    text: {
        margin: '32px 3px 12px',
        textAlign: 'left',
    },
    goalTitleClass: {
        margin: '32px 0 6px',
        textTransform: 'uppercase'
    },
    goalTextClass: {
        fontFamily: 'SF Pro Text',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '28px',
        textAlign: 'center',
        letterSpacing: '-0.0024em',
        color: '#010101',
        margin: '6px 0 16px',
    }
});
