import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { ExperimentsKeys } from 'constants/experiments';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { WeightLossChart } from 'components/weight-loss-chart';

import { InfoBlock } from 'pages/paywall/info-block';

import { useStyles } from './styles';

export const WeightLoss = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const {
        graphicInfoWrapper,
        title,
        graphicTitle,
        centered,
        smallerWidth,
        colored,
    } = useStyles();

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const wl_first_screens_agency_experiment = localStorage.getItem(ExperimentsKeys.wl_first_screens_agency_experiment) === '1';
    const endSymbol = wl_first_screens_agency_experiment ? '*' : '.';

    return (
        <>
            <Typography variant={TypographyVariants.h2}
                        className={`${title} ${wl_first_screens_agency_experiment ? centered : ''}`}>
                <Trans i18nKey='weight-loss-title' values={{ endSymbol }} />
            </Typography>

            <div className={`${graphicInfoWrapper} ${wl_first_screens_agency_experiment ? smallerWidth : ''}`}>
                <Typography variant={TypographyVariants.h1}
                            className={`${graphicTitle} ${wl_first_screens_agency_experiment ? centered : ''} ${wl_first_screens_agency_experiment ? colored : ''}`}>
                    {t('weight-loss-image-chart-title')}
                </Typography>
                <WeightLossChart />
            </div>

            <NextButton typographyText={t('weight-loss-button-next')} onClick={handleClick} />

            {wl_first_screens_agency_experiment && <InfoBlock
                imgSrc={'svg/info-block-icons/star.svg'}
                descriptionKey={'weight-loss-info-block-description'}
                bgColor={'#F6F6F6'} />
            }
        </>
    );
};
