import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';

import { ScrollToTop } from 'helpers/scroll-to-top';

import { AppThemeProvider } from 'context/theme-provider';

import { RouterConfig } from 'routes';
import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';
import { GtagKeys } from 'constants/analytics';
import { FUNNEL_VERSION } from 'constants/app-name';

import { PageConfigurationService } from 'services/page-configuration.service';

import { Content } from '../content';

import { useUserGeolocation } from 'hooks/use-user-geolocation.hook';
import { useCoachData } from 'hooks/use-coach-data.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useGtagClientId } from 'hooks/use-gtag-client-id';

export const pageConfiguration = new PageConfigurationService();

const DEFAULT_DATA = {
    'event_label': '<WeightLoss>',
    'event_funnel_version': FUNNEL_VERSION,
    'event_release_version': process.env.REACT_APP_RELEASE_VERSION
};

const growthbook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
    enableDevMode: process.env.REACT_APP_GROWTHBOOK_DEV_MODE === 'true',
    subscribeToChanges: true,
    trackingCallback: (experiment, result) => {
        console.log('[Experiment]', {
            experimentId: experiment.key,
            variationId: result.key
        });

        const params = Object.assign(DEFAULT_DATA, {
            event_category: 'experiment',
            experiment_id: experiment.key,
            variation_id: result.key,
            gb_user_id: localStorage.getItem(GtagKeys.gtagClientId),
        });

        window.gtag('event', 'experiment_viewed', params);
    },
    onFeatureUsage: (featureKey, result) => {
        const params = Object.assign(DEFAULT_DATA, {
            event_category: 'feature',
            feature_key: featureKey,
            result: result,
            gb_user_id: localStorage.getItem(GtagKeys.gtagClientId),
        });

        window.gtag('event', 'feature_viewed', params);

        console.log("[Feature]", { featureKey, result: result.value });
    },
});

function Index() {

    const { gtagClientId } = useGtagClientId();
    const { timezone, countryCode } = useUserGeolocation();

    // @ts-ignore
    const searchParams =(new URL(document?.location))?.searchParams;

    const { pageValue: userGender } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyGender],
        defaultValue: searchParams?.get('gender') || localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]) || ''
    });

    const { pageValue: userAge } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyAge],
        defaultValue: searchParams?.get('age') || searchParams?.get('userage') || localStorage.getItem(LocalStorageKeys[RoutePath.BodyAge]) || ''
    });

    useCoachData(timezone, countryCode, userGender, userAge);

    useEffect(() => {

        growthbook.loadFeatures();

        if (pageConfiguration) {
            pageConfiguration.event('OnboardingFunnelStartViewed');
        }
    }, []);

    useEffect(() => {
        if (gtagClientId && countryCode) {
            growthbook.setAttributes({
                id: gtagClientId,
                user_id: gtagClientId,
                client_id: gtagClientId,
                device_id: navigator?.userAgent,
                country: countryCode,
                device_language: window.navigator.language?.toLowerCase() || window.navigator.userLanguage?.toLowerCase || 'en',
                browser: navigator.userAgent,
                url: window.location.host,
                gender: localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]),
            });
        }
    }, [gtagClientId, countryCode]);

    return (
        <BaseContext.Provider value={{
            countryCode,
            pageConfiguration,
        }}>
            <AppThemeProvider>
                <GrowthBookProvider growthbook={growthbook}>
                    <BrowserRouter>
                        <ScrollToTop />
                        <Content>
                            <RouterConfig />
                        </Content>
                    </BrowserRouter>
                </GrowthBookProvider>
            </AppThemeProvider>
        </BaseContext.Provider>
    );
}

export default Index;
