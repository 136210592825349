import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const PlanFits = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageValue: planFits, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.PlanFits],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);
        saveAnswers({
            key: 'plan-fits',
            itemQuestion: { question: `${t('plan-fits-title')} - ${t('plan-fits-subtitle')}`, value: t(answer.value) },
        });

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    const answers: ChoiceType[] = [
        { key: 'yes', value: t('plan-fits-option-yes') },
        { key: 'no', value: t('plan-fits-option-no') },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('plan-fits-title')}
            </Typography>
            <Typography variant={TypographyVariants.h2}>{t('plan-fits-subtitle')}</Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === planFits}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
