import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    graphicImage: {
        width: '100%',
        maxWidth: '560px',
        height: 'auto',
    },
    graphicSubTitleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '18px',
        width: '100%',
        marginTop: '8px',
        marginBottom: '12px',
    },
    graphicSubTitle: {
        fontSize: '12px',
        color: '#6B6B6B',
        margin: 'unset',
    }
});

