import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    marginTop: {
        marginTop: '16px'
    },
    errorText: {
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'left',
        color: '#FF4D2B',
        marginTop: '16px',
    },
    switcherButton: {
        background: 'none',
        border: 'none',
        float: 'right',
        cursor: 'pointer'
    },
    switcherLink: {
        fontWeight: 600,
        margin: '16px 0 12px',
        color: '#1375D6',
    },
    thanksForSharingText: {
        textAlign: 'left',
        margin: '0',

        '&:first-of-type': {
            color: '#268266',
            fontWeight: 600,
            margin: '16px 0 0',
        }
    },
    excitementText: {
        textAlign: 'left',
        margin: '0 0 12px',

        '&:first-of-type': {
            color: '#268266',
            fontWeight: 600,
            margin: '16px 0 0',
        }
    },
    underweightWarning: {
        '&:first-of-type': {
            color: '#FF4D2B'
        }
    },
    unitSystemSwitcher: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '4px 10px',
        float: 'right',
        borderRadius: '30px',
        backgroundColor: '#C5C5D1',
        position: 'relative',
        width: '73px',
        margin: '20px 0 60px',
        '-webkit-tap-highlight-color': 'transparent',

        '& > p': {
            width: '19px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            lineHeight: '24px',
            color: '#FFFFFF',
            fontWeight: 600,
            cursor: 'pointer',
        },

        '& > span': {
            position: 'absolute',
            height: 'calc(100% + 8px)',
            aspectRatio: '1/1',
            backgroundColor: '#FFFFFF',
            borderRadius: '50%',
            left: 'calc(50% - 4px)',
            top: '-4px',
            border: '1px solid #1375D6',
            color: '#1375D6',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'all 0.3s ease',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            cursor: 'pointer'
        },

        '& > span[data-u="ft"]': {
            left: '-4px'
        },

        '& > span[data-u="lbs"]': {
            left: 0
        }
    }
});
