import { APP_TYPE_APP, APP_TYPE_PWA, APP_TYPE_TRAINERIZE_APP } from 'constants/app-name';

export const getAppType = (pwaNativeSplitVariation: string) => {
    let appType: string;

    if (pwaNativeSplitVariation === '0') {
        appType = APP_TYPE_PWA;
    } else if (pwaNativeSplitVariation === '1') {
        appType = APP_TYPE_APP;
    } else {
        appType = APP_TYPE_TRAINERIZE_APP;
    }

    return appType;
};
