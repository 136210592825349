import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    button: {
        background: 'none',
        border: 'none',
        float: 'right',
    },
    link: {
        fontWeight: 600,
        margin: '6px 0 0px',
        color: '#1375D6',
        cursor: 'pointer'
    },
    btnWrapper: {
        display: 'flex',
        justifyContent: 'right',
    }
});
