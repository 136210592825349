// node modules
import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    icon: {
        display: 'block',
        margin: '32px auto -20px'
    },
    coloredText: {
        color: '#31A783',
        fontWeight: 600
    },
    agreementText: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        margin: '32px 0 12px 0',

        '& a': {
            color: '#1375D6'
        }
    }
});
