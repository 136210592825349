import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { Typography } from 'components/typography';

import { TypographyVariants } from 'constants/typography-variants';
import { EBOOK_GIFT_PRICE } from 'constants/ebook-plan';

import { useStyles } from './styles';

interface IEbookGiftBlock {
    ebookPrice: number;
    currencyLabel: string;
}

export const EbookGiftBlock: FC<IEbookGiftBlock> = ({
                                                        ebookPrice,
                                                        currencyLabel,
                                                    }): JSX.Element => {
    const {
        ebookGiftBlockWrapper,
        priceWrapper,
    } = useStyles();

    const pageImages = useBackgroundImageService(['webp/upgrade-plan/content/ebook-gift-block/biohacks.webp', 'webp/upgrade-plan/content/ebook-gift-block/style-guide.webp']);

    return (
        <div className={ebookGiftBlockWrapper}>
            <div>
                <Typography variant={TypographyVariants.h3}>
                    <Trans i18nKey='upgrade-plan-ebook-gift-block-title' />
                </Typography>
                <div className={priceWrapper}>
                    <Typography variant={TypographyVariants.h3}>
                        {currencyLabel}{EBOOK_GIFT_PRICE}
                    </Typography>
                    <Typography variant={TypographyVariants.h3}>
                        {currencyLabel}{Number.isInteger(ebookPrice) ? ebookPrice : ebookPrice.toFixed(2)}
                    </Typography>
                </div>
            </div>
            <div>
                <img src={pageImages[0]} alt='Biohacks Ebook' />
                <img src={pageImages[1]} alt='Style Guide Ebook' />
            </div>
        </div>
    );
};
