import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';

import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

export const BodyRisk = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyRisk],
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.BodyRisk]}-other-userAnswer`,
        defaultValue: '',
    });

    const wl_first_screens_agency_experiment = useFeatureIsOn(ExperimentsKeys.wl_first_screens_agency_experiment);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.wl_first_screens_agency_experiment, wl_first_screens_agency_experiment ? '1' : '0');
    }, [wl_first_screens_agency_experiment]);

    const multiChoices: MultiChoiceType[] = [
        { key: 'diabetes', value: t('body-risk-option-diabetes') },
        { key: 'hormonal-issues', value: t('body-risk-option-hormonal-issues') },
        { key: 'apnea', value: t('body-risk-option-apnea') },
        { key: 'high-blood-pressure', value: t('body-risk-option-high-blood-pressure') },
        { key: 'heart-disease', value: t('body-risk-option-heart-disease') },
        { key: 'depression-anxiety', value: t('body-risk-option-depression-anxiety') },
        { key: 'high-cholesterol', value: t('body-risk-option-high-cholesterol') },
        { key: 'asthma', value: t('body-risk-option-asthma') },
        { key: 'osteoarthritis', value: t('body-risk-option-osteoarthritis') },
        { key: 'other', value: t('body-risk-option-other'), userAnswer, setUserAnswer },
        { key: 'none', value: t('body-risk-option-none') },
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: LocalStorageKeys[RoutePath.BodyRisk],
            itemQuestion: { question: t('body-risk-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue.includes('other') ? {
            pageValue,
            userAnswer: userAnswer.trim()
        } : { pageValue });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('body-risk-title')}
            </Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
