import React, { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';
import { UsersApiProvider } from 'api/users.api';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { getAppType } from 'helpers/get-app-type';

import { UserGeolocationKeys } from 'constants/user-geolocation';
import { LocalStorageKeys } from 'constants/local-storage';
import { UserVerificationKeys } from 'constants/user-verification';
import { TypographyVariants } from 'constants/typography-variants';
import { LinksKeys } from 'constants/links';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { ValidatedInput } from 'components/validated-input';
import { SignUpApiProvider } from 'api/sign-up.api';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';

export const Email = () => {
    const growthbook = useGrowthBook();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { agreementText, coloredText, icon } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: email, setPageValue: setEmail } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Email],
        defaultValue: '',
    });

    const pwaNativeSplitVariation = useFeatureValue(
        ExperimentsKeys.pwaNativeSplitVariationVersion2,
        '0'
    );

    useEffect(() => {
        localStorage.setItem(
            ExperimentsKeys.pwaNativeSplitVariationVersion2,
            pwaNativeSplitVariation
        );

        growthbook?.setAttributes({
            ...growthbook.getAttributes(),
            user_app: getAppType(pwaNativeSplitVariation),
        });
    }, [pwaNativeSplitVariation]);

    const isEUCountry = localStorage.getItem(UserGeolocationKeys.isEUCountry);
    let hideNewsOffer = false;

    if (!isEUCountry) {
        hideNewsOffer = true;
        localStorage.setItem(LocalStorageKeys[RoutePath.NewsOffer], 'yes');
    }

    const [pageError, setPageError] = useState(false);

    const onChange = (e: any) => {
        setEmail(e.target.value);
    };

    const onError = (error: boolean) => {
        setPageError(error);
    };

    const handleClick = async () => {
        if (pwaNativeSplitVariation !== '2') {
            UsersApiProvider.isUserExists(email).then(({ isUserExists }) => {
                localStorage.setItem(UserVerificationKeys.isUserExists, String(isUserExists));
                pageConfiguration.handleOnPageComplete({ email, hideNewsOffer, isUserExists });
            });
        } else {
            const checkValid = await SignUpApiProvider.verifyEmail({
                email,
            });

            if (
                !checkValid.exists ||
                (checkValid.exists === true && checkValid?.status === 'deactivated')
            ) {
                pageConfiguration.handleOnPageComplete({ email, hideNewsOffer });
            } else {
                navigate(`${RoutePath.Welcome}`);
            }
        }
    };

    const termsEvent = () => pageConfiguration.event('OnboardingStartTermsClicked');
    const privacyEvent = () => pageConfiguration.event('OnboardingStartPrivacyClicked');

    const items = ['svg/email/mountain-icon.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <>
            <img className={icon} src={pageImages[0]} alt="Mountain icon" />

            <Typography variant={TypographyVariants.h1}>
                <Trans
                    i18nKey="email-title"
                    components={[<strong className={coloredText}></strong>]}
                />
            </Typography>

            <ValidatedInput
                value={email}
                onChange={onChange}
                onError={onError}
                validationQuery={{
                    type: {
                        value: 'email',
                        errorMessage: t('email-input-error-message'),
                    },
                }}
                label={t('email-input-label')}
            />

            <Typography variant={TypographyVariants.p} className={agreementText}>
                <Trans i18nKey="email-text">
                    <a
                        href={LinksKeys.TermsAndCondition}
                        target="_blank"
                        rel="noreferrer nofollow"
                        onClick={termsEvent}
                    >
                        Terms of Use
                    </a>
                    <a
                        href={LinksKeys.PrivacyPolicy}
                        target="_blank"
                        rel="noreferrer nofollow"
                        onClick={privacyEvent}
                    >
                        Privacy Policy
                    </a>
                </Trans>
            </Typography>

            <NextButton
                onClick={handleClick}
                typographyText={t('email-button-next')}
                disabled={!email || pageError}
            />
        </>
    );
};
