import { useEffect } from 'react';

import { CoachProvider } from 'api/coach.api';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { CoachKeys } from 'constants/coach';

export const useCoachData = (timezone: string, countryCode: string, userGender: string, userAge?: string) => {

    const { pageValue: coaches, setPageValue: setCoaches } = useLocalStorage({ key: CoachKeys.coaches, defaultValue: [] });

    useEffect(() => {

        if (timezone && countryCode && userGender) {
            CoachProvider.getCoachData(timezone, countryCode, userGender, userAge)
                .then((res) => {
                    setCoaches(res);
                });
        }

    }, [timezone, countryCode, userGender, userAge]);

    return {
        coaches
    }
};
