import React from 'react';
import { useTranslation } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from '../typography';

import { useStyles } from './styles';

type VerticalCardProps = {
    data: {
        text: string;
        image: string;
        description?: string;
    };
    onClick: (value: any) => void;
    isSelected: boolean;
};

export const VerticalCard = ({ data, onClick, isSelected }: VerticalCardProps) => {
    const { t } = useTranslation();

    const { verticalCard, verticalCardSelected } = useStyles();

    const { text, description } = data;

    const pageImages = useBackgroundImageService([data?.image]);

    return (
        <button
            onClick={() => onClick(data)}
            className={`${verticalCard} ${isSelected ? verticalCardSelected : ''}`}
        >
            <div>
                <Typography variant={TypographyVariants.verticalCard}>{t(text)}</Typography>
                {description && (
                    <Typography variant={TypographyVariants.verticalCardDescription}>
                        {t(description)}
                    </Typography>
                )}
            </div>
            {pageImages && <img src={pageImages[0]} alt={t(text)} />}
        </button>
    );
};
