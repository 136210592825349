import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const useImportantDateCalculation = (eventDate: string, monthsPlan: string[], weightPlan: number[], monthsPlanForDisplay: string[]) => {

    // @tip: Same format should be at DatePicker - dateFormat='MMM dd, yyyy' (ImportantEventDate component)
    const FORMAT = 'MMM DD, YYYY';

    const [isFutureDate, setIsFutureDate] = useState(false);
    const [isToday, setIsToday] = useState(false);

    const [eventDateMonthIndex, setEventDateMonthIndex] = useState(0);

    const [weightAtEventDate, setWeightAtEventDate] = useState(0);

    // depends on month length array
    const [level, setLevel] = useState(1); // Identify 6 levels

    // 1 - Future Date

    // 2 - ['Feb', 'Mar'] // lose by Feb

    // 3 - ['Feb', 'Mar', 'Apr']; // lose by Mar

    // 4 - ['Feb', 'Mar', 'Apr', 'May']; // lose by Apr

    // 5 - ['Feb', 'Mar', 'Apr', 'May', 'Jun']; // lose by May

    // 6 - ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']; // lose by Jun
    // 6 - ['Feb', 'Apr', 'Jun', 'Aug', 'Sep', 'Oct']; // lose by Sep
    // 6 - ['Feb', 'May', 'Aug', 'Nov', 'Dec', 'Jan']; // lose by Dec
    // 6 - ['Feb', 'May', 'Aug', 'Nov', 'Dec', 'Jan']; // lose by Dec

    // 0 - it's in the future
    const [position, setPosition] = useState(0);
    const [positionForDisplay, setPositionForDisplay] = useState(0);

    useEffect(() => {
        const eventDateIndex = dayjs(eventDate, FORMAT).month();
        const monthsPlanIndex = monthsPlan.map((month) => dayjs(`${month} 01, 2024`, FORMAT).month());
        const position = monthsPlanIndex.indexOf(eventDateIndex);

        const isNextYear = dayjs(eventDate, FORMAT).isAfter(dayjs(), 'year');
        const isAfter = monthsPlanIndex.every((mIndex) => eventDateIndex > mIndex);
        const isToday = dayjs().format(FORMAT) === eventDate;

        const isFuture = isNextYear || isAfter || (monthsPlanIndex[monthsPlanIndex.length - 1] === eventDateIndex && !isNextYear);

        const monthsPlanForDisplayIndex = monthsPlanForDisplay.map((month) => dayjs(`${month} 01, 2024`, FORMAT).month());
        let positionForDisplay = monthsPlanForDisplayIndex.indexOf(eventDateIndex);
        let level = monthsPlanForDisplayIndex.length;

        if (positionForDisplay === -1) {
            const filledMonthsPlanForDisplayIndex = monthsPlanForDisplayIndex.reduce((filled, mIndex) => {
                if (mIndex !== 0) {
                    // @ts-ignore
                    filled.push(mIndex , mIndex + 1);
                }

                return filled;
            }, []);

            level = filledMonthsPlanForDisplayIndex.length;

            positionForDisplay = filledMonthsPlanForDisplayIndex
                .findIndex((mIndex, index, array) => mIndex <= eventDateIndex && eventDateIndex <= array[index + 1]);
        }

        setLevel(level);
        setPosition(position);
        setPositionForDisplay(positionForDisplay);
        setIsFutureDate(isFuture);
        setIsToday(isToday);
        setEventDateMonthIndex(eventDateIndex);
    }, [ eventDate, monthsPlan, monthsPlanForDisplay ]);

    useEffect(() => {
        if (weightPlan.length) {
            let plan = Math.round(weightPlan[position]);

            if (isFutureDate) {
                plan = weightPlan.at(-1) as number;
            }

            setWeightAtEventDate(plan);
        }
    }, [ weightPlan, eventDateMonthIndex, position, isFutureDate, positionForDisplay ]);

    return {
        level,
        position,
        isFutureDate,
        isToday,
        weightAtEventDate,
        positionForDisplay
    };
};
