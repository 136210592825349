import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const BehaviourChangeSeeMyself = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.BehaviourChangeSeeMyself];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${pageKey}-other-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'believing-in-myself',
            value: t('behaviour-change-see-myself-option-believing-in-myself'),
        },
        {
            key: 'feeling-more-energetic',
            value: t('behaviour-change-see-myself-option-feeling-more-energetic'),
        },
        {
            key: 'living-outside-comfort-zone',
            value: t('behaviour-change-see-myself-option-living-outside-comfort-zone'),
        },
        {
            key: 'having-more-vibrant-social-life',
            value: t('behaviour-change-see-myself-option-having-more-vibrant-social-life'),
        },

        {
            key: 'putting-needs-first',
            value: t('behaviour-change-see-myself-option-putting-needs-first'),
        },
        {
            key: 'other',
            value: t('behaviour-change-see-myself-option-other'),
            userAnswer,
            setUserAnswer
        },
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('behaviour-change-see-myself-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue.includes('other') ? {
            pageValue,
            userAnswer: userAnswer.trim()
        } : { pageValue });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext>
                {t('behaviour-change-see-myself-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2}>{t('behaviour-change-see-myself-subtitle')}</Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
