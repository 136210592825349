// node modules
import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    excitementText: {
        textAlign: 'left',
        margin: '0 0 12px',

        '&:first-of-type': {
            color: '#268266',
            fontWeight: 600,
            margin: '16px 0 0',
        }
    },
    remark: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'left',
        color: '#232323',
        opacity: .5,
        margin: '0'
    },
    colored: {
        fontWeight: 600,
        color: '#EB731A',
    }
});
