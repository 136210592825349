import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const TypicalDay = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.TypicalDay],
        defaultValue: '',
    });

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);
        saveAnswers({
            key: 'typical-day',
            itemQuestion: { question: t('typical-day-title'), value: t(answer.value) },
        });

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answersControl: ChoiceType[] = [
        { key: 'sedentary', value: t('typical-day-option-sedentary') },
        { key: 'lightly_active', value: t('typical-day-option-lightly_active') },
        { key: 'moderately_active', value: t('typical-day-option-moderately_active') },
        { key: 'very_active', value: t('typical-day-option-very_active') }
    ];

    const answersVersion2: ChoiceType[] = [
        { key: 'sedentary', value: t('typical-day-option-version-2-sedentary') },
        { key: 'lightly_active', value: t('typical-day-option-version-2-lightly_active') },
        { key: 'moderately_active', value: t('typical-day-option-version-2-moderately_active') },
        { key: 'very_active', value: t('typical-day-option-version-2-very_active') }
    ];

    const wl_questions_update = localStorage.getItem(ExperimentsKeys.wl_questions_update) === '1';
    const answers = wl_questions_update ? answersVersion2 : answersControl;

    return (
        <>
            <Typography variant={TypographyVariants.h1}>{t('typical-day-title')}</Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
