import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

import { RoutePath } from 'routes/route-path.constant';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { getUnitSystem, UNIT_SYSTEM } from 'constants/body-height-weight';

import { convertToLbsWithoutRounding } from 'helpers/unit-converter';

dayjs.extend(localeData);

dayjs().localeData();

type PropsType = {
    goalWeight: number,
    weight: number;
    height: number;
    unit: 'kg' | 'lb';
}

const maxMonths = 10;
const extraMonths: number = 2;
const maxMonthsOnChart = 5;
const maxWeightsOnChart: number = 6;

export class BodyPlanCalculator {
    weight: number;
    height: number;
    goalWeight: number;
    unit: 'kg' | 'lb';

    constructor({ weight, height, goalWeight, unit }: PropsType) {
        this.weight = weight;
        this.height = height;
        this.goalWeight = goalWeight;
        this.unit = unit;
    }

    getMonthlyWeightLoss = (weight: number, adjusted: boolean): number => {
        const bmi = getBmi(weight, this.height);

        let result;
        if (bmi < 18.5) {
            result = adjusted ? 0.6 : 0.5;
        } else if (bmi < 24.95) {
            result = adjusted ? 2.5 : 2;
        } else if (bmi < 29.95) {
            result = adjusted ? 3.9 : 3.5;
        } else {
            result = adjusted ? 5 : 4.5;
        }

        return this.unit === 'lb' ? convertToLbsWithoutRounding(result) : result;
    };

    getWeightLossData = (earlier: boolean, overrideGoalWeight?: number) => {
        const goalWeight = overrideGoalWeight ? overrideGoalWeight : this.goalWeight;
        const { weights, months, finalDate } = this.calculatePlan(earlier, goalWeight);

        if (!weights.includes(Math.round(goalWeight))) {
            weights.push(Math.round(goalWeight));
        }

        const finalDayCandidate = finalDate.format('D');
        const finalMonthCandidate = finalDate.format('MMM');
        if (!months.includes(finalMonthCandidate)) {
            months.push(finalMonthCandidate);
        }

        const {
            adjustedMonthsPoints,
            adjustedWeightsPoints,
            adjustedGoalWeight,
            finalMonth,
            finalDay,
        } = this.calculateAdjustments(months, finalDate, weights, finalDayCandidate, finalMonthCandidate);

        return { finalDay, finalMonth, weights, months, adjustedWeightsPoints, adjustedMonthsPoints, finalDate, adjustedGoalWeight: Math.round(adjustedGoalWeight) };
    };

    private calculatePlan(earlier: boolean, goalWeight: number) {
        let weights = [];
        let months = [];

        let weight = this.weight;
        let monthlyWeightLoss = this.getMonthlyWeightLoss(weight, earlier);

        months.push(dayjs().format('MMM'));
        weights.push(Math.round(weight));

        let monthsCount = 0;
        while (weight - monthlyWeightLoss >= goalWeight) {
            monthsCount++;
            weight -= monthlyWeightLoss;
            monthlyWeightLoss = this.getMonthlyWeightLoss(weight, earlier);

            const monthLabel = dayjs().add(monthsCount, 'M').format('MMM');
            months.push(monthLabel);
            if (months[months.length - 1] !== monthLabel) {
                months.push(monthLabel);
            }
            if (weights[weights.length - 1] !== Math.round(weight * 10) / 10) {
                weights.push(Math.round(weight * 10) / 10);
            }
        }

        const averageDaysInMonth = 30.437;
        const days = (weight - goalWeight) / monthlyWeightLoss * averageDaysInMonth;

        if (monthsCount > maxMonths) {
            monthsCount = maxMonths;
        }

        const finalDate = dayjs().add(monthsCount, 'M').add(days, 'day');
        return { weights, months, finalDate };
    }

    private calculateAdjustments(months: string[], finalDate: dayjs.Dayjs, weights: number[], finalDay: string, finalMonth: string) {
        // Adjust weight labels for charts
        const adjustedWeightsPoints = this.getShowedWeightsPoints(weights);

        // Adjust goal weight if weight loss takes more than a year
        const {
            lossDate,
            lossMonth,
            lossDay,
            adjustedGoalWeight,
        } = this.getAdjustedGoalWeightAndFinalDate(weights, finalDay, finalMonth, finalDate);

        if (lossDate !== finalDate) {
            finalDate = lossDate;
        }

        if (lossMonth !== finalMonth) {
            finalMonth = lossMonth;
        }

        if (lossDay !== finalDay) {
            finalDay = lossDay;
        }

        // Adjust months labels for charts
        const adjustedMonthsPoints = this.getShowedMonthsPoints(months, finalDate);

        return { adjustedMonthsPoints, adjustedWeightsPoints, adjustedGoalWeight, finalDate, finalMonth, finalDay };
    }

    getAdjustedGoalWeightAndFinalDate = (weightPlan: number[], lossDay: string, lossMonth: string, finalDate: dayjs.Dayjs) => {
        // Check if weight loss takes more than a year, if so adjust goal weight and final date
        const step = 2;
        let adjustedGoalWeight = weightPlan[weightPlan.length - 1];
        let lossDate = finalDate;

        if (weightPlan.length - extraMonths >= maxMonthsOnChart * step) {
            adjustedGoalWeight = weightPlan[maxMonthsOnChart * step];
            lossDate = dayjs().add(maxMonthsOnChart * step, 'M');
            lossMonth = lossDate.format('MMM');
            lossDay = lossDate.format('D');
        }

        return { adjustedGoalWeight, lossMonth, lossDay, lossDate };
    };

    getEarlierDays = (overrideGoalWeight?: number) => {
        const { finalDate } = this.getWeightLossData(false, overrideGoalWeight);
        const { finalDate: earlierFinalDate } = this.getWeightLossData(true, overrideGoalWeight);

        return Math.round(finalDate.diff(earlierFinalDate, 'd', true));
    };

    getShowedMonthsPoints = (points: string[], finalDate: dayjs.Dayjs) => {
        const lastPoint = points[points.length - 1];

        let showedPoints = [...points];

        if (points.length >= 6 && points.length <= 9) {
            showedPoints = points.filter((_: any, i: number) => !(i % 2));
            if (!showedPoints.includes(lastPoint)) {
                showedPoints.push(lastPoint);
            }
        }

        if (points.length === 10) {
            showedPoints = points.filter((_: any, i: number) => !(i % 3));
            if (!showedPoints.includes(lastPoint)) {
                showedPoints.push(lastPoint);
            }
        }

        if (points.length > 10) {
            const slicedPoints = points.slice(0, 11);
            const lastPoint = slicedPoints[slicedPoints.length - 1];
            showedPoints = slicedPoints.filter((_: any, i: number) => !(i % 3));
            if (!showedPoints.includes(lastPoint)) {
                showedPoints.push(lastPoint);
            }
        }

        // Add month after last weight loss month to show popover point correctly
        showedPoints.push(dayjs(finalDate).add(1, 'M').format('MMM'));

        return showedPoints;
    };

    getShowedWeightsPoints = (weights: number[]) => {
        let step = 1;
        let goalWeight = weights[weights.length - 1];

        if (weights.length > maxWeightsOnChart) {
            step = 2;
            // Check if weight loss takes more than a year
            if (weights.length - extraMonths >= maxMonthsOnChart * step) {
                goalWeight = weights[maxMonthsOnChart * step];
            }

            weights = weights.slice(0, 11);
            if (weights.length === 7) {
                weights.splice(1, 1);
            }

            if (weights.length === 8) {
                weights.splice(1, 1);
                weights.splice(3, 1);
            }

            if (weights.length === 9) {
                weights.splice(1, 1);
                weights.splice(3, 1);
                weights.splice(5, 1);
            }

            if (weights.length >= 10) {
                weights = weights.filter((_: any, i: number) => !(i % 2));
            }

            if (!weights.includes(goalWeight)) {
                weights.push(goalWeight);
            }
        }

        return weights
            .map(weight => Math.round(weight))
            .filter((weight, index, array) => array.indexOf(weight) === index);
    };
}

export const getBmi = (weight: number, height: number): number => {
    const unitSystem = getUnitSystem();

    const adjustedHeight = unitSystem === UNIT_SYSTEM.imperial ? height : height / 100;
    return weight / (adjustedHeight * adjustedHeight) * (unitSystem === UNIT_SYSTEM.imperial ? 703 : 1);
};

export const getUserBmi = (): number => {
    const unitSystem = getUnitSystem();
    const bodyHeightWeight = MultipleLocalStorageKeys.bodyHeightWeightKeys;

    const weight = unitSystem === UNIT_SYSTEM.imperial
        ? parseInt(localStorage.getItem(bodyHeightWeight.weightLbs) || '0')
        : parseInt(localStorage.getItem(bodyHeightWeight.weightKg) || '0');

    const height = unitSystem === UNIT_SYSTEM.imperial
        ? parseInt(localStorage.getItem(bodyHeightWeight.heightFt) || '0') * 12 + parseInt(localStorage.getItem(bodyHeightWeight.heightInches) || '0')
        : parseInt(localStorage.getItem(bodyHeightWeight.heightCm) || '0');

    return getBmi(weight, height);
};

export const getWeightDifference = (): number => {
    const unitSystem = getUnitSystem();

    const bodyHeightWeight = MultipleLocalStorageKeys.bodyHeightWeightKeys;

    const weight = unitSystem === UNIT_SYSTEM.imperial ? localStorage.getItem(bodyHeightWeight.weightLbs) : localStorage.getItem(bodyHeightWeight.weightKg);
    const idealWeight = localStorage.getItem(LocalStorageKeys[RoutePath.BodyIdealWeight]) || weight;

    // @ts-ignore
    return +weight - +idealWeight;
};

export const getNextMonth = (month: string) => {
    const monthsShort = dayjs.monthsShort();

    let nextMonthIndex = monthsShort.findIndex(m => m.toLowerCase() === month.toLowerCase()) + 1;

    if (nextMonthIndex > 11) {
        nextMonthIndex = 0;
    }

    return monthsShort[nextMonthIndex];
};

export const getUserAge = (): number => {
    const ageRange = localStorage.getItem(LocalStorageKeys[RoutePath.BodyAge]) as string;

    let age;
    switch (ageRange) {
        case 'underTwenty':
            age = 15;
            break;
        case 'twentys':
            age = 20;
            break;
        case 'fortys':
            age = 40;
            break;
        case 'fiftys':
            age = 50;
            break;
        case 'sixtyPlus':
            age = 60;
            break;
        default:
            age = 30;
    }

    return age;
};
