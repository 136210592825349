import React from 'react';

import { GoalChartLoseWeight } from '../lose-weight';

type WeightChartType = { weightPlan: number[], monthsPlan: string[], goal: string, unit: string, latestMonth?: string, goalWeight: number, desktopSmallViewChart?: boolean, chartWith24PaddingOnTheSides?: boolean, earlierFinalDateChart: boolean }

export const WeightChart = ({
                                weightPlan,
                                monthsPlan,
                                goal,
                                unit,
                                goalWeight,
                                desktopSmallViewChart = false,
                                chartWith24PaddingOnTheSides = false,
                                earlierFinalDateChart
                            }: WeightChartType) => {
    return (
        <>
            {goal === 'lose-weight' && <GoalChartLoseWeight unit={unit} weightPlan={weightPlan} monthsPlan={monthsPlan}
                                                            goalWeight={goalWeight}
                                                            desktopSmallViewChart={desktopSmallViewChart}
                                                            chartWith24PaddingOnTheSides={chartWith24PaddingOnTheSides}
                                                            earlierFinalDateChart={earlierFinalDateChart} />
            }
        </>
    );
};
