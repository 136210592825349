import { useEffect, useState } from 'react';

import { CurrencyProvider } from 'api/currency.api';

import { PLANCODE_PRIMERIO_TYPE } from 'constants/trial-pay';

import { countryToCurrencyData } from 'helpers/country-to-currency-data';

export const useLocalPlans = (planCodes: PLANCODE_PRIMERIO_TYPE[], rounded: string) => {
    const US_KEY = 'US';

    const countryCode = localStorage.getItem('countryCode') || US_KEY;

    const currencyData = countryToCurrencyData(countryCode);

    const [plans, setPlans] = useState<PLANCODE_PRIMERIO_TYPE[]>(planCodes);

    useEffect(() => {

        if (planCodes) {
            const trialAmounts = planCodes.map(p => p.trialAmount / 100);
            const subscriptionAmounts = planCodes.map(p => p.subscriptionAmount / 100);

            CurrencyProvider
                .convert(currencyData.currencyCode, [...trialAmounts, ...subscriptionAmounts], rounded)
                .then((res) => {
                    const newPlans = planCodes.map((plan, index: number) => {
                        const convertedTrialAmount = parseFloat(res[index]);
                        const convertedSubscriptionAmount = parseFloat(res[index + planCodes.length]);

                        const trialAmount = Math.round((convertedTrialAmount + (convertedTrialAmount * currencyData.tax)) * 100);
                        const subscriptionAmount = Math.round((convertedSubscriptionAmount + (convertedSubscriptionAmount * currencyData.tax)) * 100);

                        return {...plan, trialAmount, subscriptionAmount, currencyCode: currencyData.currencyCode, currencyLabel: currencyData.currencyLabel };
                    });

                    setPlans(newPlans);
                }).catch(() => {
                    setPlans(planCodes);
                });
        } else if (countryCode === US_KEY) {
            setPlans(planCodes);
        }
    }, [planCodes, rounded]);

    return {
        plans
    }
};
