import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import { CopyToClipboardButton } from 'pages/welcome/copy-to-clipboard-button';

import { useStyles } from './styles';
import { TrainerizeLinks } from '../app-links/app-links';

interface MobileContentProps {
    isIosDevice: boolean;
    isUserExists: boolean;
    trainerizeApp: boolean;
    email: string;
    password: string;
    handleClick: () => void;
    status: string;
}

const TrainerizePwdLinkComponent = (
    <a href={TrainerizeLinks.trainerizePWDReset} target="_blank" rel="noreferrer nofollow">
        Here
    </a>
);

export const MobileContent: FC<MobileContentProps> = ({
    isIosDevice,
    isUserExists,
    trainerizeApp,
    email,
    password,
    handleClick,
    status,
}) => {
    const { t } = useTranslation();

    const {
        welcomeMobileInfoWrapper,
        title,
        subtitle,
        listWrapper,
        list,
        listItem,
        suggestionIfUserExists,
        info,
    } = useStyles();

    const welcomeTitleKey = isIosDevice ? 'welcome-title' : 'welcome-title-android';
    const ableAppName = isIosDevice ? null : t('welcome-instructions-able-app-name');
    const downloadButtonAppName = isIosDevice
        ? t('welcome-button-next-ios-app-name')
        : t('welcome-instructions-able-app-name');

    let appName;

    if (trainerizeApp) {
        appName = t('welcome-list-text-mobile-download-app-name-trainerize');
    } else if (isIosDevice) {
        appName = t('welcome-list-text-mobile-download-app-ios-name');
    } else {
        appName = t('welcome-instructions-able-app-name');
    }

    return (
        <div className={welcomeMobileInfoWrapper}>
            <Typography
                className={`${isIosDevice && title}`}
                variant={TypographyVariants.h1}
                typographyNext={!isIosDevice}
            >
                {!isUserExists && t(welcomeTitleKey)}
                {isUserExists && t('welcome-title-if-user-exists')}
            </Typography>
            {isIosDevice && (
                <Typography className={subtitle} variant={TypographyVariants.h2}>
                    {t('welcome-subtitle-ios', { appName })}
                </Typography>
            )}

            <div className={listWrapper}>
                <Typography className={listItem} variant={TypographyVariants.h2}>
                    {!isUserExists && t('welcome-instructions-title', { ableAppName })}
                    {isUserExists && t('welcome-subtitle-mobile-if-user-exists')}
                </Typography>
                <ol className={list}>
                    <Typography className={listItem} variant={TypographyVariants.h2}>
                        <li>{t('welcome-list-text-mobile-download-app', { appName })}</li>
                    </Typography>
                    <Typography className={listItem} variant={TypographyVariants.h2}>
                        <li>
                            {!isUserExists && t('welcome-list-text-mobile-open-app', { email })}
                            {isUserExists &&
                                t('welcome-list-text-mobile-open-app-if-user-exists', { email })}
                        </li>
                    </Typography>

                    {!isUserExists && !trainerizeApp && (
                        <Typography className={listItem} variant={TypographyVariants.h2}>
                            <li>{t('welcome-list-text-mobile-create-password')}</li>
                        </Typography>
                    )}

                    {trainerizeApp && password && !status && (
                        <div>
                            <Typography className={listItem} variant={TypographyVariants.h2}>
                                <li>{t('welcome-list-text-mobile-copy-password', { password })}</li>
                            </Typography>
                            <CopyToClipboardButton text={password} />
                        </div>
                    )}

                    {trainerizeApp && status && (
                        <div>
                            <Typography className={listItem} variant={TypographyVariants.h2}>
                                <li>
                                    <Trans
                                        i18nKey="welcome-list-text-mobile-copy-password-link"
                                        components={[TrainerizePwdLinkComponent]}
                                    />
                                </li>
                            </Typography>
                        </div>
                    )}
                </ol>
            </div>

            {isUserExists && (
                <Typography className={suggestionIfUserExists} variant={TypographyVariants.h2}>
                    {t('welcome-text-mobile-suggestion-if-user-exists')}
                </Typography>
            )}

            {isIosDevice && !trainerizeApp && (
                <Typography className={info} variant={TypographyVariants.h3}>
                    {t('welcome-ios-info', { appName })}
                </Typography>
            )}

            <NextButton
                bottomMargin
                onClick={handleClick}
                typographyText={t('welcome-button-next', { downloadButtonAppName })}
            />
        </div>
    );
};
