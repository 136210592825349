import React, { MouseEvent, forwardRef, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ImportantEventDateKeys } from 'constants/important-event-date';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import 'react-datepicker/dist/react-datepicker.css';

import { useStyles } from './styles';

export const ImportantEventDate = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();
    const {
        header,
        buttonStyles,
        nextArrowIcon,
        buttonsWrapper,
        monthDateWrapper,
        monthDateStyles,
        calendarStyles,
        inputWrapper,
        inputStyles,
        inputLabel,
        inputLabelActive,
        inputImage,
        skipNextButton,
    } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const [importantDate, setImportantDate] = useState();

    const items = ['svg/important-date-calendar/next-arrow.svg', 'svg/important-date-calendar/prev-arrow.svg',
        'svg/important-date-calendar/calendar-icon.svg', 'svg/important-date-calendar/cross-icon.svg',
    ];
    const pageImages = useBackgroundImageService(items);

    const importantEventValue = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEvent]);
    const importantEvent = importantEventValue === 'other' ? t('important-event-date-other-option-event-name') : importantEventValue;

    const handleClick = () => {
        const importantEventDateValue = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEventDate]);

        saveAnswers({
            key: LocalStorageKeys[RoutePath.ImportantEventDate],
            itemQuestion: {
                question: t('important-event-date-subtitle', { importantEvent }),
                value: importantEventDateValue as string,
            },
        });

        setImportantDate(importantDate);

        localStorage.setItem(ImportantEventDateKeys.importantEventDateSkipped, 'false');
        pageConfiguration.event('OnboardingImportantEventDateClicked', importantEventDateValue);

        pageConfiguration.handleOnPageComplete();
    };

    const handleSkipButtonClick = () => {
        localStorage.setItem(ImportantEventDateKeys.importantEventDateSkipped, 'true');
        pageConfiguration.event('OnboardingImportantEventDateSkipClicked');
        pageConfiguration.handleOnPageComplete();
    };

    // @ts-ignore
    const CustomInput = forwardRef(({ value, onClick }, ref) => {
        const { pageValue: importantEventDate, setPageValue }: any = useLocalStorage({
            key: LocalStorageKeys[RoutePath.ImportantEventDate],
            defaultValue: value,
        });

        useEffect(() => {
            if (value) {
                setPageValue(value);
            }
        }, []);

        const [activeClass, setActiveClass] = useState<boolean>(false);

        const handleReset = (e: MouseEvent<HTMLImageElement>) => {
            e.stopPropagation();

            setPageValue('');
        };

        const handleLabelClick = () => {
            setActiveClass(true);
        };

        return (
            <>
                <div className={inputWrapper} onClick={() => {
                    onClick();
                    setPageValue(value);
                }}>
                    <label
                        onClick={handleLabelClick}
                        className={activeClass || importantEventDate ? `${inputLabel} ${inputLabelActive}` : inputLabel}>
                        {t('important-event-date-input-label')}
                    </label>
                    <input
                        ref={ref as any}
                        className={inputStyles}
                        value={importantEventDate}
                        readOnly={true}
                    />
                    {!importantEventDate && <img className={inputImage} src={pageImages[2]} alt={'Calendar'} />}
                    {importantEventDate && <img className={inputImage} src={pageImages[3]} onClick={handleReset}
                                                alt={'Reset'} />}
                </div>

                <NextButton onClick={handleClick} disabled={!importantEventDate} />
                <NextButton className={skipNextButton} onClick={handleSkipButtonClick}
                            typographyText={t('important-event-date-button-next')} />
            </>
        );
    });

    return (
        <>
            <Typography variant={TypographyVariants.h1}
                        typographyNext>{t('important-event-date-title')}</Typography>
            <Typography variant={TypographyVariants.h2}>
                {t('important-event-date-subtitle', { importantEvent })}
            </Typography>

            <DatePicker
                calendarClassName={calendarStyles}
                renderCustomHeader={({
                                         monthDate,
                                         decreaseMonth,
                                         increaseMonth,
                                         increaseYear,
                                         prevMonthButtonDisabled,
                                         nextMonthButtonDisabled,
                                     }) => (
                    <div className={header}>
                        <div className={monthDateWrapper}>
                            <Typography className={monthDateStyles} variant={TypographyVariants.h1}>
                                {monthDate.toLocaleString('en-US', {
                                    month: 'short',
                                    year: 'numeric',
                                })}
                            </Typography>
                            <button className={buttonStyles} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                                <img className={nextArrowIcon} src={pageImages[0]} alt={'Next'} />
                            </button>
                        </div>

                        <div className={buttonsWrapper}>
                            <button className={buttonStyles} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                <img src={pageImages[1]} alt={'Prev'} />
                            </button>
                            <button className={buttonStyles} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                <img src={pageImages[0]} alt={'Next'} />
                            </button>
                        </div>
                    </div>
                )}
                selected={importantDate}
                onChange={(date: any) => setImportantDate(date)}
                dateFormat='MMM dd, yyyy'
                useWeekdaysShort
                withPortal
                customInput={<CustomInput />}
                minDate={new Date()} />
        </>
    );
};
