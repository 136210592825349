import { createUseStyles } from 'react-jss'

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ tipPage }: ThemeProps) => ({
    tipPageWrapper: {
        ...tipPage.tipPageWrapper,
        '& img': {
            ...tipPage.tipPageIcon,
        }
    },
    childrenWrapper: {
        maxWidth: '560px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '80%',
    }
}));

export const useExperimentStyles = createUseStyles({
    tipPageWrapperExp: {},
    '@media (min-width: 768px)': {
        tipPageWrapperExp: {

            '& > div > div': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            },

            '& img': {
                width: '142px',
                height: '142px'
            },

            '& p': {
                textAlign: 'center'
            },

            '& button': {
                maxWidth: '560px'
            }
       }
    }
});
