import React from 'react';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';

export const WithoutWorkoutTip = () => {

    let description = 'without-workout-tip-text';

    if (localStorage.getItem(LocalStorageKeys[RoutePath.WithoutWorkout]) === 'yes') {
        description = localStorage.getItem(LocalStorageKeys[RoutePath.BodyAge]) === 'sixtyPlus'
            ? 'without-workout-tip-great-sixtyPlus-text'
            : 'without-workout-tip-great-text';
    }

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/ladder-with-star.svg'
            text={{
                description: description,
                next: 'without-workout-tip-button-next'
            }}
        />
    );
};
