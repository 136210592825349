import { SlackWorkflowAPI } from '@ruby-labs/ui-core-kit';
import { pageConfiguration } from 'containers/index';
import { PlanForUpdate } from 'constants/upgrade-plan';
import { UpgradeSubscriptionPlanRequest, ValidateEmailRequest } from 'constants/cf-requests';
import { SUBSCRIPTION_ID } from 'constants/payment';

type UpgradeSubscriptionData = {
    customerId: string;
    subscriptionId: string;
    plan: PlanForUpdate;
    amount: number;
    trialPeriodDays: number;
    paymentType: string;
    subscriptionType: string;
};

export type UpgradeCustomerSubscriptionApiResponse = {
    customerId: string;
    subscriptionId: string;
};

export type CfApiResponse = {
    status: number;
    message: string;
};

export type ValidateEmailRequestResponse = {
    exists: boolean;
    email: string;
    id?: string;
    status?: string;
};
class SignUpApi extends SlackWorkflowAPI {
    constructor() {
        super({
            webHookUrl: process.env.REACT_APP_SLACK_WEBHOOK_PAYMENTS_MONITORING_URL as string,
        });
    }

    upgradeSubscription(
        data: UpgradeSubscriptionData
    ): Promise<UpgradeCustomerSubscriptionApiResponse> {
        const abortController = new AbortController();
        const abortSignal = abortController.signal;

        const timeoutId = setTimeout(() => {
            abortController.abort();
        }, 30000);

        return fetch(`${process.env.REACT_APP_ABLE_API}/subscription/update`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            signal: abortSignal,
        })
            .then((response) => {
                clearTimeout(timeoutId);

                if (response.ok) {
                    return response.json();
                }

                return response.json().then((json) => Promise.reject(json));
            })
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                if (e.name === 'AbortError') {
                    pageConfiguration.event('PlanUpgradeSubscriptionAbortRequest');
                }

                this.sendMessageToWebhook({
                    url: window.location.host,
                    method: `[${data.paymentType}] [upgradeSubscription]`,
                    error: JSON.stringify(e),
                });
                console.log('[subscription] [update]', e, {
                    subscriptionId: data.subscriptionId,
                    plan: data.plan,
                });
                return Promise.reject(e);
            });
    }

    upgradeSubscriptionCf(data: UpgradeSubscriptionPlanRequest): Promise<CfApiResponse> {
        const id = localStorage.getItem(SUBSCRIPTION_ID);

        return fetch(`${process.env.REACT_APP_TRAINERIZE_API_URL}/v1/subscriptions/${id}/upgrade`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return response.json().then((json) => Promise.reject(json));
            })
            .then((response) => {
                return response;
            })
            .catch((e) => {
                this.sendMessageToWebhook({
                    url: window.location.host,
                    method: `[Subscription] [upgradeSubscriptionCf]`,
                    error: JSON.stringify(e),
                });
                console.log('[Subscription] [upgradeSubscriptionCf]', e, { id });
                return Promise.reject(e);
            });
    }

    verifyEmail(data: ValidateEmailRequest): Promise<ValidateEmailRequestResponse> {
        return fetch(`${process.env.REACT_APP_TRAINERIZE_API_URL}/v1/validate`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return response.json().then((json) => Promise.reject(json));
            })
            .then((response) => {
                return response;
            });
    }

    charge({
        customerId,
        invoiceItemPriceId,
        price,
        paymentType,
        currency,
    }: {
        customerId: string;
        invoiceItemPriceId: string;
        price: number;
        paymentType: string;
        currency: string;
    }): Promise<{ invoiceId: string }> {
        return fetch(`${process.env.REACT_APP_ABLE_API}/payment/charge`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ customerId, invoiceItemPriceId, price, paymentType, currency }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return response.json().then((json) => Promise.reject(json));
            })
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                this.sendMessageToWebhook({
                    url: window.location.host,
                    method: `[${paymentType}] [charge]`,
                    error: JSON.stringify(e),
                });
                console.log('[charge]', e, { customerId, invoiceItemPriceId, price });
                return Promise.reject(e);
            });
    }

    chargeCf({
        userId,
        price,
        currencyCode,
    }: {
        userId: string;
        price: number;
        currencyCode: string;
    }): Promise<CfApiResponse> {
        return fetch(`${process.env.REACT_APP_TRAINERIZE_API_URL}/v1/one-time-payments`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, price, currencyCode }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return response.json().then((json) => Promise.reject(json));
            })
            .then((response) => {
                return response;
            })
            .catch((e) => {
                this.sendMessageToWebhook({
                    url: window.location.host,
                    method: `[chargeCf]`,
                    error: JSON.stringify(e),
                });
                console.log('[charge]', e, { userId, price, currencyCode });
                return Promise.reject(e);
            });
    }
}

export const SignUpApiProvider = new SignUpApi();
