import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBodyPlanData } from 'hooks/use-body-plan-data.hook';

import { getUnitSystem, UNIT_SYSTEM } from 'constants/body-height-weight';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { useStyles } from './styles';

export const PersonalizedProgram = () => {
    const { t } = useTranslation();
    const { animationPageWrapper, textWrapper, animatedText, first, second, third } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const unit = getUnitSystem() === UNIT_SYSTEM.imperial ? t('lb') : t('kg');
    const { adjustedGoalWeight } = useBodyPlanData();

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const timeouts = [
            2500,
            4000,
            3000
        ]
        let counter = 1;
        let timeoutId: NodeJS.Timeout;

        const timeout = (duration: number) => {
            timeoutId = setTimeout(function() {
                setIndex(counter);
                if (++counter === text.length + 1) {
                    handleClick();
                    clearTimeout(timeoutId);
                } else {
                    timeout(timeouts[counter-1]);
                }
            }, duration);
        }

        timeout(timeouts[counter-1]);

        return () => clearTimeout(timeoutId);
    }, []);

    const textWeightProps = useMemo(
        () => ({ adjustedGoalWeight, unit }),
        [adjustedGoalWeight, unit]
    );

    const text = [
        <Typography
            className={`${animatedText} ${first}`}
            variant={TypographyVariants.h1}
            typographyNext
        >
            <Trans i18nKey="personalized-program-text-based" />
        </Typography>,
        <Typography
            onAnimationStart={() => pageConfiguration.event('OnboardingFinisher2Viewed')}
            className={`${animatedText} ${second}`}
            variant={TypographyVariants.h1}
            typographyNext
        >
            <Trans i18nKey="personalized-program-text-personalized" />
        </Typography>,
        <Typography
            onAnimationStart={() => pageConfiguration.event('OnboardingFinisher3Viewed')}
            className={`${animatedText} ${third}`}
            variant={TypographyVariants.h1}
        >
            <Trans i18nKey="personalized-program-text-weight" values={textWeightProps} />
        </Typography>,
    ];

    return (
        <div className={animationPageWrapper}>
            <div className={textWrapper}>{text[index]}</div>
        </div>
    );
};
