import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { UNIT_SYSTEM } from 'constants/body-height-weight';
import { MultipleLocalStorageKeys } from 'constants/local-storage';
import { UserGeolocationKeys } from 'constants/user-geolocation';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { BodyHeightWeightInputs } from 'components/body-height-weight-inputs';

const convertDataForIntakeForm = (data: { unit: string, weight: string }) => {
    return `Weight: ${data.weight} ${data.unit === UNIT_SYSTEM.metric ? 'kg' : 'lb'}`;
};

export const BodyWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;
    const countryCode = localStorage.getItem(UserGeolocationKeys.countryCode) as string;

    const [pageError, setPageError] = useState(false);
    const [underweightError, setUnderweightError] = useState(false);

    const [disabledButtonState, setDisabledButtonState] = useState(true);
    const [errors, setError] = useState({});

    const handleClick = () => {
        if (disabledButtonState) {
            return;
        }

        const data = {
            unit: bodyHeightWeightUnitSystem,
            weight:
                bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? bodyWeightLbs : bodyWeightKg,
        };

        saveAnswers({
            key: 'body-weight',
            itemQuestion: { question: t('body-weight-title'), value: convertDataForIntakeForm(data) },
        });
        pageConfiguration.handleOnPageComplete(data);
    };

    const { pageValue: bodyHeightWeightUnitSystem } =
        useLocalStorage({
            key: bodyHeightWeightKeys.unitSystem,
            defaultValue: countryCode === 'US' || countryCode === 'CA' ? UNIT_SYSTEM.imperial : UNIT_SYSTEM.metric,
        });

    const { pageValue: bodyWeightLbs } = useLocalStorage({
        key: bodyHeightWeightKeys.weightLbs,
        defaultValue: '',
    });

    const { pageValue: bodyWeightKg } = useLocalStorage({
        key: bodyHeightWeightKeys.weightKg,
        defaultValue: '',
    });

    useEffect(() => {

        setPageError(() => {
            // @ts-ignore
            return Object.keys(errors).some((key) => errors[key]);
        });

    }, [JSON.stringify(errors)]);

    useEffect(() => {
        const btnState = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial
            ? pageError || !bodyWeightLbs || underweightError
            : pageError || !bodyWeightKg || underweightError;

        setDisabledButtonState(btnState);

    }, [
        bodyHeightWeightUnitSystem,
        bodyWeightLbs,
        bodyWeightKg,
        pageError,
        underweightError
    ]);

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>{t('body-weight-title')}</Typography>

            <Typography variant={TypographyVariants.h2}>{t('body-weight-subtitle')}</Typography>

            <BodyHeightWeightInputs heightInput={false}  idealWeightInput={false} setInputError={setError} underweightError={underweightError} setUnderweightError={setUnderweightError} pageError={pageError} />

            <NextButton onClick={handleClick} disabled={disabledButtonState} />
        </>
    );
};
