import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const AtIdealWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: atIdealWeight, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.AtIdealWeight],
        defaultValue: '',
    });

    const handleClick = (goal: ChoiceType) => {
        saveAnswers({
            key: 'at-ideal-weight',
            itemQuestion: { question: t('at-ideal-weight-title'), value: t(goal.value) },
        });
        setPageValue(goal.key);

        pageConfiguration.handleOnPageComplete(goal.key);
    };

    const genders: ChoiceType[] = [
        { key: 'one-to-six-month', value: t('at-ideal-weight-option-one-to-six-month') },
        { key: 'six-to-twelve-month', value: t('at-ideal-weight-option-six-to-twelve-month') },
        { key: 'one-to-two-year', value: t('at-ideal-weight-option-one-to-two-year') },
        { key: 'two-plus-year', value: t('at-ideal-weight-option-two-plus-year') },
        { key: 'right-now', value: t('at-ideal-weight-option-right-now') },
        { key: 'never-was', value: t('at-ideal-weight-option-never-was') },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('at-ideal-weight-title')}
            </Typography>

            {genders.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === atIdealWeight}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
