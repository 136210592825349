import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    progressWrapper: {
        width: '97%',
        margin: 'auto',
        '$.RSPBprogressBar': {
            backgroundColor: '#E0E3EB'
        }
    },
    labelStyles: {
        fontSize: '14px',
        lineHeight: '14px',
        color: '#EB731A',
        margin: '12px auto 17px',

        fontWeight: 600,
    },
    indexedStep: {
        color: '#202B47',
        width: '16px',
        height: '16px',
        fontSize: '12px',
        backgroundColor: '#E0E3EB',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'SF Pro Text',
        fontWeight: 600,
        lineHeight: '6px',
    },
    accomplishedStep: {
        color: 'black',
        backgroundColor: '#EB731A'
    },
    firstItem: {
        backgroundColor: '#FEA54D'
    },
    darkBar: {
        position: 'relative',
        marginTop: '-6px',

        '& div:first-child': {
            background: 'transparent!important'
        },

        '& div:last-child': {
            transition: 'none'
        }
    }
});
