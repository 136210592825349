class Currency {

    convert(to: string, amount: number[], rounded: string): Promise<string[]> {

        let url = `${process.env.REACT_APP_ABLE_API}/currency/convert?to=${to}&amount=${amount.join(';')}&rounded=${rounded}`;

        return fetch(url, {
            method: 'GET'
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response
                .json()
                .then(json => Promise.reject(json));
        }).then((response) => {
            console.log(response.data);
            return response.data?.result;
        }).catch((e) => {
            console.log('[convert request]', e);
            return Promise.reject(e);
        });
    }
}

export const CurrencyProvider = new Currency();
