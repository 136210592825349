import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useExperimentStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    noHeightButton: {
        ...nextButton.nextButton,
        height: '100%',
        background: 'none',
        color: '#EB731A',
        padding: 0,
        margin: '0 0 20px'
    }
}));
