import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { useStyles } from './styles';

export const YouAreAble = () => {
    const { animationPageWrapper, textWrapper, animatedText, first, second, third, dot } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    return (
            <div className={animationPageWrapper}>
                <div className={textWrapper}>
                    <Typography onAnimationStart={() => pageConfiguration.event('OnboardingFinisher5Viewed')} className={`${animatedText} ${first}`} variant={TypographyVariants.h1}>
                        <Trans i18nKey='you-are-able-title-you' />
                    </Typography>
                    <Typography onAnimationStart={() => pageConfiguration.event('OnboardingFinisher6Viewed')} className={`${animatedText} ${second}`} variant={TypographyVariants.h1}>
                        <Trans i18nKey='you-are-able-title-are' />
                    </Typography>
                    <Typography onAnimationEnd={() => handleClick()} className={`${animatedText} ${third}`} variant={TypographyVariants.h1}>
                        <Trans i18nKey='you-are-able-title-able' components={[<span className={dot}></span>]} />
                    </Typography>
                </div>
            </div>
    )
};
