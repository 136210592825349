import React from 'react';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';

export const FoodRestrictionsTip = () => {
    const description = (JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.FoodRestrictions]) as string)[0]) === 'all'
        ? 'food-restrictions-tip-eat-everything-title'
        : 'food-restrictions-tip-all-title';

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/food-tray.svg'
            text={{
                description: description,
                next: 'food-restrictions-tip-button-next'
            }}
        />
    );
};
