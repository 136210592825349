export const VibrantLifeLinks = {
    appStore: 'https://apps.apple.com/us/app/vibrantlife-connect/id6451144527',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.able.user',
    deepLink: 'https://install.ableapp.com/gdEx',
};

export const TrainerizeLinks = {
    appStore: 'https://apps.apple.com/us/app/fitness-app-abc-trainerize/id516851502',
    googlePlay:
        'https://play.google.com/store/apps/details?id=com.trainerize.Trainerize&hl=en&gl=US',
    deepLink: 'https://install.ableapp.com/rwAk',
    trainerizePWDReset: 'https://ableapp.trainerize.com/app/getPassword.aspx',
};
