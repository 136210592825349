import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ animatedPage }: ThemeProps) => ({
    pageWrapper: {
        ...animatedPage.animationPageWrapper,
        height: '100vh',
        justifyContent: 'space-between',
        gap: '40px',
        padding: '24px',
    },
    pageContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '560px'
    },
    pageTitle: {
        fontSize: '20px',
        lineHeight: '28px'
    },
    loaderWrapper: {
        width: '100%',
        display: 'flex',
        gap: '40px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    modalWindow: {
        padding: '24px',
        margin: '0 32px'
    },
    '@media screen and (min-width: 390px) and (max-height: 844px)': {
        pageWrapper: {
            position: 'relative'
        }
    },
    '@media screen and (max-width: 375px)': {
        pageWrapper: {
            position: 'relative',
            height: '100%'
        }
    },
    '@media screen and (min-width: 768px)': {
        pageWrapper: {
            height: '100%',
        }
    }
}));
