import { FC } from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { Title } from 'pages/helped-thousands/content/title';

import { useStyles } from './styles';

interface ContentProps {
    userGender: string;
    userAge: string;
}

export const Content: FC<ContentProps> = ({ userGender, userAge }): JSX.Element => {
    const {
        customerReviewWrapper,
        customerReview,
        jeansImg,
        userAvatar,
        name,
        reviewText
    } = useStyles();

    const items = ['webp/testimonials/testimonial-image.webp', 'webp/testimonials/jeans.webp'];
    const pageImages = useBackgroundImageService(items);

    return (
        <>
            <Title userGender={userGender} userAge={userAge} />

            <div className={customerReviewWrapper}>
                <img
                    src={pageImages[0]}
                    className={userAvatar}
                    height={64}
                    width={64}
                    alt='nicole'
                />
                <div className={customerReview}>
                    <Typography variant={TypographyVariants.h3} className={reviewText}>
                        <Trans i18nKey='helped-thousands-customer-review' />
                    </Typography>
                    <Typography variant={TypographyVariants.h3} className={name}>
                        <Trans i18nKey='helped-thousands-customer-name' />
                    </Typography>
                </div>
            </div>

            <img src={pageImages[1]} className={jeansImg} alt="jeans" />

            <br />
        </>
    );
};
