import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const HowMotivated = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.HowMotivated],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);
        saveAnswers({
            key: 'how-motivated',
            itemQuestion: { question: t('how-motivated-title'), value: t(answer.value) },
        });

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        {
            key: 'ready',
            value: t('how-motivated-option-ready'),
        },
        {
            key: 'feeling-hopeful',
            value: t('how-motivated-option-feeling-hopeful'),
        },
        {
            key: 'need-motivation',
            value: t('how-motivated-option-need-motivation'),
        },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>{t('how-motivated-title')}</Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
