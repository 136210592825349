import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../button';

import { useStyles } from './styles';

export const NextButton = ({children, typographyText, ...props}: any) => {
    const { t } = useTranslation();

    const { nextButton } = useStyles();

    typographyText = typographyText ? typographyText : t('next-button-default-text');

    return (
        <Button className={nextButton} typographyText={typographyText} {...props}>{children}</Button>
    );
}
