import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    block: {
        border: '2px solid #D1DCF8',
        borderRadius: '8px',
    },
    textBlock: {
        margin: '17px 16px 0',
    },
    title: {
        display: 'inline',
        lineHeight: '22px',
        margin: 'unset'
    },
    bookImg: {
        width: '24px',
        margin: '0px 4px -4px',
    },
    starImg: {
        marginRight: '8px',
        width: '18px',
        height: '18px',
    },
    secondTitle: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#1375D6',
        display: 'flex',
        alignItems: 'flex-start',
        textAlign: 'left',
        margin: '17px 0 8px',
    },
    marginTop: {
        marginTop: '16px',
    },
    list: {
        listStyle: 'disc',
        marginLeft: '42px',
    },
    listItem: {
        fontSize: '16px',
        textAlign: 'left',
        margin: '8px auto 8px',
    },
    priceBlock: {
        height: '51px',
        backgroundColor: '#D1DCF8',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: '16px',
    },
    priceBlockText: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        '& span': {
            textDecoration: 'line-through',
        }
    },
    '@media screen and (min-width: 768px)': {
        title: {
            fontSize: '22px',
            lineHeight: '28px',
            margin: '20px auto 20px',
        },
        bookImg: {
            width: '32px',
        },
        secondTitle: {
            fontSize: '20px',
            lineHeight: '24px',
        },
        marginTop: {
            marginTop: '20px',
        },
        list: {
            marginLeft: '55px',
        },
        listItem: {
            fontSize: '20px',
            lineHeight: '24px'
        },
        priceBlock: {
            marginTop: '24px',
        },
        priceBlockText: {
            fontSize: '20px',
            lineHeight: '28px',
        },
    },
    '@media screen and (max-width: 320px)': {
        title: {
            display: 'block',
            textAlign: 'left',
            '& img': {
                verticalAlign: 'bottom'
            }
        }
    }
}));
