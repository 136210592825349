import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ animatedPage }: ThemeProps) => ({
    pageWrapper: {
        ...animatedPage.animationPageWrapper,
        justifyContent: 'flex-start',
    },
    pageContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '560px',

        '& ul': {
            listStyle: 'disc',

            '& li': {
                marginLeft: '1em',
                marginBottom: '24px',

                '&:last-child': {
                    marginBottom: '28px',
                }
            }
        }
    },
    image: {
        marginTop: '66px'
    },
    title: {
        fontSize: '22px',
        lineHeight: '32px',
        margin: '32px 0 24px'
    },
    description: {
        fontSize: '20px',
        lineHeight: '28px',
        textAlign: 'left',
        margin: 0,
    },
    '@media screen and (min-width: 768px)': {
        pageContent: {

            '& ul': {

                '& li': {
                    marginBottom: '16px',

                    '&:last-child': {
                        marginBottom: '60px',
                    }
                }
            }
        },
        image: {
            marginTop: '80px'
        },
        title: {
            fontSize: '28px',
            lineHeight: '33.41px',
            margin: '40px 0 24px'
        }
    },
}));
