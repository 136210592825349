import { Palette } from '../index';

export type VerticalCardType = {
    verticalCard: {
        display: string,
        flexDirection: string,
        alignItems: string,
        height: string,
        maxHeight: string,
        minHeight: string,
        padding: string,
        cursor: string,
        border: string,
        borderRadius: string,
        background: Palette | string,
        color: Palette | string,
    },
    verticalCardSelected: {
        background: Palette | string,
        color: Palette | string,
    },
}

export const VerticalCardStyles: VerticalCardType = {
    verticalCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'auto',
        maxHeight: '280px',
        minHeight: '236px',
        padding: '20px',
        cursor: 'pointer',
        border: 'none',
        borderRadius: '8px',
        background: '#E8F5FD',
        color: '#202B47',
    },
    verticalCardSelected: {
        background: '#1375D6',
        color: '#F6F6F6',
    },
};
