import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import book from 'assets/images/svg/upgrade/book.svg';
import star from 'assets/images/svg/upgrade/star.svg';

import { useStyles } from './styles';

interface PriceBlockProps {
    currencyLabel: string;
    price: string | number;
    discountPrice: string | number;
    discountPriceApplied: boolean;
}

export const ToolkitDescription: FC<PriceBlockProps> = ({ currencyLabel, price, discountPrice, discountPriceApplied }) => {
    const { t } = useTranslation();

    const {
        block,
        textBlock,
        title,
        bookImg,
        starImg,
        secondTitle,
        marginTop,
        list,
        listItem,
        priceBlock,
        priceBlockText
    } = useStyles();

    return (
        <div className={block}>
            <div className={textBlock}>
                <Typography variant={TypographyVariants.h2} className={title}>
                    {t('upgrade-toolkit-description-title')}
                    <img src={book} className={bookImg} alt='Book' />:
                </Typography>

                <Typography variant={TypographyVariants.h3} className={secondTitle}>
                    <img src={star} className={starImg} alt='Star' />
                    {t('upgrade-toolkit-description-first-subtitle')}
                </Typography>

                <ul className={list}>
                    <li>
                        <Typography variant={TypographyVariants.h3} className={listItem}>
                            {t('upgrade-toolkit-description-item-1')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.h3} className={listItem}>
                            {t('upgrade-toolkit-description-item-2')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.h3} className={listItem}>
                            {t('upgrade-toolkit-description-item-3')}
                        </Typography>
                    </li>
                </ul>

                <Typography variant={TypographyVariants.h3} className={`${secondTitle} ${marginTop}`}>
                    <img src={star} className={starImg} alt='Star' />
                    {t('upgrade-toolkit-description-second-subtitle')}
                </Typography>

                <ul className={list}>
                    <li>
                        <Typography variant={TypographyVariants.h3} className={listItem}>
                            {t('upgrade-toolkit-description-item-4')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.h3} className={listItem}>
                            {t('upgrade-toolkit-description-item-5')}
                        </Typography>
                    </li>
                </ul>
            </div>

            <div className={priceBlock}>
                {discountPriceApplied &&
                    <Typography variant={TypographyVariants.h3} className={priceBlockText}>
                        <Trans i18nKey='upgrade-toolkit-discount-price' components={[<span></span>]} values={{ currencyLabel, price, discountPrice }} />
                    </Typography>
                }

                {!discountPriceApplied &&
                    <Typography variant={TypographyVariants.h3} className={priceBlockText}>
                        {t('upgrade-toolkit-original-price', { currencyLabel, price })}
                    </Typography>
                }
            </div>
        </div>
    );
};
