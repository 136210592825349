import React from 'react';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';
import { useTranslation } from 'react-i18next';

export const SpecificDietTip = () => {
    const { t } = useTranslation();
    const userDiet = localStorage.getItem(LocalStorageKeys[RoutePath.SpecificDiet]) as string;

    const getDescriptionTextKey = () => {
        let description;
        if (['not'].includes(localStorage.getItem(LocalStorageKeys[RoutePath.SpecificDiet]) as string)) {
            description = 'specific-diet-tip-not-description';
        } else if (['other'].includes(localStorage.getItem(LocalStorageKeys[RoutePath.SpecificDiet]) as string)) {
            description = 'specific-diet-tip-other-description';
        } else {
            description = 'specific-diet-tip-all-description';
        }

        return description;
    };

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/apple.svg'
            text={{
                description: getDescriptionTextKey(),
                values: userDiet === 'atkins' ? t('specific-diet-option-atkins') : userDiet.split('-')[0],
                next: 'specific-diet-tip-button-next',
            }}
        />
    );
};
