import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { NextButton } from 'components/next-button';
import { Typography } from 'components/typography';

import logo2 from 'assets/images/svg/logo-black.svg';

import { useStyles } from './styles';


export const PersonalityQuiz = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageWrapper, pageContent, image, title, description } = useStyles();

    const pageImages = useBackgroundImageService(['svg/personality-quiz/histogram.svg']);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    return (
        <div className={pageWrapper}>
            <div className={pageContent}>
                <img src={logo2} alt='Able Logo' />

                <img className={image} src={pageImages[0]} alt='Histogram' />

                <Typography variant={TypographyVariants.h1} className={title}>
                    <Trans i18nKey='personality-quiz-title' />
                </Typography>

                <ul>
                    <li>
                        <Typography variant={TypographyVariants.h2} className={description}>
                            <Trans i18nKey='personality-quiz-description-point-1' />
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.h2} className={description}>
                            <Trans i18nKey='personality-quiz-description-point-2' />
                        </Typography>
                    </li>
                </ul>
            </div>

            <NextButton typographyText={t('personality-quiz-button-next')} onClick={handleClick} />
        </div>
    );
};
