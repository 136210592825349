export enum CHARGE_PERIOD_UNIT {
    day = 'day',
    month = 'month'
}

export type PLANCODE_PRIMERIO_TYPE = {
    title: string,
    key: string,
    trialAmount: number,
    trialPeriod: number,
    subscriptionAmount: number,
    subscriptionPeriod: number
    chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT,
    currencyCode: string,
    currencyLabel: string,
};

// [project]_[version]_[trial price]_[trial period]_[subscription price]_[subscription period]_[subscription charge period unit (day | month)]_[currency code]
export const PLANCODE_PRIMERIO: PLANCODE_PRIMERIO_TYPE[] = [
    {
        title: 'Able - Weight Loss',
        key: 'loseWeight_v3_50_7_3500_7_day_USD',
        trialAmount: 50,
        trialPeriod: 7,
        subscriptionAmount: 3500, // Hardcoded
        subscriptionPeriod: 7, /* chargePeriod - numbers of days, answer for - How often user needs to be billed (X Days) */
        chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT.day, /* period (X Days) */
        currencyCode: 'USD',
        currencyLabel: '$'
    },
    {
        title: 'Able - Weight Loss',
        key: 'loseWeight_v3_300_7_3500_7_day_USD',
        trialAmount: 300,
        trialPeriod: 7,
        subscriptionAmount: 3500, // Hardcoded
        subscriptionPeriod: 7, /* chargePeriod - numbers of days, answer for - How often user needs to be billed (X Days) */
        chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT.day, /* period (X Days) */
        currencyCode: 'USD',
        currencyLabel: '$'
    },
    {
        title: 'Able - Weight Loss',
        key: 'loseWeight_v3_1000_7_3500_7_day_USD',
        trialAmount: 1000,
        trialPeriod: 7,
        subscriptionAmount: 3500, // Hardcoded
        subscriptionPeriod: 7, /* chargePeriod - numbers of days, answer for - How often user needs to be billed (X Days) */
        chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT.day, /* period (X Days) */
        currencyCode: 'USD',
        currencyLabel: '$'
    },
    {
        title: 'Able - Weight Loss',
        key: 'loseWeight_v3_1837_7_3500_7_day_USD',
        trialAmount: 1837,
        trialPeriod: 7,
        subscriptionAmount: 3500, // Hardcoded
        subscriptionPeriod: 7, /* chargePeriod - numbers of days, answer for - How often user needs to be billed (X Days) */
        chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT.day, /* period (X Days) */
        currencyCode: 'USD',
        currencyLabel: '$'
    },
];
