import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const BehaviourChangeHappyWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.BehaviourChangeHappyWeight];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${pageKey}-other-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'eat-mindfully',
            value: t('behaviour-change-happy-weight-option-eat-mindfully'),
        },
        {
            key: 'enjoy-all-foods',
            value: t('behaviour-change-happy-weight-option-enjoy-all-foods'),
        },
        {
            key: 'feel-empowered',
            value: t('behaviour-change-happy-weight-option-feel-empowered'),
        },
        {
            key: 'think-less-about-food',
            value: t('behaviour-change-happy-weight-option-think-less-about-food'),
        },
        {
            key: 'worry-less-about-body',
            value: t('behaviour-change-happy-weight-option-worry-less-about-body'),
        },
        {
            key: 'other',
            value: t('behaviour-change-happy-weight-option-other'),
            userAnswer,
            setUserAnswer
        },
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('behaviour-change-happy-weight-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue.includes('other') ? {
            pageValue,
            userAnswer: userAnswer.trim()
        } : { pageValue });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext>
                {t('behaviour-change-happy-weight-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2}>{t('behaviour-change-happy-weight-subtitle')}</Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
