import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useLoadingProgress } from 'hooks/use-loading-progress.hook';

import { AGES } from 'constants/body-age';
import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Content } from 'pages/helped-thousands/content';

import { Typography } from 'components/typography';
import { ThinProgressBar } from 'components/progress-bar-thin';

import { useStyles } from './styles';

export const HelpedThousands = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const { text2, bar } = useStyles();

    const userGender =
        localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]) === 'female' ? t('women').toLowerCase() : t('men').toLowerCase();
    const bodyAge = localStorage.getItem(LocalStorageKeys[RoutePath.BodyAge]);
    const userAge = AGES.find((age) => age.key === bodyAge)?.value as string;

    const handleClick = useCallback(() => {
        pageConfiguration.handleOnPageComplete();
    }, []);

    const { progress } = useLoadingProgress({
        onDone: handleClick,
    });

    return (
        <>
            <Content userAge={userAge} userGender={userGender} />

            <Typography variant={TypographyVariants.p} className={text2}>
                {t('helped-thousands-title-analyzing')}
            </Typography>

            <ThinProgressBar progress={progress} className={bar} />
        </>
    );
};
