import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ animatedPage }: ThemeProps) => ({
    pageWrapper: {
        ...animatedPage.animationPageWrapper,
        justifyContent: 'space-between',
    },
    pageContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flexBasis: '100%',
        width: '100%',
        maxWidth: '560px',
    },
    lampImage: {},
    animatedTitle: {
        fontSize: '22px',
        lineHeight: '32px',
        margin: '48px 0',
        animation: '$animation 3s linear forwards 1s',
        opacity: 0
    },
    '@media (min-width: 768px)': {
        pageContent: {
            justifyContent: 'flex-start'
        },
        lampImage: {
            marginTop: '80px'
        },
        animatedTitle: {
            fontSize: '28px',
            lineHeight: '32px',
            margin: '40px 0',
        },
    },
    '@keyframes animation': {
        '0%, 100%': {
            opacity: 0
        },
        '50%': {
            opacity: 1
        }
    }
}));
