import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { RoutePath } from 'routes/route-path.constant';

import { LocalStorageKeys } from 'constants/local-storage';

import { useImportantDateCalculation } from 'hooks/use-important-date-calc.hook';
import { useBodyPlanData } from 'hooks/use-body-plan-data.hook';

import rightArrow from 'assets/images/svg/important-event-popover/right-arrow.svg';

import { useStyles } from './styles';

export const ImportantEventPopover = ({
                                          isSmallChartView,
                                          chartWith24PaddingOnTheSides,
                                          earlierFinalDateChart
                                      }: { isSmallChartView?: boolean, chartWith24PaddingOnTheSides?: boolean, earlierFinalDateChart: boolean }) => {
    const { t } = useTranslation();

    const {
        popoverWrapper,
        dotSymbol,
        text,
        futureText,
        popoverInSmallChartView,
        popoverOnPageWith24PaddingOnTheSidesView,

        l_p_2_0,
        l_p_3_0, l_p_3_1,
        l_p_4_1, l_p_4_2,
        l_p_5_1, l_p_5_2, l_p_5_3,
        l_p_6_1, l_p_6_2, l_p_6_3, l_p_6_4,
        l_p_12_1, l_p_12_3, l_p_12_5,
    } = useStyles();

    const eventValue = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEvent]);
    const event = eventValue === 'other' ? t('important-event-date-other-option-event-name') : eventValue;
    const eventDate = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEventDate]) as string;

    let { unit, weight, earlierFinalDate, finalDate, weightsData, monthsData, monthsPlan, earlierMonthsPlan, earlierInitialMonthsData, earlierInitialWeightsData } = useBodyPlanData();
    const monthsPlanForDisplay = earlierFinalDateChart ? earlierMonthsPlan : monthsPlan;
    const monthsInitialData = earlierFinalDateChart ? earlierInitialMonthsData : monthsData;
    const weightsInitialData = earlierFinalDateChart ? earlierInitialWeightsData : weightsData;

    const {
        level,
        isFutureDate,
        weightAtEventDate,
        isToday,
        positionForDisplay
    } = useImportantDateCalculation(eventDate, monthsInitialData, weightsInitialData, monthsPlanForDisplay);

    const [sectionClass, setSectionClass] = useState('');

    useEffect(() => {

        if (level === 2) {
            setSectionClass(l_p_2_0);
        }

        if (level === 3) {
            setSectionClass(positionForDisplay === 0 ? l_p_3_0 : l_p_3_1);
        }

        if (level === 4) {
            setSectionClass([l_p_3_0, l_p_4_1, l_p_4_2, l_p_4_2][positionForDisplay]);
        }

        if (level === 5) {
            setSectionClass([l_p_3_0, l_p_5_1, l_p_5_2, l_p_5_3][positionForDisplay]);
        }

        if (level === 6) {
            setSectionClass([l_p_3_0, l_p_6_1, l_p_6_2, l_p_6_3, l_p_6_4][positionForDisplay]);
        }

        if (level === 8) {
            setSectionClass([l_p_3_0, l_p_12_1, l_p_4_1, l_p_6_2, l_p_4_2, l_p_4_2][positionForDisplay]);
        }

        if (level === 10) {
            setSectionClass([l_p_3_0, l_p_12_1, l_p_5_1, l_p_12_3, l_p_5_2, l_p_12_5][positionForDisplay]);
        }

        if (level === 12) {
            setSectionClass([l_p_3_0, l_p_12_1, l_p_6_1, l_p_12_3, l_p_6_2, l_p_12_5][positionForDisplay]);
        }

    }, [level, positionForDisplay]);

    const currentFinalDate = earlierFinalDateChart ? earlierFinalDate : finalDate;
    const isEventInFuture = isFutureDate && (dayjs(new Date(eventDate))).isAfter(currentFinalDate);
    const weightToSubtract = unit === 'kg' ? 0.5 : 1;
    const adjustedWeight = weightAtEventDate - weightToSubtract;

    const getWeightToShow = () => {
        let weightToShow;
        if (isToday) {
            weightToShow = weight;
        } else if (weightAtEventDate === weight) {
            weightToShow = adjustedWeight;
        } else {
            weightToShow = weightAtEventDate;
        }

        return weightToShow;
    };

    return (
        <>
            <div
                className={`${popoverWrapper} ${sectionClass} ${(isSmallChartView && !isEventInFuture) && popoverInSmallChartView} ${isEventInFuture && futureText} ${(chartWith24PaddingOnTheSides && !isEventInFuture) && popoverOnPageWith24PaddingOnTheSidesView}`}>
                {!isEventInFuture && <span className={dotSymbol}></span>}
                <span className={text}>{event} ({getWeightToShow()}&nbsp;{unit}) {isEventInFuture &&
                    <img src={rightArrow} alt='Arrow' />}</span>
            </div>
        </>
    );
};
