export const getParameterizedCoachImage = (url: string): string => {

    if (!url) {
        return '';
    }

    const parameters = {
        width: 284,
        height: 284,
        quality: 100,
        format: 'jpeg',
    };

    const parameterList: string[] = [];

    for (const parameter in parameters) {
        // @ts-ignore
        parameterList.push(`${parameter}=${parameters[parameter]}`)
    }

    const parameterString = parameterList.join(',');
    const toBeInserted = `cdn-cgi/image/${parameterString}`;
    const splitUrl = url.split('/');
    splitUrl.splice(3, 0, toBeInserted);

    return splitUrl.join('/');
};
