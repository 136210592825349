import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { NextButton } from 'components/next-button';
import { Typography } from 'components/typography';

import { useStyles } from './styles';

export const WeightMetabolicRate = () => {
    const { t } = useTranslation();

    const { title, subtitle, graphicImage } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const items = ['svg/weight-metabolic-rate/weight-metabolic-rate-graphic.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <>
            <Typography variant={TypographyVariants.h1} className={title} typographyNext>
                <Trans i18nKey="weight-metabolic-rate-title" />
            </Typography>

            <Typography variant={TypographyVariants.h2} className={subtitle}>
                <Trans i18nKey="weight-metabolic-rate-subtitle" />
            </Typography>

            <img src={pageImages[0]} className={graphicImage} alt="Weight Metabolic Rate" />

            <NextButton
                typographyText={t('weight-metabolic-rate-button-text')}
                onClick={handleClick}
            />
        </>
    );
};
