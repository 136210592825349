import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { Typography } from 'components/typography';

import { TypographyVariants } from 'constants/typography-variants';

import logo2 from 'assets/images/svg/logo-black.svg';

import { useStyles } from './styles';


export const PersonalityQuizLoading = () => {
    const { pageConfiguration } = useContext(BaseContext);

    const { pageWrapper, pageContent, lampImage, animatedTitle } = useStyles();

    const pageImages = useBackgroundImageService(['svg/personality-quiz/lamp.svg']);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    return (
        <div className={pageWrapper}>
            <img src={logo2} alt='Able Logo' />

            <div className={pageContent}>
                <img className={lampImage} src={pageImages[0]} alt='Lamp' />

                <Typography onAnimationEnd={() => handleClick()} variant={TypographyVariants.h1} className={animatedTitle}>
                    <Trans i18nKey='personality-quiz-loading-title' />
                </Typography>
            </div>
        </div>
    );
};
