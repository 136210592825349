import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(() => ({
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 'none',
        minWidth: '61px',
        height: '48px',
        padding: '4px 8px',
        borderRadius: '8px',
        border: '2px solid #EB731A',
        background: 'none',
        color: '#EB731A',
        '-webkit-tap-highlight-color': 'transparent',

        '& > span': {
            fontWeight: 500
        }
    },
    copied: {
        width: '100%',
        flexDirection: 'row-reverse',
        gap: '8px',
        border: '2px solid #36B992',
        color: '#36B992',
        cursor: 'none',
        pointerEvents: 'none'
    },
    '@media (min-width: 768px)': {
        button: {
            minWidth: '80px',
            height: '34px',
        }
    }
}));
