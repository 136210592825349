import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const TargetZone = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.TargetZone],
        defaultValue: [],
    });

    let multiChoices: MultiChoiceType[] = [
        { key: 'all', value: t('target-zone-option-men-all') },
        { key: 'abs', value: t('target-zone-option-men-abs') },
        { key: 'arms', value: t('target-zone-option-men-arms') },
        { key: 'chest', value: t('target-zone-option-men-chest') },
        { key: 'shoulders', value: t('target-zone-option-men-shoulders') },
        { key: 'back', value: t('target-zone-option-men-back') },
        { key: 'legs', value: t('target-zone-option-men-legs') },
    ];

    if (localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]) === 'female') {
        multiChoices = [
            { key: 'all', value: t('target-zone-option-woman-all') },
            { key: 'belly', value: t('target-zone-option-woman-belly') },
            { key: 'butt', value: t('target-zone-option-woman-butt') },
            { key: 'breasts', value: t('target-zone-option-woman-breasts') },
            { key: 'legs', value: t('target-zone-option-woman-legs') },
        ];
    }

    const handleClick = () => {
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.key === item);

            return currentOption?.value;
        });
        saveAnswers({
            key: 'target-zone',
            itemQuestion: { question: t('target-zone-title'), value: selectOption },
        });
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('target-zone-title')}
            </Typography>
            <Typography variant={TypographyVariants.h2}>{t('target-zone-subtitle')}</Typography>
            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
