import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    modalWindowLoading: {
        zIndex: 103,
        backdropFilter: 'blur(2px)',
        '& > div': {
            padding: '24px',
        }
    },
});
