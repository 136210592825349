import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { RoutePath } from './route-path.constant';

import { MainGoal } from 'pages/main-goal';
import { BodyAge } from 'pages/body-age';
import { BodyGender } from 'pages/body-gender';
import { BodyType } from 'pages/body-type';
import { BodyAgeTip } from 'pages/body-age-tip';
import { WeightLoss } from 'pages/weight-loss';
import { BodyBreastfeeding } from 'pages/body-breastfeeding';
import { BodyRisk } from 'pages/body-risk';
import { BodyRiskTip } from 'pages/body-risk-tip';
import { GlassesWaterTip } from 'pages/glasses-water-tip';
import { SpecificDiet } from 'pages/specific-diet';
import { SpecificDietTip } from 'pages/specific-diet-tip';
import { WithoutWorkoutTip } from 'pages/without-workout-tip';
import { SleepHoursTip } from 'pages/sleep-hours-tip';
import { DietActivity } from 'pages/diet-activity';
import { LoseWeight } from 'pages/lose-weight';
import { WeightGain } from 'pages/weight-gain';
import { PastTrials } from 'pages/past-trials';
import { ConsumedItems } from 'pages/consumed-items';
import { TypicalDay } from 'pages/typical-day';
import { AchieveGoal } from 'pages/achieve-goal';
import { NewsOffer } from 'pages/news-offer';
import { HealthyFridge } from 'pages/healthy-fridge';
import { EatTimes } from 'pages/eat-times';
import { PlanFits } from 'pages/plan-fits';
import { AverageBusy } from 'pages/average-busy';
import { WorkoutTimes } from 'pages/workout-times';
import { WithoutWorkout } from 'pages/without-workout';
import { GlassesWater } from 'pages/glasses-water';
import { SleepHours } from 'pages/sleep-hours';
import { HowMotivated } from 'pages/how-motivated';
import { WellnessGoal } from 'pages/wellness-goal';
import { GoalChart } from 'pages/goal-chart';
import { FeelFailureRating } from 'pages/feel-failure-rating';
import { OldHabitsRating } from 'pages/old-habits-rating';
import { BodyIdealWeight } from 'pages/body-ideal-weight';
import { Email } from 'pages/email';
import { HelpedThousands } from 'pages/helped-thousands';
import { Congrats } from 'pages/congrats';
import { TrialPay } from 'pages/trial-pay';
import { Welcome } from 'pages/welcome';
import { FoodRestrictions } from 'pages/food-restrictions';
import { FoodRestrictionsTip } from 'pages/food-restrictions-tip';
import { IntermittentFasting } from 'pages/intermittent-fasting';
import { IntermittentFastingTip } from 'pages/intermittent-fasting-tip';
import { AverageBusyTip } from 'pages/average-busy-tip';
import { AtIdealWeight } from 'pages/at-ideal-weight';
import { WalkDaily } from 'pages/walk-daily';
import { TargetZone } from 'pages/target-zone';
import { FitnessLevel } from 'pages/fitness-level';
import { AverageWorkoutTimes } from 'pages/average-workout-times';
import { PhysicalLimitations } from 'pages/physical-limitations';
import { PhysicalLimitationsTip } from 'pages/physical-limitations-tip';
import { WorkoutType } from 'pages/workout-type';
import { WorkoutPlace } from 'pages/workout-place';
import { WorkoutPlaceTip } from 'pages/workout-place-tip';
import { HitGoalChartTip } from 'pages/hit-goal-chart-tip';
import { PastTrialsTip } from 'pages/past-trials-tip';
import { FitClothesRating } from 'pages/fit-clothes-rating';
import { LookSatisfactionRating } from 'pages/look-satisfaction-rating';
import { PeopleRelationshipRating } from 'pages/people-relationship-rating';
import { MentalHealthRating } from 'pages/mental-health-rating';
import { PublicPlacesRating } from 'pages/public-places-rating';
import { EnjoyLifeRating } from 'pages/enjoy-life-rating';
import { CareerOpportunitiesRating } from 'pages/career-opportunities';
import { PlanLoader } from 'pages/plan-loader';
import { PersonalizedProgram } from 'pages/personalized-program';
import { YouAreAble } from 'pages/you-are-able';

import { ImportantEvent } from 'pages/important-event';
import { ImportantEventDate } from 'pages/important-event-date';

import { BodyHeight } from 'pages/body-height';
import { BodyWeight } from 'pages/body-weight';

import { UpgradePlan } from 'pages/upgrade-plan';

import { Upgrade } from 'pages/upgrade';
import { UpgradeTip } from 'pages/upgrade-tip';

import { MainGoalPageComponent } from 'pages/main-goal-page-component';
import { ScientificProof } from 'pages/scientific-proof';
import { GoalWithProgressBar } from 'pages/goal-with-progress-bar';
import { NotFound } from 'pages/not-found';
import { BloodType } from 'pages/blood-type';
import { BloodTypeTip } from 'pages/blood-type-tip';
import { BehaviourChangeSelf } from 'pages/behaviour-change-self';
import { BehaviourChangeHappyWeight } from 'pages/behaviour-change-happy-weight';
import { BehaviourChangeSeeMyself } from 'pages/behaviour-change-see-myself';
import { BehaviourChangeReachWeight } from 'pages/behaviour-change-reach-weight';
import { BehaviourChangeImportance } from 'pages/behaviour-change-importance';
import { BehaviourChangeHitGoal } from 'pages/behaviour-change-hit-goal';
import { BehaviourChangeTip } from 'pages/behaviour-change-tip';
import { PersonalityQuiz } from 'pages/personality-quiz';
import { PersonalityQuizEnjoy } from 'pages/personality-quiz-enjoy';
import { PersonalityQuizFocus } from 'pages/personality-quiz-focus';
import { PersonalityQuizMakeDecisions } from 'pages/personality-quiz-make-decisions';
import { PersonalityQuizPrefer } from 'pages/personality-quiz-prefer';
import { PersonalityQuizLoading } from 'pages/personality-quiz-loading';
import { PersonalityQuizResults } from 'pages/personality-quiz-results';
import { WeightMetabolicRate } from 'pages/weight-metabolic-rate';
// import { AbleProgram } from 'pages/able-program';

const ReactLazyPreload = (importStatement: () => Promise<any>) => {
        const Component = lazy(importStatement);

        // @ts-ignore
        Component.preload = importStatement;

        return Component;
};

const HitGoalChart = ReactLazyPreload(() => import('pages/hit-goal-chart'));
const HitGoalChartImportantEvent = ReactLazyPreload(() => import('pages/hit-goal-chart'));
const Paywall = ReactLazyPreload(() => import('pages/paywall'));

export const RouterConfig = () => {
    return (
        <Routes>
            {/* List all public routes here */}
            <Route path={RoutePath.Goal} element={<MainGoalPageComponent />} />
            <Route path={RoutePath.GoalWithProgressBar} element={<GoalWithProgressBar />} />
            <Route path={RoutePath.MainGoal} element={<MainGoal />} />
            <Route path={RoutePath.BodyGender} element={<BodyGender />} />
            <Route path={RoutePath.BodyBreastfeeding} element={<BodyBreastfeeding />} />
            <Route path={RoutePath.BodyAge} element={<BodyAge />} />
            <Route path={RoutePath.BodyAgeTip} element={<BodyAgeTip />} />
            <Route path={RoutePath.BodyRisk} element={<BodyRisk />} />
            <Route path={RoutePath.BodyRiskTip} element={<BodyRiskTip />} />
            <Route path={RoutePath.BodyHeight} element={<BodyHeight />} />
            <Route path={RoutePath.BodyWeight} element={<BodyWeight />} />
            <Route path={RoutePath.WeightMetabolicRate} element={<WeightMetabolicRate />} />
            <Route path={RoutePath.BodyIdealWeight} element={<BodyIdealWeight />} />
            <Route path={RoutePath.WeightLoss} element={<WeightLoss />} />
            <Route path={RoutePath.BodyType} element={<BodyType />} />
            <Route path={RoutePath.BloodType} element={<BloodType />} />
            <Route path={RoutePath.BloodTypeTip} element={<BloodTypeTip />} />
            <Route path={RoutePath.LoseWeight} element={<LoseWeight />} />
            <Route path={RoutePath.AverageBusy} element={<AverageBusy />} />
            <Route path={RoutePath.AverageBusyTip} element={<AverageBusyTip />} />
            <Route path={RoutePath.SleepHours} element={<SleepHours />} />
            <Route path={RoutePath.SleepHoursTip} element={<SleepHoursTip />} />
            <Route path={RoutePath.AtIdealWeight} element={<AtIdealWeight />} />
            <Route path={RoutePath.DietActivity} element={<DietActivity />} />
            <Route path={RoutePath.AchieveGoal} element={<AchieveGoal />} />
            <Route path={RoutePath.HelpedThousands} element={<HelpedThousands />} />
            <Route path={RoutePath.Email} element={<Email />} />
            <Route path={RoutePath.NewsOffer} element={<NewsOffer />} />
            <Route path={RoutePath.GoalChart} element={<GoalChart />} />
            <Route path={RoutePath.HealthyFridge} element={<HealthyFridge />} />
            <Route path={RoutePath.GlassesWater} element={<GlassesWater />} />
            <Route path={RoutePath.GlassesWaterTip} element={<GlassesWaterTip />} />
            <Route path={RoutePath.ConsumedItems} element={<ConsumedItems />} />
            <Route path={RoutePath.EatTimes} element={<EatTimes />} />
            <Route path={RoutePath.SpecificDiet} element={<SpecificDiet />} />
            <Route path={RoutePath.SpecificDietTip} element={<SpecificDietTip />} />
            <Route path={RoutePath.FoodRestrictions} element={<FoodRestrictions />} />
            <Route path={RoutePath.FoodRestrictionsTip} element={<FoodRestrictionsTip />} />
            <Route path={RoutePath.IntermittentFasting} element={<IntermittentFasting />} />
            <Route path={RoutePath.IntermittentFastingTip} element={<IntermittentFastingTip />} />
            <Route path={RoutePath.TypicalDay} element={<TypicalDay />} />
            <Route path={RoutePath.WalkDaily} element={<WalkDaily />} />
            <Route path={RoutePath.WithoutWorkout} element={<WithoutWorkout />} />
            <Route path={RoutePath.WithoutWorkoutTip} element={<WithoutWorkoutTip />} />
            <Route path={RoutePath.WellnessGoal} element={<WellnessGoal />} />
            <Route path={RoutePath.TargetZone} element={<TargetZone />} />
            <Route path={RoutePath.FitnessLevel} element={<FitnessLevel />} />
            <Route path={RoutePath.WorkoutTimes} element={<WorkoutTimes />} />
            <Route path={RoutePath.AverageWorkoutTimes} element={<AverageWorkoutTimes />} />
            <Route path={RoutePath.PhysicalLimitations} element={<PhysicalLimitations />} />
            <Route path={RoutePath.PhysicalLimitationsTip} element={<PhysicalLimitationsTip />} />
            <Route path={RoutePath.WorkoutType} element={<WorkoutType />} />
            <Route path={RoutePath.WorkoutPlace} element={<WorkoutPlace />} />
            <Route path={RoutePath.WorkoutPlaceTip} element={<WorkoutPlaceTip />} />
            <Route path={RoutePath.HowMotivated} element={<HowMotivated />} />
            <Route path={RoutePath.HitGoalChart} element={<HitGoalChart />} />
            <Route path={RoutePath.HitGoalChartTip} element={<HitGoalChartTip />} />
            <Route path={RoutePath.WeightGain} element={<WeightGain />} />
            <Route path={RoutePath.PastTrials} element={<PastTrials />} />
            <Route path={RoutePath.PastTrialsTip} element={<PastTrialsTip />} />
            <Route path={RoutePath.BehaviourChangeSelf} element={<BehaviourChangeSelf />} />
            <Route path={RoutePath.BehaviourChangeHappyWeight} element={<BehaviourChangeHappyWeight />} />
            <Route path={RoutePath.BehaviourChangeSeeMyself} element={<BehaviourChangeSeeMyself />} />
            <Route path={RoutePath.BehaviourChangeReachWeight} element={<BehaviourChangeReachWeight />} />
            <Route path={RoutePath.BehaviourChangeImportance} element={<BehaviourChangeImportance />} />
            <Route path={RoutePath.BehaviourChangeHitGoal} element={<BehaviourChangeHitGoal />} />
            <Route path={RoutePath.BehaviourChangeTip} element={<BehaviourChangeTip />} />
            <Route path={RoutePath.FitClothesRating} element={<FitClothesRating />} />
            <Route path={RoutePath.LookSatisfactionRating} element={<LookSatisfactionRating />} />
            <Route path={RoutePath.PeopleRelationshipRating} element={<PeopleRelationshipRating />} />
            <Route path={RoutePath.MentalHealthRating} element={<MentalHealthRating />} />
            <Route path={RoutePath.PublicPlacesRating} element={<PublicPlacesRating />} />
            <Route path={RoutePath.EnjoyLifeRating} element={<EnjoyLifeRating />} />
            <Route path={RoutePath.CareerOpportunitiesRating} element={<CareerOpportunitiesRating />} />
            <Route path={RoutePath.FeelFailureRating} element={<FeelFailureRating />} />
            <Route path={RoutePath.OldHabitsRating} element={<OldHabitsRating />} />
            <Route path={RoutePath.PlanFits} element={<PlanFits />} />
            <Route path={RoutePath.ScientificProof} element={<ScientificProof />} />
            <Route path={RoutePath.PlanLoader} element={<PlanLoader />} />
            <Route path={RoutePath.PersonalizedProgram} element={<PersonalizedProgram />} />
            <Route path={RoutePath.YouAreAble} element={<YouAreAble />} />
            <Route path={RoutePath.TrialPay} element={<TrialPay />} />
            <Route path={RoutePath.Paywall} element={<Paywall />} />
            <Route path={RoutePath.UpgradePlan} element={<UpgradePlan />} />
            <Route path={RoutePath.Congrats} element={<Congrats />} />
            <Route path={RoutePath.Welcome} element={<Welcome />} />

            <Route path={RoutePath.ImportantEvent} element={<ImportantEvent />} />
            <Route path={RoutePath.ImportantEventDate} element={<ImportantEventDate />} />
            <Route path={RoutePath.HitGoalChartImportantEvent} element={<HitGoalChartImportantEvent />} />

            <Route path={RoutePath.Upgrade} element={<Upgrade />} />
            <Route path={RoutePath.UpgradeTip} element={<UpgradeTip />} />

            <Route path={RoutePath.PersonalityQuiz} element={<PersonalityQuiz />} />
            <Route path={RoutePath.PersonalityQuizEnjoy} element={<PersonalityQuizEnjoy />} />
            <Route path={RoutePath.PersonalityQuizFocus} element={<PersonalityQuizFocus />} />
            <Route path={RoutePath.PersonalityQuizMakeDecisions} element={<PersonalityQuizMakeDecisions />} />
            <Route path={RoutePath.PersonalityQuizPrefer} element={<PersonalityQuizPrefer />} />
            <Route path={RoutePath.PersonalityQuizLoading} element={<PersonalityQuizLoading />} />
            <Route path={RoutePath.PersonalityQuizResults} element={<PersonalityQuizResults />} />

            {/*<Route path={RoutePath.AbleProgram} element={<AbleProgram />} />*/}

            {/* List a generic 404-Not Found route here */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
