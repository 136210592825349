import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';
import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

export const SpecificDiet = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.SpecificDiet],
        defaultValue: '',
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.SpecificDiet]}-other-userAnswer`,
        defaultValue: '',
    });

    const answersControl: ChoiceType[] = [
        { key: 'not', value: t('specific-diet-option-not') },
        { key: 'keto', value: t('specific-diet-option-keto') },
        { key: 'vegetarian', value: t('specific-diet-option-vegetarian') },
        { key: 'vegan', value: t('specific-diet-option-vegan') },
        { key: 'paleo', value: t('specific-diet-option-paleo') },
        { key: 'atkins', value: t('specific-diet-option-atkins') },
        { key: 'dash', value: t('specific-diet-option-dash') },
        { key: 'other', value: t('specific-diet-option-other'), userAnswer, setUserAnswer },
    ];

    const answersVersion2: ChoiceType[] = [
        { key: 'not', value: t('specific-diet-option-version-2-not') },
        { key: 'vegan', value: t('specific-diet-option-vegan') },
        { key: 'vegetarian-eggs', value: t('specific-diet-option-version-2-vegetarian-eggs') },
        { key: 'vegetarian-dairy', value: t('specific-diet-option-version-2-vegetarian-dairy') },
        { key: 'vegetarian-eggs-and-dairy', value: t('specific-diet-option-version-2-vegetarian-eggs-and-dairy') },
        { key: 'pescatarian', value: t('specific-diet-option-version-2-pescatarian') },
        { key: 'paleo', value: t('specific-diet-option-paleo') },
    ];

    const wl_questions_update = localStorage.getItem(ExperimentsKeys.wl_questions_update) === '1';
    const answers = wl_questions_update ? answersVersion2 : answersControl;

    // Set otherOptionAnswer initial value to avoid using null
    const initialOtherOptionAnswerValue = answers.find(answer => answer.key === 'other') as ChoiceType;
    const [otherOptionAnswer, setOtherOptionAnswer] = useState<ChoiceType>(initialOtherOptionAnswerValue);

    const handleButtonClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        if (answer.key !== 'other') {
            handleNextButtonClick(answer);
        } else {
            setOtherOptionAnswer(answer);
            setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
        }
    };

    const handleNextButtonClick = (answer: ChoiceType) => {
        const userSelectedAnswerValue = answer.value;
        const showUserAnswer = answer.key === 'other' && userAnswer;
        const trimmedUserAnswer = userAnswer.trim();
        const intakeFormValue = showUserAnswer ? `${userSelectedAnswerValue}: ${trimmedUserAnswer}` : userSelectedAnswerValue;
        saveAnswers({
            key: LocalStorageKeys[RoutePath.SpecificDiet],
            itemQuestion: { question: t('specific-diet-title'), value: intakeFormValue },
        });

        pageConfiguration.handleOnPageComplete(showUserAnswer ?
            { userSelectedAnswerValue, trimmedUserAnswer }
            :
            { userSelectedAnswerValue }
        );
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('specific-diet-title')}
            </Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    item={v}
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={v.value}
                    onClick={() => handleButtonClick(v)}
                    pageValue={pageValue}
                />
            ))}
            {pageValue === 'other' &&
                <FloatedButton floated={pageValue.length} withGradient>
                    <NextButton onClick={() => handleNextButtonClick(otherOptionAnswer)} disabled={false} />
                </FloatedButton>
            }
        </>
    );
};
