import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const BehaviourChangeHitGoal = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.BehaviourChangeHitGoal];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${pageKey}-other-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'no-success-in-past',
            value: t('behaviour-change-hit-goal-option-no-success-in-past'),
        },
        {
            key: 'restrictive-previous-plans',
            value: t('behaviour-change-hit-goal-option-restrictive-previous-plans'),
        },
        {
            key: 'hitting-plateau',
            value: t('behaviour-change-hit-goal-option-hitting-plateau'),
        },
        {
            key: 'losing-and-gaining-weight-back',
            value: t('behaviour-change-hit-goal-option-losing-and-gaining-weight-back'),
        },
        {
            key: 'losing-motivation',
            value: t('behaviour-change-hit-goal-option-losing-motivation'),
        },
        {
            key: 'not-realistic-plan',
            value: t('behaviour-change-hit-goal-option-not-realistic-plan'),
        },
        {
            key: 'not-aggressive-plan',
            value: t('behaviour-change-hit-goal-option-not-aggressive-plan'),
        },
        {
            key: 'other',
            value: t('behaviour-change-hit-goal-option-other'),
            userAnswer,
            setUserAnswer
        },
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('behaviour-change-hit-goal-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue.includes('other') ? {
            pageValue,
            userAnswer: userAnswer.trim()
        } : { pageValue });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext>
                {t('behaviour-change-hit-goal-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2}>{t('behaviour-change-hit-goal-subtitle')}</Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
