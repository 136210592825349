import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RatingScale, RatingType } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

export const FitClothesRating = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.FitClothesRating],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (rating: RatingType) => {
        setPageValue(rating.value);

        saveAnswers({
            key: 'fit-clothes-rating',
            itemQuestion: { question: `${t('fit-clothes-rating-title')} - ${t('fit-clothes-rating-subtitle')}`, value: rating.value },
        });

        pageConfiguration.handleOnPageComplete(rating.value);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('fit-clothes-rating-title')}
            </Typography>
            <Typography variant={TypographyVariants.h2}>{t('fit-clothes-rating-subtitle')}</Typography>

            <RatingScale
                value={pageValue}
                handleClick={handleClick}
                ratingGradationInfo={[t('rating-scale-default-gradation-disagree'), t('rating-scale-default-gradation-agree')]}
            />
        </>
    );
};
