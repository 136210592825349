import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BaseContext } from 'context/base.context';

import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';
import { getPrice } from 'helpers/payment';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';
import { useConvertPrice } from 'hooks/use-convert-price.hook';

import { SignUpApiProvider } from 'api/sign-up.api';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { BILLING_PLATFORM, CUSTOMER_ID } from 'constants/payment';
import { EBOOK_TOOLKIT } from 'constants/ebook-plan';
import { ExperimentsKeys } from 'constants/experiments';
import { BreadcrumbsNavigationKeys } from 'constants/breadcrumbs-navigation';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { BreadcrumbsNavigation } from 'components/breadcrumbs-navigation';

import { LoadingModalWindow } from 'pages/paywall/payment/loading-modal-window';

import { ToolkitDescription } from './toolkit-description';
import { SkipUpgradeModalWindow } from './skip-upgrade-modal-window';

import { useStyles } from './styles';

export const Upgrade = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    const {
        pageWrapper,
        title,
        buttonsWrapper,
        skipButtonWrapper,
        skipButton,
        startButtonWrapper,
        startButton,
        info,
        discountBlock,
        discountTitle,
        minHeight,
    } = useStyles();

    const { setPageValue: setIsBoughtEbook }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Upgrade],
        defaultValue: false,
    });

    const items = ['svg/upgrade/discount.svg'];
    const pageImages = useBackgroundImageService(items);

    const [loading, setLoading] = useState(false);
    const [discountPriceApplied, setDiscountPriceApplied] = useState(false);
    const [skipUpgradeModalWindow, setSkipUpgradeModalWindow] = useState(false);

    const handleUpgradeModalWindowClick = () => {
        setSkipUpgradeModalWindow(false);
        pageConfiguration.event('EBookUpsellPopUpClicked');
    };

    const handleSkipClick = () => {
        pageConfiguration.event('EBookUpsellSkipClicked');
        if (discountPriceApplied) {
            navigate(`..${RoutePath.Congrats}`);
            return;
        }
        setSkipUpgradeModalWindow(true);
        setDiscountPriceApplied(true);
    };

    const handleBuyNowClick = () => {

        setLoading(true);

        const pwaNativeSplitVariation = localStorage.getItem(ExperimentsKeys.pwaNativeSplitVariationVersion2);
        const useCfWorker = pwaNativeSplitVariation === '2';
        const customerId = localStorage.getItem(CUSTOMER_ID) as string || '';
        const price = discountPriceApplied ? EBOOK_TOOLKIT.discountPrice : EBOOK_TOOLKIT.price;

        const paymentType = BILLING_PLATFORM.Primer;

        pageConfiguration.event('EBookUpsellPurchaseClicked', { price: price / 100 });

        (useCfWorker ?
            SignUpApiProvider.chargeCf({
                userId: customerId,
                price,
                currencyCode: EBOOK_TOOLKIT.currencyCode,
            })
            :
            SignUpApiProvider
                .charge({
                    customerId,
                    invoiceItemPriceId: EBOOK_TOOLKIT.invoiceItemPriceId,
                    price,
                    paymentType,
                    currency: EBOOK_TOOLKIT.currencyCode,
                }))
            .then(() => {
                setIsBoughtEbook(true);
                pageConfiguration.event('EBookUpsellPurchased', { price: price / 100 });
            }).catch((e) => {
            pageConfiguration.event('EBookUpsellPurchaseError', {
                price: price / 100,
                error: e?.type,
                message: e?.message,
            });
        }).finally(() => {
            setLoading(false);
            pageConfiguration.handleOnPageComplete();
        });
    };

    let companyMentionVariationExp = false;

    const rounded = localStorage.getItem(ExperimentsKeys.wl_price_rounding) as string;
    const currencyLabel = useConvertPrice(EBOOK_TOOLKIT.price, rounded).currencyLabel;
    const convertedPrice = useConvertPrice(getPrice(EBOOK_TOOLKIT.price), rounded).convertedPriceWithTax;
    const convertedDiscountPrice = useConvertPrice(getPrice(EBOOK_TOOLKIT.discountPrice), rounded).convertedPriceWithTax;
    const convertedPriceValue = Number.isInteger(convertedPrice) ? convertedPrice : convertedPrice.toFixed(2);
    const convertedDiscountPriceValue = Number.isInteger(convertedDiscountPrice) ? convertedDiscountPrice : convertedDiscountPrice.toFixed(2);
    const priceValue = convertedPriceValue;
    const discountPriceValue = convertedDiscountPriceValue;

    const wl_post_paywall_progress_bar = localStorage.getItem(ExperimentsKeys.wl_post_paywall_progress_bar) === '1';

    return (
        <div className={`${pageWrapper} ${companyMentionVariationExp && minHeight}`}>

            {wl_post_paywall_progress_bar && <BreadcrumbsNavigation activeSection={BreadcrumbsNavigationKeys.addToPlan}
                                                                    completedSections={[BreadcrumbsNavigationKeys.healthPlan]} />}

            <LoadingModalWindow loading={loading} />

            <Typography variant={TypographyVariants.h1} typographyNext className={title}>
                {t('upgrade-title')}
            </Typography>

            {skipUpgradeModalWindow && <SkipUpgradeModalWindow handleClick={handleUpgradeModalWindowClick} />}

            <ToolkitDescription currencyLabel={currencyLabel} price={priceValue} discountPrice={discountPriceValue}
                                discountPriceApplied={discountPriceApplied} />

            {discountPriceApplied &&
                <div className={discountBlock}>
                    <img src={pageImages[0]} alt='Discount' />
                    <Typography variant={TypographyVariants.h3} className={discountTitle}>
                        <Trans i18nKey='upgrade-discount-info' components={[<span></span>]} />
                    </Typography>
                </div>
            }

            <div className={buttonsWrapper}>
                <NextButton
                    typographyText={t('upgrade-button-skip')}
                    buttonWrapperClassName={skipButtonWrapper}
                    className={skipButton}
                    onClick={handleSkipClick} />

                <NextButton
                    typographyText={t('upgrade-button-buy')}
                    buttonWrapperClassName={startButtonWrapper}
                    className={startButton}
                    onClick={handleBuyNowClick} />
            </div>

            <Typography variant={TypographyVariants.h3} className={info}>
                {t('upgrade-info', { currencyLabel, price: discountPriceApplied ? discountPriceValue : priceValue })}
            </Typography>

            <Typography variant={TypographyVariants.h3} className={info}>
                {t('multicurrency-payment-info')}
            </Typography>
        </div>
    );
};
