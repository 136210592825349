import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    footer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '-25em',
        backgroundColor: '#010101',
    },
    atTheBottom: {
        width: '100%',
        position: 'relative',
        bottom: 0,
    },
    footerContent: {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 24px',
        color: '#FFFFFF',
    },
    smallLogo: {
        width: '70px',
        height: '25.45px',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '48px',

        '&:last-child': {
            marginBottom: 0,
        }
    },
    textWithBottomMargin: {
        marginBottom: '8px'
    },
    links: {
        '& a': {
            color: '#FFFFFF',
            textDecoration: 'underline',
            textUnderlineOffset: '3px',
            '&:after': {
                display: 'inline-block',
                content: '"|"',
                padding: '5px',
            },
            '&:last-child': {
                '&:after': {
                    content: '""',
                    padding: '0px',
                }
            }
        }
    },
    socialMedia: {
        alignSelf: 'center',
        '& a': {
            paddingRight: '36px',

            '&:last-child': {
                paddingRight: 0,
            }
        },
    },
    phoneNumber: {
        textAlign: 'center',
    },
    mobileBlock: {
        marginBottom: '24px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    smallerBottomMargin: {
        marginBottom: '8px',
    },
    '@media only screen and (min-width: 800px)' : {
        footerContent: {
            width: '80%',
            maxWidth: '1240px',
            alignItems: 'unset',
            marginRight: 'auto',
            marginLeft: 'auto',
            padding: '56px 0',
        },
        links: {
            '& a': {
                textDecoration: 'none',
            }
        },
        phoneNumber: {
            textAlign: 'right',
        }
    },
});
