import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { UNIT_SYSTEM } from 'constants/body-height-weight';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { UserGeolocationKeys } from 'constants/user-geolocation';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { LbsInput } from 'components/mass-input/lbs-input';
import { KgInput } from 'components/mass-input/kg-input';

import {
    convertToCm,
    convertToFtInches,
    convertToInches,
    convertToKg,
    convertToLbs,
} from 'helpers/unit-converter';

import { getBmi } from 'services/body-plan-calculator.service';

import { CmInput } from './cm-input';
import { FtInchesInputs } from './ft-inches-inputs';

import { useStyles } from './styles';

type ErrorType = {
    error: boolean;
    key: string;
}

export interface BodyHeightWeightInputsProps {
    heightInput: boolean;
    idealWeightInput: boolean;
    setInputError: Dispatch<SetStateAction<ErrorType>>;
    underweightError?: boolean;
    setUnderweightError?: (underweight: boolean) => void;
    pageError: boolean;
}

export const BodyHeightWeightInputs: FC<BodyHeightWeightInputsProps> = ({ heightInput, idealWeightInput, setInputError, underweightError, setUnderweightError, pageError }) => {
    const { t } = useTranslation();

    const { marginTop, errorText, switcherButton, switcherLink, thanksForSharingText, excitementText, underweightWarning, unitSystemSwitcher } = useStyles();

    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;
    const countryCode = localStorage.getItem(UserGeolocationKeys.countryCode) as string;

    const { pageValue: bodyHeightWeightUnitSystem, setPageValue: setBodyHeightWeightUnitSystem } =
        useLocalStorage({
            key: bodyHeightWeightKeys.unitSystem,
            defaultValue: countryCode === 'US' || countryCode === 'CA' ? UNIT_SYSTEM.imperial : UNIT_SYSTEM.metric,
        });

    const { pageValue: bodyHeightCm, setPageValue: setBodyHeightCm } = useLocalStorage({
        key: bodyHeightWeightKeys.heightCm,
        defaultValue: '',
    });

    const { pageValue: bodyHeightFt, setPageValue: setBodyHeightFt } = useLocalStorage({
        key: bodyHeightWeightKeys.heightFt,
        defaultValue: '',
    });

    const { pageValue: bodyHeightInches, setPageValue: setBodyHeightInches } = useLocalStorage({
        key: bodyHeightWeightKeys.heightInches,
        defaultValue: '',
    });

    const { pageValue: bodyWeightLbs, setPageValue: setBodyWeightLbs } = useLocalStorage({
        key: bodyHeightWeightKeys.weightLbs,
        defaultValue: '',
    });

    const { pageValue: bodyWeightKg, setPageValue: setBodyWeightKg } = useLocalStorage({
        key: bodyHeightWeightKeys.weightKg,
        defaultValue: '',
    });

    const { pageValue: bodyIdealWeight, setPageValue: setBodyIdealWeight } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyIdealWeight],
        defaultValue: '',
    });

    const { setPageValue: setBodyHeightOnlyInches } = useLocalStorage({
        key: bodyHeightWeightKeys.heightOnlyInches,
        defaultValue: '',
    });

    const minKgValue = 26.76;
    const maxKgValue = 454.046;
    const minLbsValue = 59;
    const maxLbsValue = 1002;
    const minFtValue = 1;
    const maxFtValue = 9;
    const maxInchesValue = 12;
    const minCmValue = 60;
    const maxCmValue = 273;

    const onTabChange = (nextTab: string) => {
        if (nextTab === UNIT_SYSTEM.imperial) {
            const lbs = convertToLbs(Number(bodyWeightKg));
            const idealLbs = convertToLbs(Number(bodyIdealWeight));
            const { ft: convertedFt, inches: convertedInches } = convertToFtInches(
                Number(bodyHeightCm)
            );

            if (lbs && lbs > minLbsValue && lbs < maxLbsValue) {
                setBodyWeightLbs(lbs);
            } else if (bodyWeightKg === '') {
                setBodyWeightLbs('');
            }

            if (idealLbs) {
                setBodyIdealWeight(idealLbs);
            } else if (bodyIdealWeight === '') {
                setBodyIdealWeight('');
            }

            if (convertedFt && convertedFt > minFtValue && convertedFt < maxFtValue) {
                setBodyHeightFt(convertedFt);
            } else if (bodyHeightCm === '') {
                setBodyHeightFt('');
            }

            if (convertedFt && convertedFt > minFtValue && convertedFt < maxFtValue && convertedInches && convertedInches < maxInchesValue) {
                setBodyHeightInches(convertedInches);
            } else if (bodyHeightCm === '') {
                setBodyHeightInches('');
            }

            setBodyHeightOnlyInches(convertToInches(Number(bodyHeightCm)));
        } else {
            const kg = convertToKg(Number(bodyWeightLbs));
            const idealKg = convertToKg(Number(bodyIdealWeight));
            const cm = convertToCm(Number(bodyHeightFt), Number(bodyHeightInches));

            if (kg && kg > minKgValue && kg < maxKgValue) {
                setBodyWeightKg(kg);
            } else if (bodyWeightLbs === '') {
                setBodyWeightKg('');
            }

            if (idealKg) {
                setBodyIdealWeight(idealKg);
            } else if (bodyIdealWeight === '') {
                setBodyIdealWeight('');
            }

            if (cm && cm > minCmValue && cm < maxCmValue) {
                setBodyHeightCm(cm);
            } else if (bodyHeightFt === '' && bodyHeightInches === '') {
                setBodyHeightCm('');
            }
        }
        setBodyHeightWeightUnitSystem(nextTab);
    };

    const onChangeFt = (e: any) => {
        setBodyHeightFt(e.target.value);
        const { name } = e.target;
        const [, fieldIndex] = name.split('-');

        let fieldIntIndex = parseInt(fieldIndex, 10);
        if (e.target.value.length === 1 && e.target.value > 1 && e.target.value < 9) {
            const nextField = document.querySelector(
                `input[name=field-${fieldIntIndex + 1}]`
            ) as HTMLInputElement;
            nextField.focus();
        }
    };

    const onChangeInches = (e: any) => {
        setBodyHeightInches(e.target.value);
    };

    const onChangeCm = (e: any) => {
        setBodyHeightCm(e.target.value);
    };

    const onChangeLbs = (e: any) => {
        idealWeightInput ? setBodyIdealWeight(e.target.value) : setBodyWeightLbs(e.target.value);
    };

    const onChangeKg = (e: any) => {
        idealWeightInput ? setBodyIdealWeight(e.target.value) : setBodyWeightKg(e.target.value);
    };

    const onError = (error: boolean, key: string) => {
        setInputError((prevState: ErrorType) => ({
            ...prevState,
            [key]: error
        }));
    };

    const isBodyHeightInchesEmpty = bodyHeightInches === '';
    const adjustedBodyHeightInches = isBodyHeightInchesEmpty ? 0 : parseInt(bodyHeightInches);
    const currentWeight = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? parseInt(bodyWeightLbs) : parseInt(bodyWeightKg);
    const idealWeight = parseInt(bodyIdealWeight);
    const weight = idealWeightInput ? idealWeight : currentWeight;
    const height = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial
        ? parseInt(bodyHeightFt) * 12 + adjustedBodyHeightInches
        : parseInt(bodyHeightCm);

    const bmi = getBmi(weight, height);

    useEffect(() => {

        const underweightValueImperial =
            !!bodyWeightLbs &&
            bodyWeightLbs > minLbsValue &&
            bodyWeightLbs !== '' &&
            bodyWeightLbs < maxLbsValue &&
            bodyHeightFt > minFtValue &&
            bodyHeightFt !== '' &&
            bodyHeightFt < maxFtValue &&
            bodyHeightInches < maxInchesValue &&
            bmi < 18.5;

        const underweightValueMetric =
            !!bodyWeightKg &&
            bodyWeightKg > minKgValue &&
            bodyWeightKg !== '' &&
            bodyWeightKg < maxKgValue &&
            bodyHeightCm > minCmValue &&
            bodyHeightCm !== '' &&
            bodyHeightCm < maxCmValue &&
            bmi < 18.5;

        if (setUnderweightError) {
            setUnderweightError(bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? underweightValueImperial : underweightValueMetric);
        }

    }, [bodyWeightLbs, bodyWeightKg, bodyHeightCm, bodyHeightFt, bodyHeightInches, bmi, bodyHeightWeightUnitSystem, bodyIdealWeight]);

    const setCurrentTab = () => {
        const newTab = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? UNIT_SYSTEM.metric : UNIT_SYSTEM.imperial;
        onTabChange(newTab);
    };

    const heightInputSwitcherButtonText = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? t('body-height-switch-button-metric') : t('body-height-switch-button-imperial');
    const weightInputSwitcherButtonText = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? t('body-weight-switch-button-metric') : t('body-weight-switch-button-imperial');

    const wl_first_screens_agency_experiment = localStorage.getItem(ExperimentsKeys.wl_first_screens_agency_experiment) === '1';

    const bodyHeightUnitSystemLabel = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? t('ft') : t('cm');
    const bodyWeightUnitSystemLabel = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? t('lbs') : t('kg');
    const currentUnitSystemLabel = heightInput ? bodyHeightUnitSystemLabel : bodyWeightUnitSystemLabel;

    return (
        <>
            {bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial && (
                <>
                    {heightInput && !wl_first_screens_agency_experiment && <FtInchesInputs
                        ft={bodyHeightFt}
                        inches={bodyHeightInches}
                        onChangeFt={onChangeFt}
                        onChangeInches={onChangeInches}
                        onError={onError}
                    />}

                    {!heightInput && !idealWeightInput && <div className={marginTop}>
                        <LbsInput value={bodyWeightLbs} onChange={onChangeLbs} onError={onError} />
                    </div>}

                    {!heightInput && idealWeightInput && <div className={marginTop}>
                        <LbsInput value={bodyIdealWeight} lessThen={Number(bodyWeightLbs)} onChange={onChangeLbs}
                                  onError={onError} />
                    </div>}
                </>
            )}

            {bodyHeightWeightUnitSystem === UNIT_SYSTEM.metric && (
                <>
                    {heightInput && !wl_first_screens_agency_experiment && <CmInput value={bodyHeightCm} onChange={onChangeCm} onError={onError} />}

                    {!heightInput && !idealWeightInput && <div className={marginTop}>
                        <KgInput value={bodyWeightKg} onChange={onChangeKg} onError={onError} />
                    </div>}

                    {!heightInput && idealWeightInput && <div className={marginTop}>
                        <KgInput value={bodyIdealWeight} lessThen={Number(bodyWeightKg)} onChange={onChangeKg}
                                  onError={onError} />
                    </div>}
                </>
            )}
            {underweightError && !heightInput && !idealWeightInput && (
                <Typography className={errorText} variant={TypographyVariants.h3}>
                    {t('body-height-weight-title-error')}
                </Typography>
            )}
            {!pageError && underweightError && !heightInput && idealWeightInput && (
                <>
                    <Typography className={`${excitementText} ${underweightWarning}`} variant={TypographyVariants.h3}>
                        {t('body-height-weight-ideal-weight-underweight-error-title')}
                    </Typography>
                    <Typography className={`${excitementText} ${underweightWarning}`} variant={TypographyVariants.h3}>
                        {t('body-height-weight-ideal-weight-underweight-error-description')}
                    </Typography>
                </>
            )}
            {!pageError && !underweightError && ((bodyHeightWeightUnitSystem === UNIT_SYSTEM.metric && bodyWeightKg) || (bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial && bodyWeightLbs)) && !heightInput && !idealWeightInput && (
                <>
                    <Typography className={thanksForSharingText} variant={TypographyVariants.h3}>
                        {t('body-height-weight-thanks-for-sharing-title')}
                    </Typography>
                    <Typography className={thanksForSharingText} variant={TypographyVariants.h3}>
                        {t('body-height-weight-thanks-for-sharing-description')}
                    </Typography>
                </>
            )}

            {!pageError && bodyIdealWeight && idealWeightInput && !underweightError && (
                <>
                    <Typography className={excitementText} variant={TypographyVariants.h3}>
                        {t('body-ideal-weight-goal-excitement-title')}
                    </Typography>
                    <Typography className={excitementText} variant={TypographyVariants.h3}>
                        {t('body-ideal-weight-goal-excitement-description')}
                    </Typography>
                </>
            )}

            {!idealWeightInput && wl_first_screens_agency_experiment && <div className={unitSystemSwitcher} onClick={() => setCurrentTab()} onDragEnd={() => setCurrentTab()}>
                <p>{heightInput ? t('ft') : t('lbs')}</p>
                <p>{heightInput ? t('cm') : t('kg')}</p>
                <span data-u={currentUnitSystemLabel}>{currentUnitSystemLabel}</span>
            </div>}

            {!idealWeightInput && !wl_first_screens_agency_experiment && <button className={switcherButton} onClick={() => setCurrentTab()}>
                <Typography variant={TypographyVariants.h2} className={switcherLink}>
                    {heightInput ? heightInputSwitcherButtonText : weightInputSwitcherButtonText}
                </Typography>
            </button>}
        </>
    );
};
