import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const WalkDaily = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageValue: healthyFridge, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.WalkDaily],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const answers: ChoiceType[] = [
        {
            key: 'less-one',
            value: t('walk-daily-option-less-one'),
        },
        {
            key: 'one-to-two',
            value: t('walk-daily-option-one-to-two'),
        },
        {
            key: 'more-two',
            value: t('walk-daily-option-more-two'),
        },
    ];

    const handleClick = (answer: ChoiceType) => {
        saveAnswers({
            key: 'walk-daily',
            itemQuestion: { question: t('walk-daily-title'), value: t(answer.value) },
        });
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>{t('walk-daily-title')}</Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === healthyFridge}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
