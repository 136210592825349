export const ExperimentsKeys = {
    pwaNativeSplitVariationVersion2: 'split_native_vs_pwa_version_2',
    redirect_to_ebook_if_upsell_fails: 'redirect_to_ebook_if_upsell_fails',
    wl_price_rounding: 'wl_price_rounding',
    wl_first_screens_agency_experiment: 'wl_first_screens_agency_experiment',
    wl_post_paywall_progress_bar: 'wl_post_paywall_progress_bar',
    wl_questions_update: 'wl_questions_update',
    wl_personality_quiz: 'wl_personality_quiz',
    wl_metabolic_rate_screen: 'wl_metabolic_rate_screen',
    wl_paywall_short_versions: 'wl_paywall_short_versions',
};
