import React from 'react';

import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { ExperimentsKeys } from 'constants/experiments';

import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';

import { TipPage } from 'components/tip-page';

export const UpgradeTip = () => {

    const { pageValue: isBoughtEbook }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Upgrade],
        defaultValue: false,
    });

    if (isBoughtEbook) {
        disableBrowserBackButton(window.location.href);
    }

    const pwaNativeSplitVariationExp = localStorage.getItem(ExperimentsKeys.pwaNativeSplitVariationVersion2) === '0';

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/sand-clock.svg'
            text={{
                description: 'upgrade-tip-text',
                next: 'upgrade-tip-button-next',
            }}
            needToRedirectToPwa={pwaNativeSplitVariationExp}
        />
    );
};
