import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

export const PhysicalLimitations = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.PhysicalLimitations],
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.PhysicalLimitations]}-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: MultiChoiceType[] = [
        { key: 'none', value: t('physical-limitations-option-none') },
        { key: 'back', value: t('physical-limitations-option-back') },
        { key: 'knees', value: t('physical-limitations-option-knees') },
        { key: 'other', value: t('physical-limitations-option-other'), userAnswer, setUserAnswer },
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: LocalStorageKeys[RoutePath.PhysicalLimitations],
            itemQuestion: { question: t('physical-limitations-title'), value: selectOption },
        });
        pageConfiguration.handleOnPageComplete(pageValue.includes('other') ? {
            pageValue,
            userAnswer: userAnswer.trim()
        } : { pageValue });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('physical-limitations-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2}>{t('physical-limitations-subtitle')}</Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}

            <NextButton onClick={handleClick} disabled={!pageValue.length} />
        </>
    );
};
