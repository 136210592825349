import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    ebookGiftBlockWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '16px',
        padding: '16px',
        background: '#F6F6F6',
        borderRadius: '8px',

        '& > div:first-child': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '6px',
            width: '100%',

            '& h3': {
                fontSize: '16px',
                fontWeight: 500,
                margin: 'unset'
            },

            '& h3:last-child': {
                fontWeight: 400,
                color: '#5E626B',
                textDecoration: 'line-through'
            }
        },

        '& > div:last-child': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '8px',

            '& img': {
                width: '91.5px',
                height: '129px'
            }
        }
    },
    priceWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: '6px'
    }
});
