import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';
import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

export const WeightGain = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.WeightGain],
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.WeightGain]}-userAnswer`,
        defaultValue: '',
    });

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'busier-work',
            value: t('weight-gain-option-busier-work'),
        },
        {
            key: 'family-life',
            value: t('weight-gain-option-family-life'),
        },
        {
            key: 'pregnancy',
            value: t('weight-gain-option-pregnancy'),
        },
        {
            key: 'relationships',
            value: t('weight-gain-option-relationships'),
        },
        {
            key: 'medication',
            value: t('weight-gain-option-medication'),
        },
        {
            key: 'stress',
            value: t('weight-gain-option-stress'),
        },
        {
            key: 'injury',
            value: t('weight-gain-option-injury'),
        },
        {
            key: 'post-covid',
            value: t('weight-gain-option-post-covid'),
        },
        {
            key: 'other',
            value: t('weight-gain-option-other'),
            userAnswer, setUserAnswer,
        },
        {
            key: 'none',
            value: t('weight-gain-option-none'),
        },
    ];

    const wl_personality_quiz = useFeatureIsOn(ExperimentsKeys.wl_personality_quiz);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.wl_personality_quiz, wl_personality_quiz ? '1' : '0');
    }, [wl_personality_quiz]);

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: LocalStorageKeys[RoutePath.WeightGain],
            itemQuestion: { question: t('weight-gain-title'), value: selectOption },
        });
        pageConfiguration.handleOnPageComplete(pageValue.includes('other') ? {
            pageValue,
            userAnswer: userAnswer.trim()
        } : { pageValue });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('weight-gain-title')}
            </Typography>
            <Typography variant={TypographyVariants.h2}>{t('weight-gain-subtitle')}</Typography>
            {multiChoices
                .filter((v: MultiChoiceType) => userGender === 'female' || v.key !== 'pregnancy')
                .map((v: MultiChoiceType) => (
                    <MultiChoiceButton
                        key={v.key}
                        item={v}
                        pageValue={pageValue}
                        setPageValue={setPageValue}
                    />
                ))}

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
