import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { getUnitSystem, UNIT_SYSTEM } from 'constants/body-height-weight';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { BodyHeightWeightInputs } from 'components/body-height-weight-inputs';
import { NextButton } from 'components/next-button';

import { InfoBlock } from 'pages/paywall/info-block';

import { useStyles } from './styles';

const convertDataForIntakeForm = (data: { unit: string, bodyIdealWeight: number }) => {
    return `Ideal weight: ${data.bodyIdealWeight} ${data.unit === UNIT_SYSTEM.metric ? 'kg' : 'lb'}`;
};

const calculateIdealWeight = (height: number, weight: number, unitSystem: string) => {
    const idealWeightCm = Math.round(20 * (height / 100) ** 2);
    const idealWeightLbs = Math.round(idealWeightCm * 2.205);

    const calculatedIdealWeight = unitSystem === UNIT_SYSTEM.imperial ? idealWeightLbs : idealWeightCm;

    return calculatedIdealWeight > weight ? weight - 1 : calculatedIdealWeight;
};

export const BodyIdealWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { remark, colored } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;

    const { pageValue: bodyIdealWeight } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyIdealWeight],
        defaultValue: '',
    });
    const { pageValue: weightLbs }: any = useLocalStorage({ key: bodyHeightWeightKeys.weightLbs, defaultValue: '' });
    const { pageValue: weightKg }: any = useLocalStorage({ key: bodyHeightWeightKeys.weightKg, defaultValue: '' });
    const { pageValue: heightCm }: any = useLocalStorage({ key: bodyHeightWeightKeys.heightCm, defaultValue: '' });

    const unitSystem = getUnitSystem() as string;
    const typicalWeightLossValue = unitSystem === UNIT_SYSTEM.metric ? t('body-ideal-weight-remark-typical-weight-loss-value-kg') : t('body-ideal-weight-remark-typical-weight-loss-value-lbs');

    const handleClick = () => {
        if (disabledButtonState) {
            return;
        }

        pageConfiguration.handleOnPageComplete({ bodyIdealWeight, unit: unitSystem });
        saveAnswers({
            key: 'body-ideal-weight',
            itemQuestion: {
                question: t('body-ideal-weight-title'),
                value: convertDataForIntakeForm({ unit: unitSystem as string, bodyIdealWeight }),
            },
        });
    };

    const [pageError, setPageError] = useState(false);
    const [underweightError, setUnderweightError] = useState(false);

    const [disabledButtonState, setDisabledButtonState] = useState(true);
    const [errors, setError] = useState({});

    useEffect(() => {

        setPageError(() => {
            // @ts-ignore
            return Object.keys(errors).some((key) => errors[key]);
        });

    }, [JSON.stringify(errors)]);

    useEffect(() => {
        const btnState = pageError || !bodyIdealWeight || underweightError;

        setDisabledButtonState(btnState);
    }, [
        bodyIdealWeight,
        underweightError,
        pageError,
    ]);

    const wl_first_screens_agency_experiment = localStorage.getItem(ExperimentsKeys.wl_first_screens_agency_experiment) === '1';

    const weight = unitSystem === UNIT_SYSTEM.imperial ? weightLbs : weightKg;
    const height = parseInt(heightCm);
    const idealWeight = useMemo(() => calculateIdealWeight(height, weight, unitSystem), [height, weight, unitSystem]);

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={wl_first_screens_agency_experiment}>
                {t('body-ideal-weight-title')}
            </Typography>

            {wl_first_screens_agency_experiment &&
                <Typography variant={TypographyVariants.h2}>
                    <Trans i18nKey='body-ideal-weight-subtitle' components={[<span className={colored}></span>]}
                           values={{ idealWeight }} />
                </Typography>
            }

            <BodyHeightWeightInputs heightInput={false} idealWeightInput underweightError={underweightError}
                                    setUnderweightError={setUnderweightError} setInputError={setError}
                                    pageError={pageError} />

            <NextButton onClick={handleClick} disabled={disabledButtonState} />

            {!wl_first_screens_agency_experiment && <Typography variant={TypographyVariants.p} className={remark}>
                *{t('body-ideal-weight-remark', { typicalWeightLossValue })}
            </Typography>}

            {wl_first_screens_agency_experiment && <InfoBlock
                imgSrc={'svg/info-block-icons/star.svg'}
                descriptionKey={'body-ideal-weight-remark'}
                bgColor={'#F6F6F6'}
                descriptionDynamicProps={{ typicalWeightLossValue }} />
            }
        </>
    );
};
