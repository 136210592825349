import React, { FC, useEffect } from 'react';
import i18n from 'translations/i18n';

import { ValidatedInput } from '../validated-input';

import { ageValidationQuery } from 'components/age-input/age-validation-query';

const props = { placeholder: i18n.t('default-age-input'), maxLength: 3 };

const LABEL = 'age';

type AgeInputProps = {
    value: string;
    onChange: (e: any) => void;
    onError: (error: boolean, key: string) => void;
};

export const AgeInput: FC<AgeInputProps> = ({
    value,
    onChange,
    onError,
}) => {
    const handleError = (key: string) => (error: boolean) => onError(error, key);

    useEffect(() => () => onError(false, LABEL), []);

    return (
        <ValidatedInput
            type="number"
            value={value}
            onChange={onChange}
            onError={handleError(LABEL)}
            validationQuery={ageValidationQuery}
            {...props}
        />
    );
};
