import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const PersonalityQuizPrefer = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.PersonalityQuizPrefer];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const answers: ChoiceType[] = [
        {
            key: 'judging',
            value: t('personality-quiz-prefer-option-structure'),
        },
        {
            key: 'perceiving',
            value: t('personality-quiz-prefer-option-flexibility'),
        },
    ];

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('personality-quiz-prefer-title'), value: t(answer.value) },
        });

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('personality-quiz-prefer-title')}
            </Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
