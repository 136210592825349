import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const AchieveGoal = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageValue: achieveGoal, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.AchieveGoal],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);
        saveAnswers({
            key: 'achieve-goal',
            itemQuestion: { question: t('achieve-goal-title'), value: t(answer.value) },
        });

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: 'fast', value: t('achieve-goal-option-fast') },
        { key: 'slow', value: t('achieve-goal-option-slow') },
        { key: 'average', value: t('achieve-goal-option-average') },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>{t('achieve-goal-title')}</Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === achieveGoal}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
