import { useState } from 'react';

import { GtagKeys } from 'constants/analytics';

export const useGtagClientId = () => {
    const [gtagClientId, setGtagClientId] = useState('');

    if (window.gtag && !gtagClientId) {
        window.gtag('get', 'G-72M2PQRMV1', 'client_id', (clientId: string) => {
            localStorage.setItem(GtagKeys.gtagClientId, clientId);
            setGtagClientId(clientId);
        });
    }

    return {
        gtagClientId,
    }
};
