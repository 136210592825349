import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { RoutePath } from 'routes/route-path.constant';

import { BodyPlanCalculator } from 'services/body-plan-calculator.service';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { getUnitSystem, UNIT_SYSTEM } from 'constants/body-height-weight';

export const useBodyPlanData = () => {
    const [monthsPlan, setMonthsPlan] = useState<string[]>([]);
    const [weightPlan, setWeightPlan] = useState<number[]>([]);

    const [monthsData, setMonthsData] = useState<string[]>([]);
    const [weightsData, setWeightsData] = useState<number[]>([]);

    const [earlierMonthsData, setEarlierMonthsData] = useState<string[]>([]);
    const [earlierWeightsData, setEarlierWeightsData] = useState<number[]>([]);

    const [earlierMonthsPlan, setEarlierMonthsPlan] = useState<string[]>([]);
    const [earlierWeightPlan, setEarlierWeightPlan] = useState<number[]>([]);

    const [finalDay, setFinalDay] = useState<string>('');
    const [finalMonth, setFinalMonth] = useState<string>('');

    const [finalDate, setFinalDate] = useState<dayjs.Dayjs | null>(null);
    const [earlierFinalDate, setEarlierFinalDate] = useState<dayjs.Dayjs | null>(null);

    const [earlierFinalDay, setEarlierFinalDay] = useState<string>('');
    const [earlierFinalMonth, setEarlierFinalMonth] = useState<string>('');

    const [earlierWeightLossDifference, setEarlierWeightLossDifference] = useState<number>(0);

    const [adjustedGoalWeight, setAdjustedGoalWeight] = useState<number>(0);
    const [earlierAdjustedGoalWeight, setEarlierAdjustedGoalWeight] = useState<number>(0);

    const unitSystem = getUnitSystem();
    const unit = unitSystem === UNIT_SYSTEM.imperial ? 'lb' : 'kg';
    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;

    const { pageValue: weightLbs }: any = useLocalStorage({ key: bodyHeightWeightKeys.weightLbs, defaultValue: '' });
    const { pageValue: weightKg }: any = useLocalStorage({ key: bodyHeightWeightKeys.weightKg, defaultValue: '' });
    const { pageValue: heightFt }: any = useLocalStorage({ key: bodyHeightWeightKeys.heightFt, defaultValue: '' });
    const { pageValue: heightInches }: any = useLocalStorage({
        key: bodyHeightWeightKeys.heightInches,
        defaultValue: '',
    });
    const { pageValue: heightCm }: any = useLocalStorage({ key: bodyHeightWeightKeys.heightCm, defaultValue: '' });
    const { pageValue: goalWeight }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyIdealWeight],
        defaultValue: '',
    });

    const weight = unitSystem === UNIT_SYSTEM.imperial ? parseInt(weightLbs) : parseInt(weightKg);

    const isBodyHeightInchesEmpty = heightInches === '';
    const adjustedBodyHeightInches = isBodyHeightInchesEmpty ? 0 : parseInt(heightInches);
    const height = unitSystem === UNIT_SYSTEM.imperial ? parseInt(heightFt) * 12 + adjustedBodyHeightInches : parseInt(heightCm);

    let goal = weight > goalWeight ? 'lose-weight' : 'get-fit';

    useEffect(() => {
        const bodyPlan = new BodyPlanCalculator({ weight, height, goalWeight, unit });

        const { adjustedGoalWeight } = bodyPlan.getWeightLossData(false);

        const { finalDate, finalDay, finalMonth, weights, months, adjustedWeightsPoints, adjustedMonthsPoints } = bodyPlan.getWeightLossData(false, adjustedGoalWeight);

        const {
            finalDate: earlierFinalDate,
            finalDay: earlierFinalDay,
            finalMonth: earlierFinalMonth,
            months: earlierInitialMonthsData,
            weights: earlierInitialWeightsData,
            adjustedWeightsPoints: earlierWeights,
            adjustedMonthsPoints: earlierMonths,
            adjustedGoalWeight: earlierAdjustedGoalWeight
        } = bodyPlan.getWeightLossData(true, adjustedGoalWeight);

        const earlierWeightLossDifference = bodyPlan.getEarlierDays(adjustedGoalWeight);

        // Weight loss goal chart
        setFinalDate(finalDate);
        setFinalDay(finalDay);
        setFinalMonth(finalMonth);

        setMonthsPlan(adjustedMonthsPoints);
        setMonthsData(months);

        setWeightPlan(adjustedWeightsPoints);
        setWeightsData(weights);

        setAdjustedGoalWeight(adjustedGoalWeight);

        // Earlier weight loss hit goal chart
        setEarlierFinalDay(earlierFinalDay);
        setEarlierFinalMonth(earlierFinalMonth);
        setEarlierWeightLossDifference(earlierWeightLossDifference);
        setEarlierMonthsData(earlierInitialMonthsData);
        setEarlierWeightsData(earlierInitialWeightsData);
        setEarlierMonthsData(earlierInitialMonthsData);
        setEarlierMonthsPlan(earlierMonths);
        setEarlierWeightPlan(earlierWeights);
        setEarlierAdjustedGoalWeight(earlierAdjustedGoalWeight);
        setEarlierFinalDate(earlierFinalDate);
    }, [weight, height, goalWeight, unit]);

    return {
        finalDate,
        monthsData,
        monthsPlan,
        weightPlan,
        weightsData,
        weight,
        unit,
        goal,
        lossDay: finalDay,
        lossMonth: finalMonth,
        earlierMonthsPlan,
        earlierWeightPlan,
        earlierLossDay: earlierFinalDay,
        earlierLossMonth: earlierFinalMonth,
        earlierInitialMonthsData: earlierMonthsData,
        earlierInitialWeightsData: earlierWeightsData,
        earlierWeightLossDifference,
        adjustedGoalWeight,
        earlierAdjustedGoalWeight,
        earlierFinalDate
    };
};
