import { CHARGE_PERIOD_UNIT } from 'constants/trial-pay';

export enum DISCOUNT_TYPE {
    percentage = 'percentage',
    flat = 'flat'
}

type Discount = {
    type: DISCOUNT_TYPE;
    amount: number;
}

export type PlanForUpdate = {
    key: string;
    price: number;
    currency: string;
    chargePeriod: number;
    chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT;
    discount?: Discount;
}

export const UPGRADE_PLAN: PlanForUpdate =
    {
        key: 'loseWeight_v3_3000_7_3500_7_day_USD',
        price: 3500,
        currency: 'USD',
        chargePeriod: 7,
        chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT.day,
        discount: {
            type: DISCOUNT_TYPE.flat,
            amount: 500,
        }
};

export const UPGRADE_PLAN_EXP: PlanForUpdate = {
        key: 'loseWeight_v3_6900_1_month_USD',
        price: 6900,
        currency: 'USD',
        chargePeriod: 30,
        chargeSubscriptionPeriodUnit: CHARGE_PERIOD_UNIT.day,
};
