import { CurrencyLabels } from 'constants/currency-labels';

export const countryToCurrencyData = (country: string) : { currencyCode: string, currencyLabel: string, tax: number } => {
    const result = { currencyCode: 'USD', currencyLabel: CurrencyLabels.US, tax: 0 };

    if (country === 'US') {
        return result;
    }

    // Canada
    if (country === 'CA') {
        return { currencyCode: 'CAD', currencyLabel: CurrencyLabels.CA, tax: 0 }
    }

    // United Kingdom
    if (country === 'GB') {
        return { currencyCode: 'GBP', currencyLabel: CurrencyLabels.GB, tax: 0 }
    }

    // Australia
    if (country === 'AU') {
        return { currencyCode: 'AUD', currencyLabel: CurrencyLabels.AU, tax: 0 }
    }

    // India
    if (country === 'IN') {
        return { currencyCode: 'INR', currencyLabel: CurrencyLabels.IN, tax: 0.18 }
    }

    // European Union (EU)
    if (['BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE'].includes(country)) {
        return { currencyCode: 'EUR', currencyLabel: CurrencyLabels.EU, tax: 0 }
    }

    // Saudi Arabia
    if (country === 'SA') {
        return { currencyCode: 'SAR', currencyLabel: CurrencyLabels.SAR, tax: 0 }
    }

    // United Arab Emirates
    if (country === 'AE') {
        return { currencyCode: 'AED', currencyLabel: CurrencyLabels.AED, tax: 0 }
    }

    // New Zealand
    if (country === 'NZ') {
        return { currencyCode: 'NZD', currencyLabel: CurrencyLabels.NZ, tax: 0 }
    }

    return result;
};
