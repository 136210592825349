
export type InputType = {
    inputWrapper: any,
    input: any,
    inputActiveClass: any,
    inputErrorClass: any,
};

export const InputStyles: InputType = {
    inputWrapper: {
        position: 'relative',
        background: '#F6F6F6',
        borderRadius: '.5rem',
        boxSizing: 'border-box',
        display: 'flex',
        fontFamily: 'SF Pro Text',
        alignItems: 'center',
        height: '56px',
        maxWidth: '560px',
        margin: '0 auto',
        padding: '8px 16px',
    },
    input: {
        border: 'none',
        outline: 'none',
        background: 'none',
        all: 'unset',
        display: 'block',
        width: '100%',
        boxSizing: 'border-box',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        borderRadius: '.5rem',
        color: '#010101',
        marginTop: '20px',
        caretColor: '#1375D6',

        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
        }
    },
    inputActiveClass: {
        backgroundImage: 'linear-gradient(#1375D6, #1375D6)',
        backgroundPosition: 'bottom 0 center',
        backgroundSize: '100% 2px',
        backgroundRepeat: 'no-repeat',
    },
    inputErrorClass: {
        backgroundImage: 'linear-gradient(#FF4D2B, #FF4D2B)',
        backgroundPosition: 'bottom 0 center',
        backgroundSize: '100% 2px',
        backgroundRepeat: 'no-repeat',
    },
};
