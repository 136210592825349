import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { EnchargePosition } from 'constants/encharge-position';
import { CUSTOMER_ID } from 'constants/payment';

import { getUserBmi } from './body-plan-calculator.service';

export type PageType = {
    customWrapper?: boolean,
    scrollableContent?: boolean,
    header: {
        email: boolean,
        logo: boolean,
        progress: {
            percents: number | null,
            label: string | null
        } | null
    },
    route: {
        next: (data?: any) => string,
    },
    events: {
        onEnter?: (data?: any) => string,
        onLeave?: (data?: any) => string,
    },
    // @description: string - required & string[] - any of the item required
    requiredKeys?: (string | string[])[],
    footerAtTheBottom?: boolean,
};

export type PageConfigurationType = {
    [key: string]: PageType;
};

export const DEFAULT_PAGES_CONFIGURATION: PageConfigurationType = {
    [RoutePath.GoalWithProgressBar]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 20,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyGender,
        },
        events: {
            onLeave: () => 'OnboardingFunnelStartClicked'
        }
    },
    [RoutePath.Goal]: {
        customWrapper: true,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.BodyGender,
        },
        events: {
            onLeave: () => 'OnboardingFunnelStartClicked'
        }
    },
    [RoutePath.MainGoal]: {
        customWrapper: true,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.BodyGender,
        },
        events: {
            onLeave: () => 'OnboardingFunnelStartClicked'
        }
    },
    [RoutePath.BodyGender]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 8,
                label: '',
            },
        },
        route: {
            // @TODO: Add enum for  BodyGender female
            next: () => localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]) === 'female' ? RoutePath.BodyBreastfeeding : RoutePath.BodyAge,
        },
        events: {
            onEnter: () => 'OnboardingGenderViewed',
            onLeave: () => 'OnboardingGenderClicked',
        }
    },
    [RoutePath.BodyBreastfeeding]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 12,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BodyAge,
        },
        events: {
            onLeave: () => 'OnboardingPregnantClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
        ]
    },
    [RoutePath.BodyAge]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 16,
                label: '',
            }
        },
        route: {
            // @TODO: Move keys to 'underTwenty', 'sixtyPlus' - to enum. All such keys
            next: () => ['underTwenty', 'sixtyPlus'].includes(localStorage.getItem(LocalStorageKeys[RoutePath.BodyAge]) as string) ? RoutePath.BodyAgeTip : RoutePath.BodyRisk
        },
        events: {
            onLeave: () => 'OnboardingAgeClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
        ]
    },
    [RoutePath.BodyAgeTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BodyRisk,
        },
        events: {
            onLeave: () => 'OnboardingBodyAgeTipClicked', // @TODO: newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
        ]
    },
    [RoutePath.BodyRisk]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 18,
                label: '',
            }
        },
        route: {
            next: () => (JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.BodyRisk]) as string)[0]) === 'none' ? RoutePath.BodyHeight : RoutePath.BodyRiskTip,
        },
        events: {
            onLeave: () => 'OnboardingRisksClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
        ]
    },
    [RoutePath.BodyRiskTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyHeight,
        },
        events: {
            onLeave: () => 'OnboardingBodyRiskTipClicked', //newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
        ]
    },
    [RoutePath.BodyHeight]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 18,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyWeight,
        },
        events: {
            onLeave: () => 'OnboardingHeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
        ]
    },
    [RoutePath.BodyWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 20,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyIdealWeight,
        },
        events: {
            onLeave: () => 'OnboardingWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
        ]
    },
    [RoutePath.BodyIdealWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 22,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.WeightLoss,
        },
        events: {
            onLeave: () => 'OnboardingGoalWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.WeightLoss]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 24,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyType,
        },
        events: {
            onLeave: () => 'OnboardingWeightLossClicked', // <-- OnboardingReinRestrictiveDietClicked
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.BodyType]: {
        customWrapper: false,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 24.5,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BloodType,
        },
        events: {
            onEnter: () => 'OnboardingBodyTypeViewed',
            onLeave: () => 'OnboardingBodyTypeClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
        ],
    },
    [RoutePath.BloodType]: {
        customWrapper: false,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 24.7,
                label: '',
            },
        },
        route: {
            next: (data: string) => data === 'do-not-know' ? RoutePath.LoseWeight : RoutePath.BloodTypeTip,
        },
        events: {
            onEnter: () => 'OnboardingBloodTypeViewed',
            onLeave: () => 'OnboardingBloodTypeClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
        ],
    },
    [RoutePath.BloodTypeTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.LoseWeight,
        },
        events: {
            onEnter: () => 'OnboardingBloodTypeTipsViewed',
            onLeave: () => 'OnboardingBloodTypeTipsClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
        ]
    },
    [RoutePath.LoseWeight]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 25,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.AverageBusy,
        },
        events: {
            onLeave: () => 'OnboardingLoseWeightClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.AverageBusy]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 26,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.AverageBusyTip,
        },
        events: {
            onLeave: () => 'OnboardingAverageBusyClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
        ]
    },
    [RoutePath.AverageBusyTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.SleepHours,
        },
        events: {
            onLeave: () => 'OnboardingAverageBusyTipClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
        ]
    },
    [RoutePath.SleepHours]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 27,
                label: ''
            }
        },
        route: {
            next: () => RoutePath.SleepHoursTip,
        },
        events: {
            onLeave: () => 'OnboardingSleepHoursClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.SleepHoursTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.AtIdealWeight,
        },
        events: {
            onLeave: () => 'OnboardingSleepHoursTipClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.AtIdealWeight]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 28,
                label: ''
            }
        },
        route: {
            next: () => RoutePath.DietActivity,
        },
        events: {
            onLeave: () => 'OnboardingAtIdealWeightClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.DietActivity]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 29,
                label: ''
            }
        },
        route: {
            next: () => RoutePath.AchieveGoal,
        },
        events: {
            onLeave: () => 'OnboardingDietActivityClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
        ]
    },
    [RoutePath.AchieveGoal]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 30,
                label: ''
            }
        },
        route: {
            next: () => RoutePath.HelpedThousands,
        },
        events: {
            onLeave: () => 'OnboardingAchieveGoalClicked', //newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.HelpedThousands]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress:  {
                percents: 31,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.Email,
        },
        events: {
            onLeave: () => 'OnboardingLoadingCompleted',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.Email]: {
        header: {
            email: false,
            logo: true,
            progress:  {
                percents: 32,
                label: '',
            },
        },
        route: {
            next: (data) => {
                if (data?.isUserExists) {
                    return RoutePath.Welcome;
                }

                return data?.hideNewsOffer ? RoutePath.GoalChart : RoutePath.NewsOffer;
            },
        },
        events: {
            onLeave: () => 'OnboardingUserEmailClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.NewsOffer]: {
        header: {
            email: true,
            logo: false,
            progress:  {
                percents: 33,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.GoalChart,
        },
        events: {
            onLeave: () => 'OnboardingNewsOffersClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.GoalChart]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 34, //@breakpoint
                label: '',
            }
        },
        route: {
            next: () => RoutePath.HealthyFridge,
        },
        events: {
            onLeave: () => 'OnboardingCustomPlanClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.HealthyFridge]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 36,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.GlassesWater,
        },
        events: {
            onLeave: () => 'OnboardingHealthyFridgeClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.GlassesWater]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 38,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.GlassesWaterTip,
        },
        events: {
            onLeave: () => 'OnboardingGlassesWaterClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.GlassesWaterTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.ConsumedItems,
        },
        events: {
            onLeave: () => 'OnboardingGlassesWaterTipClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.ConsumedItems]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 40,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.EatTimes,
        },
        events: {
            onLeave: () => 'OnboardingConsumedItemsClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.EatTimes]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 42,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.SpecificDiet,
        },
        events: {
            onLeave: () => 'OnboardingEatTimesClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.SpecificDiet]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 44,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.SpecificDietTip,
        },
        events: {
            onLeave: () => 'OnboardingSpecificDietClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.SpecificDietTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.FoodRestrictions,
        },
        events: {
            onLeave: () => 'OnboardingSpecificDietTipClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.FoodRestrictions]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 46,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.FoodRestrictionsTip,
        },
        events: {
            onLeave: () => 'OnboardingFoodRestrictionsClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.FoodRestrictionsTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.IntermittentFasting,
        },
        events: {
            onLeave: () => 'OnboardingFoodRestrictionsTipClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.IntermittentFasting]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 48,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.IntermittentFastingTip,
        },
        events: {
            onLeave: () => 'OnboardingIntermittentFastingClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.IntermittentFastingTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.TypicalDay,
        },
        events: {
            onLeave: () => 'OnboardingIntermittentFastingTipClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.TypicalDay]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 49,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WalkDaily,
        },
        events: {
            onLeave: () => 'OnboardingTypicalDayClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.WalkDaily]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 50,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WithoutWorkout,
        },
        events: {
            onLeave: () => 'OnboardingWalkDailyClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.WithoutWorkout]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 51,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WithoutWorkoutTip,
        },
        events: {
            onLeave: () => 'OnboardingWithoutWorkoutClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.WithoutWorkoutTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => localStorage.getItem(LocalStorageKeys[RoutePath.WithoutWorkout]) === 'yes' ? RoutePath.WellnessGoal : RoutePath.HowMotivated,
        },
        events: {
            onLeave: () => 'OnboardingWithoutWorkoutTipClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.WellnessGoal]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 52,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.TargetZone,
        },
        events: {
            onLeave: () => 'OnboardingWellnessGoalClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.TargetZone]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 53,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.FitnessLevel,
        },
        events: {
            onLeave: () => 'OnboardingTargetZoneClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.FitnessLevel]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 54,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WorkoutTimes,
        },
        events: {
            onLeave: () => 'OnboardingFitnessLevelClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.WorkoutTimes]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 56,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.AverageWorkoutTimes,
        },
        events: {
            onLeave: () => 'OnboardingWorkoutTimesClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.AverageWorkoutTimes]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 58,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.PhysicalLimitations,
        },
        events: {
            onLeave: () => 'OnboardingAverageWorkoutTimesClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.PhysicalLimitations]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 60,
                label: '',
            }
        },
        route: {
            next: () => (JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.PhysicalLimitations]) as string)[0]) === 'none' ? RoutePath.WorkoutType : RoutePath.PhysicalLimitationsTip,
        },
        events: {
            onLeave: () => 'OnboardingPhysicalLimitationsClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.PhysicalLimitationsTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.WorkoutType,
        },
        events: {
            onLeave: () => 'OnboardingPhysicalLimitationsTipClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.WorkoutType]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 61,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WorkoutPlace
        },
        events: {
            onLeave: () => 'OnboardingWorkoutTypeClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.WorkoutPlace]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 63,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WorkoutPlaceTip
        },
        events: {
            onLeave: () => 'OnboardingWorkoutPlaceClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.WorkoutPlaceTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.HowMotivated,
        },
        events: {
            onLeave: () => 'OnboardingWorkoutPlaceTipClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.HowMotivated]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 65,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.HitGoalChart,
        },
        events: {
            onLeave: () => 'OnboardingHowMotivatedClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.HitGoalChart]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 67, //@breakpoint
                label: '',
            }
        },
        route: {
            next: () => RoutePath.HitGoalChartTip,
        },
        events: {
            onLeave: () => 'OnboardingGoalPredictClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.HitGoalChartTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.WeightGain,
        },
        events: {
            onLeave: () => 'OnboardingHitGoalChartTipClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.WeightGain]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 69,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.PastTrials,
        },
        events: {
            onLeave: () => 'OnboardingEventsToGainClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.PastTrials]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 70,
                label: '',
            }
        },
        route: {
            next: () => (JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.PastTrials]) as string)[0]) === 'none' ? RoutePath.BehaviourChangeSelf : RoutePath.PastTrialsTip,
        },
        events: {
            onLeave: () => 'OnboardingTriedInPastClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.PastTrialsTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.BehaviourChangeSelf,
        },
        events: {
            onLeave: () => 'OnboardingTriedInPastTipClicked', // newevent
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.BehaviourChangeSelf]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 70.5,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BehaviourChangeHappyWeight,
        },
        events: {
            onEnter: () => 'OnboardingBehaviourChangeSelfViewed',
            onLeave: () => 'OnboardingBehaviourChangeSelfClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.BehaviourChangeHappyWeight]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 71,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BehaviourChangeSeeMyself,
        },
        events: {
            onEnter: () => 'OnboardingBehaviourChangeHappyWeightViewed',
            onLeave: () => 'OnboardingBehaviourChangeHappyWeightClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.BehaviourChangeSeeMyself]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 71.4,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BehaviourChangeReachWeight,
        },
        events: {
            onEnter: () => 'OnboardingBehaviourChangeSeeMySelfViewed',
            onLeave: () => 'OnboardingBehaviourChangeSeeMySelfClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.BehaviourChangeReachWeight]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 71.6,
                label: '',
            }
        },
        route: {
            next: (data: string) => data === 'insure' ? RoutePath.BehaviourChangeHitGoal : RoutePath.BehaviourChangeImportance,
        },
        events: {
            onEnter: () => 'OnboardingBehaviourChangeReachWeightViewed',
            onLeave: () => 'OnboardingBehaviourChangeReachWeightClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.BehaviourChangeImportance]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 71.8,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BehaviourChangeTip,
        },
        events: {
            onEnter: () => 'OnboardingBehaviourChangeImportanceViewed',
            onLeave: () => 'OnboardingBehaviourChangeImportanceClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.BehaviourChangeHitGoal]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 71.8,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BehaviourChangeTip,
        },
        events: {
            onEnter: () => 'OnboardingBehaviourChangeHitGoalViewed',
            onLeave: () => 'OnboardingBehaviourChangeHitGoalClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.BehaviourChangeTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.FitClothesRating,
        },
        events: {
            onEnter: () => 'OnboardingBehaviourChangeTipsViewed',
            onLeave: () => 'OnboardingBehaviourChangeTipsClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.FitClothesRating]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 72,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.LookSatisfactionRating,
        },
        events: {
            onLeave: () => 'OnboardingFitClothesRatingClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.LookSatisfactionRating]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 74,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.PeopleRelationshipRating,
        },
        events: {
            onLeave: () => 'OnboardingLookSatisfactionRatingClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.PeopleRelationshipRating]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 76,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.MentalHealthRating,
        },
        events: {
            onLeave: () => 'OnboardingPeopleRelationshipRatingClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.MentalHealthRating]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 78,
                label: '',
            }
        },
        route: {
            next: () => getUserBmi() >= 33 ? RoutePath.PublicPlacesRating : RoutePath.FeelFailureRating,
        },
        events: {
            onLeave: () => 'OnboardingMentalHealthRatingClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.PublicPlacesRating]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 80,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.EnjoyLifeRating,
        },
        events: {
            onLeave: () => 'OnboardingPublicPlacesRatingClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.EnjoyLifeRating]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 82,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.CareerOpportunitiesRating,
        },
        events: {
            onLeave: () => 'OnboardingEnjoyLifeRatingClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.CareerOpportunitiesRating]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 84,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.FeelFailureRating,
        },
        events: {
            onLeave: () => 'OnboardingCareerOpportunitiesRatingClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.FeelFailureRating]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 86,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.OldHabitsRating,
        },
        events: {
            onLeave: () => 'OnboardingFeelFailureRatingClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.OldHabitsRating]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 88,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.PlanFits,
        },
        events: {
            onLeave: () => 'OnboardingOldHabitsRatingClicked', // renamed
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.PlanFits]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 90,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.ScientificProof,
        },
        events: {
            onLeave: () => 'OnboardingRelatePlanFitsClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.ScientificProof]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.ImportantEvent,
        },
        events: {
            onEnter: () => 'OnboardingYoYoScientificProofViewed',
            onLeave: () => 'OnboardingYoYoScientificProofClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email]
        ]
    },
    [RoutePath.ImportantEvent]: {
        customWrapper: false,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 94,
                label: '',
            },
        },
        route: {
            next: (data: any) => {
                if (data.key !== 'none') {
                    return RoutePath.ImportantEventDate;
                } else {
                    return RoutePath.PlanLoader;
                }
            }
        },
        events: {
            onLeave: () => 'OnboardingImportantEventClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email]
        ],
    },
    [RoutePath.ImportantEventDate]: {
        customWrapper: false,
        header: {
            email: true,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.PlanLoader,
        },
        events: {},
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email]
        ]
    },
    [RoutePath.PlanLoader]: {
        customWrapper: true,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.PersonalizedProgram,
        },
        events: {
            onEnter: () => 'OnboardingPlanLoaderViewed',
            onLeave: () => 'OnboardingPlanLoaderCompleted',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.PersonalizedProgram]: {
        customWrapper: true,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.YouAreAble,
        },
        events: {
            onEnter: () => 'OnboardingFinisherStarterViewed',
            onLeave: () => 'OnboardingFinisher4Viewed',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.YouAreAble]: {
        customWrapper: true,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.HitGoalChartImportantEvent,
        },
        events: {
            onLeave: () => 'OnboardingFinisherCompletedViewed',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.HitGoalChartImportantEvent]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => localStorage.getItem(RoutePath.TrialPay.slice(1)) === 'no' ? RoutePath.Paywall : RoutePath.TrialPay
        },
        events: {
            onLeave: () => 'OnboardingGoalPredictWithImportantEventClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.TrialPay]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.Paywall,
        },
        events: {
            onLeave: () => 'OnboardingTrialPicker',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.Paywall]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.UpgradePlan,
        },
        events: {
            onEnter: () => 'OnboardingPaywallViewed',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
            [LocalStorageKeys[RoutePath.TrialPay], EnchargePosition, RoutePath.TrialPay.slice(1)]
        ],
        footerAtTheBottom: true
    },
    [RoutePath.UpgradePlan]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Congrats,
        },
        events: {},
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem, LocalStorageKeys[RoutePath.UpgradePlan]], // User went through funnel or direct redirect
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            CUSTOMER_ID,
        ]
    },
    [RoutePath.Congrats]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Welcome,
        },
        events: {
            onEnter: () => 'OnboardingCongratsViewed',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email]
        ]
    },
    [RoutePath.Welcome]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Welcome,
        },
        events: {
            onLeave: () => 'OnboardingDownloadButtonClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email]
        ]
    },
    [RoutePath.Upgrade]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.UpgradeTip,
        },
        events: {},
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email],
            [CUSTOMER_ID, EnchargePosition],
            CUSTOMER_ID,
        ]
    },
    [RoutePath.UpgradeTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.Welcome,
        },
        events: {
            onLeave: () => 'EBookUpsellWrapUpClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email],
            [CUSTOMER_ID, EnchargePosition],
            CUSTOMER_ID,
        ]
    }
};
