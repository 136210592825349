import React from 'react';

import { TipPage } from 'components/tip-page';

export const AverageBusyTip = () => {

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/clock.svg'
            text={{
                description: 'average-busy-tip-text',
                next: 'average-busy-tip-button-next'
            }}
        />
    );
};
