import React from 'react';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';

export const SleepHoursTip = () => {

    const description = ['4-less-hours', '5-6-hours'].includes(localStorage.getItem(LocalStorageKeys[RoutePath.SleepHours]) as string)
        ? 'sleep-hours-tip-few-description'
        : 'sleep-hours-tip-normal-description';

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/moon.svg'
            text={{
                description: description,
                next: 'sleep-hours-tip-button-next'
            }}
        />
    );
};
