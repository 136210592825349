import { BanPrepaidCardsKeys } from 'constants/payment';

export const getPrice = (price: number) => {
    return price / 100;
};

export const addZeroes = (trialPriceValue: string) => {
    if (!trialPriceValue.includes('.')) {
        return '.00';
    }

    if (trialPriceValue.includes('.') && trialPriceValue.indexOf('.') === trialPriceValue.length - 2) {
        return '0';
    }

    return '';
};

export const getPaymentGeneralErrorKey = (error?: string) => {
    const prepaidBanExperiment = localStorage.getItem(BanPrepaidCardsKeys.banPrepaidCardsEnabled) === 'true' && localStorage.getItem(BanPrepaidCardsKeys.banPrepaidCards) === 'true';

    if (prepaidBanExperiment) {
        return 'payment-general-error-prepaid-card-ban-experiment';
    }

    if (error) {
        return error;
    }

    return 'payment-general-error';
};
