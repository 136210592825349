import React from 'react';

import { TipPage } from 'components/tip-page';

export const HitGoalChartTip = () => {
    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/brain.svg'
            text={{
                description: 'hit-goal-chart-tip-title',
                next: 'hit-goal-chart-tip-button-next'
            }}
        />
    );
};
