import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    footer: {
        position: 'relative',
        width: '100%',
        left: 0,
        right: 0,
        bottom: 0,
    },
    footerContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
        letterSpacing: '-0.2px',
        padding: '32px 16px 16px',
        color: '#999999',

        '& a': {
            color: '#999999',
            textDecoration: 'none'
        }
    },
    contentBlock: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    companyInfo: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '8px'
    },
    '@media only screen and (min-width: 768px)' : {
        footerContent: {
            fontSize: '14px',
            padding: '48px 16px 32px',
        },
        contentBlock: {
            width: '100%'
        },
        companyInfo: {
            gap: '16px'
        }
    }
});
