import React, { FC } from 'react';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { BreadcrumbsNavigationKeys } from 'constants/breadcrumbs-navigation';

import { Typography } from 'components/typography';

import { TypographyVariants } from 'constants/typography-variants';

import { useStyles } from './styles';

const IMG_PATHS = [
    'svg/breadcrumbs-navigation/navigation-icon-down.svg',
    'svg/breadcrumbs-navigation/check-icon.svg',
];

const SECTIONS = [1, 2, 3, 4];
const SECTION_NAMES = [
    BreadcrumbsNavigationKeys.healthPlan,
    BreadcrumbsNavigationKeys.addToPlan,
    BreadcrumbsNavigationKeys.downloadApp,
    BreadcrumbsNavigationKeys.logIn,
];

interface BreadcrumbsNavigationProps {
    activeSection: string;
    completedSections: string[];
}

export const BreadcrumbsNavigation: FC<BreadcrumbsNavigationProps> = ({
                                                                                              activeSection,
                                                                                              completedSections,
                                                                                          }) => {
    const {
        navWrapper,
        sectionsList,
        listItem,
        image,
        shown,
        sectionName,
        sectionNameActive,
        active,
    } = useStyles();

    const pageImages = useBackgroundImageService(IMG_PATHS);

    return (
        <nav className={navWrapper}>
            <ol className={sectionsList}>
                {SECTIONS.map((section, index) => {
                    const isSectionActive = activeSection === SECTION_NAMES[index];
                    const isSectionCompleted = completedSections.includes(SECTION_NAMES[index]);

                    return (
                        <div key={section}>
                            <img className={`${image} ${isSectionActive && shown}`} src={pageImages[0]}
                                 alt='Arrow Down' />
                            <li className={`${listItem} ${(isSectionActive || (isSectionCompleted && !isSectionActive)) && active}`}>
                                {isSectionCompleted && !isSectionActive ?
                                    <img src={pageImages[1]} alt='Check' />
                                    :
                                    <Typography variant={TypographyVariants.h3}>
                                        {section}
                                    </Typography>
                                }
                            </li>
                            <Typography className={`${sectionName} ${isSectionActive && sectionNameActive}`}
                                        variant={TypographyVariants.h3}>
                                {SECTION_NAMES[index]}
                            </Typography>
                        </div>
                    );
                })}
            </ol>
        </nav>
    );
};
