import { RoutePath } from 'routes/route-path.constant';

export const LocalStorageKeys = {
    [RoutePath.MainGoal]: 'goal',
    [RoutePath.BodyGender]: 'gender',
    [RoutePath.BodyBreastfeeding]: 'isBreastfeeding',
    [RoutePath.BodyAge]: 'age',
    [RoutePath.BodyRisk]: 'body-risk',
    [RoutePath.BodyIdealWeight]: 'weightGoal',
    [RoutePath.LoseWeight]: 'lose-weight',
    [RoutePath.AverageBusy]: 'average-busy',
    [RoutePath.SleepHours]: 'sleep-hours',
    [RoutePath.AtIdealWeight]: 'at-ideal-weight',
    [RoutePath.DietActivity]: 'diet-activity',
    [RoutePath.AchieveGoal]: 'achieve-goal',
    [RoutePath.Email]: 'email',
    [RoutePath.NewsOffer]: 'news-offer',
    [RoutePath.HealthyFridge]: 'healthy-fridge',
    [RoutePath.GlassesWater]: 'glasses-water',
    [RoutePath.ConsumedItems]: 'consumed-items',
    [RoutePath.EatTimes]: 'eat-times',
    [RoutePath.SpecificDiet]: 'specific-diet',
    [RoutePath.FoodRestrictions]: 'food-restrictions',
    [RoutePath.IntermittentFasting]: 'intermittent-fasting',
    [RoutePath.TypicalDay]: 'activity',
    [RoutePath.WalkDaily]: 'walk-daily',
    [RoutePath.WithoutWorkout]: 'without-workout',
    [RoutePath.WellnessGoal]: 'wellness-goal',
    [RoutePath.TargetZone]: 'target-zone',
    [RoutePath.FitnessLevel]: 'fitness-level',
    [RoutePath.WorkoutTimes]: 'workout-times',
    [RoutePath.AverageWorkoutTimes]: 'average-workout-times',
    [RoutePath.PhysicalLimitations]: 'physical-limitations',
    [RoutePath.WorkoutType]: 'workout-type',
    [RoutePath.WorkoutPlace]: 'workout-place',
    [RoutePath.HowMotivated]: 'how-motivated',
    [RoutePath.WeightGain]: 'weight-gain',
    [RoutePath.PastTrials]: 'past-trials',
    [RoutePath.BehaviourChangeSelf]: 'behaviour-change-self',
    [RoutePath.BehaviourChangeHappyWeight]: 'behaviour-change-happy-weight',
    [RoutePath.BehaviourChangeSeeMyself]: 'behaviour-change-see-myself',
    [RoutePath.BehaviourChangeReachWeight]: 'behaviour-change-reach-weight',
    [RoutePath.BehaviourChangeImportance]: 'behaviour-change-importance',
    [RoutePath.BehaviourChangeHitGoal]: 'behaviour-change-hit-goal',
    [RoutePath.FitClothesRating]: 'fit-clothes-rating',
    [RoutePath.LookSatisfactionRating]: 'look-satisfaction-rating',
    [RoutePath.PeopleRelationshipRating]: 'people-relationship-rating',
    [RoutePath.MentalHealthRating]: 'mental-health-rating',
    [RoutePath.PublicPlacesRating]: 'public-places-rating',
    [RoutePath.EnjoyLifeRating]: 'enjoy-life-rating',
    [RoutePath.CareerOpportunitiesRating]: 'career-opportunities-rating',
    [RoutePath.FeelFailureRating]: 'feel-failure-rating',
    [RoutePath.OldHabitsRating]: 'old-habits-rating',
    [RoutePath.PlanFits]: 'plan-fits',
    [RoutePath.TrialPay]: 'planCode',
    [RoutePath.BodyType]: 'body-type',
    [RoutePath.BloodType]: 'blood-type',

    [RoutePath.ImportantEvent]: 'important-event',
    [RoutePath.ImportantEventDate]: 'important-event-date',
    [RoutePath.UpgradePlan]: 'newplan',
    [RoutePath.Upgrade]: 'isBoughtEbook',

    [RoutePath.PersonalityQuizEnjoy]: 'personality-quiz-enjoy',
    [RoutePath.PersonalityQuizFocus]: 'personality-quiz-focus',
    [RoutePath.PersonalityQuizMakeDecisions]: 'personality-quiz-make-decisions',
    [RoutePath.PersonalityQuizPrefer]: 'personality-quiz-prefer',
};

export const MultipleLocalStorageKeys = {
    bodyHeightWeightKeys: {
        unitSystem: 'body-height-weight-unitSystem',
        heightCm: 'body-height-cm',
        heightFt: 'body-height-ft',
        heightInches: 'body-height-inches',
        heightOnlyInches: 'body-height-only-inches',
        weightLbs: 'body-weight-lbs',
        weightKg: 'body-weight-kg'
    },
};
