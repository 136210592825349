import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { useStyles } from './styles';

interface InfoBlockProps {
    imgSrc: string;
    titleKey?: string;
    descriptionKey: string;
    bgColor?: string;
    iconBgColor?: string;
    descriptionDynamicProps?: {};
}

export const InfoBlock: FC<InfoBlockProps> = ({ imgSrc, titleKey, descriptionKey, bgColor, iconBgColor, descriptionDynamicProps }): JSX.Element => {
    const { infoBlock, iconWrapper, infoBlockTitle  } = useStyles({ background: bgColor, iconBackground: iconBgColor });

    const items = [imgSrc];
    const pageImages = useBackgroundImageService(items);

    return (
        <div className={infoBlock}>
            <div className={iconWrapper}>
                <img src={pageImages[0]} alt='Icon' />
            </div>
            <div>
                {titleKey && <Typography className={infoBlockTitle} variant={TypographyVariants.h2} typographyNext>
                    <Trans i18nKey={titleKey} />
                </Typography>}
                <Typography variant={TypographyVariants.h3}>
                    <Trans i18nKey={descriptionKey} values={descriptionDynamicProps} />
                </Typography>
            </div>
        </div>
    );
};
