import React, { useContext, useEffect } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

import { useStyles } from './styles';

export const BodyGender = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();
    const { pageConfiguration } = useContext(BaseContext);

    const { choiceButtonWrapper, choiceButton, choiceButtonSelected } = useStyles();

    const { pageValue: bodyGender, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyGender],
        defaultValue: '',
    });

    const handleClick = (gender: ChoiceType) => {
        saveAnswers({
            key: 'body-gender',
            itemQuestion: { question: t('body-gender-title'), value: t(gender.value) },
        });
        setPageValue(gender.key);

        pageConfiguration.handleOnPageComplete(gender.key);
    };

    const genders: ChoiceType[] = [
        { key: 'female', value: t('body-gender-option-female'), image: 'svg/body-gender/female-gender.svg' },
        { key: 'male', value: t('body-gender-option-male'), image: 'svg/body-gender/male-gender.svg' },
    ];

    const wl_questions_update = useFeatureIsOn(ExperimentsKeys.wl_questions_update);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.wl_questions_update, wl_questions_update ? '1' : '0');
    }, [wl_questions_update]);

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                <Trans i18nKey='body-gender-title' />
            </Typography>

            <Typography variant={TypographyVariants.h2}>
                <Trans i18nKey='body-gender-description' />
            </Typography>

            <div className={choiceButtonWrapper}>
                {genders.map((v: ChoiceType) => (
                    <ChoiceButton
                        className={`${choiceButton} ${(v.key === bodyGender) && choiceButtonSelected}`}
                        item={v}
                        key={v.key}
                        center={true}
                        typographyText={v.value}
                        selected={v.key === bodyGender}
                        onClick={() => handleClick(v)}
                    />
                ))}
            </div>
        </>
    );
};
