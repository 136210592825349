import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'translations/i18n';

import { ValidatedInput } from '../../validated-input';

import { lbsValidationQuery } from './lbs-validation-query';

const LABEL = i18n.t('lb');

type LbsInputProps = {
    value: string;
    onChange: (e: any) => void;
    onError: (error: boolean, key: string) => void;
    lessThen?: number;
    autoFocus?: boolean;
    withEndAdornment?: boolean;
};

export const LbsInput: FC<LbsInputProps> = ({
    value,
    onChange,
    onError,
    lessThen,
    autoFocus,
    withEndAdornment = true,
}) => {
    const { t } = useTranslation();

    const props = useMemo(() => ({ label: t('weight'), maxLength: 3 }), []);

    const handleError = (key: string) => (error: boolean) => onError(error, key);

    useEffect(() => () => onError(false, LABEL), []);

    const endAdornmentLabel = withEndAdornment ? LABEL : '';

    // @TODO: Write it more elegant (for eg. custom validation query);
    const validationQuery = JSON.parse(JSON.stringify(lbsValidationQuery));
    if (lessThen && lessThen < validationQuery.type.lessThen.value) {
        validationQuery.type.lessThen.value = lessThen;
        validationQuery.type.lessThen.errorMessage = t('weight-input-error');
    }

    return (
        <ValidatedInput
            type="number"
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            onError={handleError(LABEL)}
            validationQuery={validationQuery}
            endAdornment={endAdornmentLabel}
            {...props}
        />
    );
};
