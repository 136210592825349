export const RoutePath = {
    MainGoal: '/',
    BodyGender: '/body-gender',
    BodyBreastfeeding: '/body-breastfeeding',
    BodyAge: '/body-age',
    BodyAgeTip: '/body-age-tip', // new
    BodyRisk: '/body-risk',
    BodyRiskTip: '/body-risk-tip', // renamed from reduce-risks
    BodyHeight: '/body-height',
    BodyWeight: '/body-weight',
    WeightMetabolicRate: '/weight-metabolic-rate',
    BodyIdealWeight: '/body-ideal-weight',
    WeightLoss: '/weight-loss',
    BodyType: '/body-type',
    BloodType: '/blood-type',
    BloodTypeTip: '/blood-type-tip',
    LoseWeight: '/lose-weight',
    AverageBusy: '/average-busy',
    AverageBusyTip: '/average-busy-tip',
    SleepHours: '/sleep-hours',
    SleepHoursTip: '/sleep-hours-tip',
    AtIdealWeight: '/at-ideal-weight', // new
    DietActivity: '/diet-activity',
    AchieveGoal: '/achieve-goal',
    HelpedThousands: '/helped-thousands',
    Email: '/email',
    NewsOffer: '/news-offer',
    GoalChart: '/goal-chart',
    HealthyFridge: '/healthy-fridge',
    GlassesWater: '/glasses-water',
    GlassesWaterTip: '/glasses-water-tip',
    ConsumedItems: '/consumed-items',
    EatTimes: '/eat-times',
    SpecificDiet: '/specific-diet',
    SpecificDietTip: '/specific-diet-tip',
    FoodRestrictions: '/food-restrictions',
    FoodRestrictionsTip: '/food-restrictions-tip',
    IntermittentFasting: '/intermittent-fasting',
    IntermittentFastingTip: '/intermittent-fasting-tip',
    TypicalDay: '/typical-day',
    WalkDaily: '/walk-day',
    WithoutWorkout: '/without-workout',
    WithoutWorkoutTip: '/without-workout-tip',
    WellnessGoal: '/wellness-goal',
    TargetZone: '/target-zone',
    FitnessLevel: '/fitness-level',
    WorkoutTimes: '/workout-times',
    AverageWorkoutTimes: '/average-workout-times',
    PhysicalLimitations: '/physical-limitations',
    PhysicalLimitationsTip: '/physical-limitations-tip',
    WorkoutType: '/workout-type',
    WorkoutPlace: '/workout-place',
    WorkoutPlaceTip: '/workout-place-tip',
    HowMotivated: '/how-motivated',
    HitGoalChart: '/hit-goal-chart',
    HitGoalChartTip: '/hit-goal-chart-tip',
    WeightGain: '/weight-gain',
    PastTrials: '/past-trials',
    PastTrialsTip: '/past-trials-tip',
    BehaviourChangeSelf: '/behaviour-change-self',
    BehaviourChangeHappyWeight: '/behaviour-change-happy-weight',
    BehaviourChangeSeeMyself: '/behaviour-change-see-myself',
    BehaviourChangeReachWeight: '/behaviour-change-reach-weight',
    BehaviourChangeImportance: '/behaviour-change-importance',
    BehaviourChangeHitGoal: '/behaviour-change-hit-goal',
    BehaviourChangeTip: '/behaviour-change-tip',
    FitClothesRating: '/fit-clothes-rating',
    LookSatisfactionRating: '/look-satisfaction-rating',
    PeopleRelationshipRating: '/people-relationship-rating',
    MentalHealthRating: '/mental-health-rating',
    PublicPlacesRating: '/public-places-rating',
    EnjoyLifeRating: '/enjoy-life-rating',
    CareerOpportunitiesRating: '/career-opportunities-rating',
    FeelFailureRating: '/feel-failure-rating',
    OldHabitsRating: '/old-habits-rating',
    PlanFits: '/plan-fits',
    ScientificProof: '/scientific-proof',
    PlanLoader: '/plan-loader',
    PersonalizedProgram: '/personalized-program',
    YouAreAble: '/you-are-able',
    TrialPay: '/trial-pay',
    Paywall: '/paywall',
    UpgradePlan: '/upgrade-plan',
    Congrats: '/congrats',
    Welcome: '/welcome',
    Goal: '/goal',
    GoalWithProgressBar: '/goal-with-progress-bar',

    ImportantEvent: '/important-event',
    ImportantEventDate: '/important-event-date',
    ImportantEventDateTip: '/important-event-date-tip',
    HitGoalChartImportantEvent: '/hit-goal-chart-important-event',

    Upgrade: '/upgrade',
    UpgradeTip: '/upgrade-tip',

    // @TODO: Will be tested later
    AbleProgram: '/able-program',

    PersonalityQuiz: '/personality-quiz',
    PersonalityQuizEnjoy: '/personality-quiz-enjoy',
    PersonalityQuizFocus: '/personality-quiz-focus',
    PersonalityQuizMakeDecisions: '/personality-quiz-make-decisions',
    PersonalityQuizPrefer: '/personality-quiz-prefer',
    PersonalityQuizLoading: '/personality-quiz-loading',
    PersonalityQuizResults: '/personality-quiz-results',
};
