import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { UserGeolocationKeys } from 'constants/user-geolocation';
import { UNIT_SYSTEM } from 'constants/body-height-weight';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { getFtAndInchesFromInches } from 'helpers/unit-converter';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { BodyHeightWeightInputs } from 'components/body-height-weight-inputs';

import { HeightRange } from 'pages/body-height/height-range';
import { InfoBlock } from 'pages/paywall/info-block';

import { useExperimentStyles } from './styles';

const convertDataForIntakeForm = (data: { unit: string, height: string }) => {
    return `Height: ${data.height} ${data.unit === UNIT_SYSTEM.metric ? 'cm' : 'ft/in'}`;
};

export const BodyHeight = () => {
    const { noHeightButton } = useExperimentStyles();

    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const [pageError, setPageError] = useState(false);

    const [disabledButtonState, setDisabledButtonState] = useState(true);
    const [errors, setError] = useState({});

    const wl_first_screens_agency_experiment = localStorage.getItem(ExperimentsKeys.wl_first_screens_agency_experiment) === '1';

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);
    const defaultHeightCm = userGender === 'female' ? '167' : '173';
    const defaultHeightInches = userGender === 'female' ? '63' : '68';

    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;
    const countryCode = localStorage.getItem(UserGeolocationKeys.countryCode) as string;

    const { pageValue: bodyHeightWeightUnitSystem } =
        useLocalStorage({
            key: bodyHeightWeightKeys.unitSystem,
            defaultValue: countryCode === 'US' || countryCode === 'CA' ? UNIT_SYSTEM.imperial : UNIT_SYSTEM.metric,
        });

    const { pageValue: bodyHeightCm, setPageValue: setBodyHeightCm } = useLocalStorage({
        key: bodyHeightWeightKeys.heightCm,
        defaultValue: wl_first_screens_agency_experiment ? defaultHeightCm : '',
    });

    const { pageValue: bodyHeightFt, setPageValue: setBodyHeightFt } = useLocalStorage({
        key: bodyHeightWeightKeys.heightFt,
        defaultValue: '',
    });

    const { pageValue: bodyHeightInches, setPageValue: setBodyHeightInches } = useLocalStorage({
        key: bodyHeightWeightKeys.heightInches,
        defaultValue: '',
    });

    const { pageValue: bodyHeightOnlyInches, setPageValue: setBodyHeightOnlyInches } = useLocalStorage({
        key: bodyHeightWeightKeys.heightOnlyInches,
        defaultValue: defaultHeightInches,
    });

    const handleClick = () => {
        if (disabledButtonState) {
            return;
        }

        const data = {
            unit: bodyHeightWeightUnitSystem,
            height:
                bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial
                    ? `${bodyHeightFt}.${bodyHeightInches}`
                    : bodyHeightCm
        };

        saveAnswers({
            key: 'body-height',
            itemQuestion: { question: t('body-height-title'), value: convertDataForIntakeForm(data) },
        });
        pageConfiguration.handleOnPageComplete(data);
    };

    const handleNoHeightClick = () => {
        setBodyHeightCm(defaultHeightCm);
        const { ft, in_ } = getFtAndInchesFromInches(Number(defaultHeightInches));
        setBodyHeightFt(ft);
        setBodyHeightInches(in_);
        setBodyHeightOnlyInches(defaultHeightInches);

        handleClick();
    };

    useEffect(() => {

        setPageError(() => {
            // @ts-ignore
            return Object.keys(errors).some((key) => errors[key]);
        });

    }, [JSON.stringify(errors)]);

    useEffect(() => {
        const btnState = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial
            ? pageError || !bodyHeightFt
            : pageError || !bodyHeightCm;

        setDisabledButtonState(btnState);

    }, [
        bodyHeightWeightUnitSystem,
        bodyHeightFt,
        bodyHeightCm,
        pageError
    ]);

    const defaultHeightInFtAndInches = useMemo(() => getFtAndInchesFromInches(Number(defaultHeightInches)), [defaultHeightInches]);
    const userDefaultHeight = useMemo(() => {
        return bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial
            ? `${defaultHeightInFtAndInches.ft} ${t('ft')} ${defaultHeightInFtAndInches.in_} ${t('in')}`
            : `${defaultHeightCm} ${t('cm')}`;
    }, [bodyHeightWeightUnitSystem, defaultHeightInFtAndInches, defaultHeightCm]);
    const userGenderText = useMemo(() => {
        return userGender === 'female' ? t('women').toLowerCase() : t('men').toLowerCase();
    }, [userGender]);

    const wl_metabolic_rate_screen = useFeatureIsOn(ExperimentsKeys.wl_metabolic_rate_screen);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.wl_metabolic_rate_screen, wl_metabolic_rate_screen ? '1' : '0');
    }, [wl_metabolic_rate_screen]);

    return (
        <>
            <Typography variant={TypographyVariants.h1}
                        typographyNext={wl_first_screens_agency_experiment}>{t('body-height-title')}</Typography>

            {wl_first_screens_agency_experiment &&
                <Typography variant={TypographyVariants.h2}>{t('body-height-subtitle')}</Typography>}

            {wl_first_screens_agency_experiment &&
                <HeightRange bodyHeightWeightUnitSystem={bodyHeightWeightUnitSystem}
                             bodyHeightCm={bodyHeightCm}
                             bodyHeightFt={bodyHeightFt}
                             bodyHeightInches={bodyHeightInches}
                             bodyHeightOnlyInches={bodyHeightOnlyInches}
                             setBodyHeightCm={setBodyHeightCm}
                             setBodyHeightFt={setBodyHeightFt}
                             setBodyHeightInches={setBodyHeightInches}
                             setBodyHeightOnlyInches={setBodyHeightOnlyInches} />
            }

            <BodyHeightWeightInputs heightInput idealWeightInput={false} setInputError={setError}
                                    pageError={pageError} />

            <NextButton onClick={handleClick} disabled={disabledButtonState} />
            {wl_first_screens_agency_experiment &&
                <NextButton className={noHeightButton}
                            typographyText={t('body-height-no-height-button-next')}
                            onClick={handleNoHeightClick} />
            }

            {wl_first_screens_agency_experiment && <InfoBlock
                            imgSrc={'svg/info-block-icons/commas.svg'}
                            descriptionKey={'body-height-info-block-description'}
                            bgColor={'#F6F6F6'}
                            descriptionDynamicProps={{ userGenderText, userDefaultHeight }} />
            }
        </>
    );
};
