import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useBodyPlanData } from 'hooks/use-body-plan-data.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const BehaviourChangeReachWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.BehaviourChangeReachWeight];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const answers: ChoiceType[] = [
        {
            key: 'can-do-it',
            value: t('behaviour-change-reach-weight-option-can-do-it'),
        },
        {
            key: 'uncertain-but-willing',
            value: t('behaviour-change-reach-weight-option-uncertain-but-willing'),
        },
        {
            key: 'insure',
            value: t('behaviour-change-reach-weight-option-insure'),
        },
    ];

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('behaviour-change-reach-weight-title'), value: t(answer.value) },
        });

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    const {
        earlierAdjustedGoalWeight,
        earlierLossDay,
        earlierLossMonth,
    } = useBodyPlanData();

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext>
                {t('behaviour-change-reach-weight-title', {
                    goalWeight: earlierAdjustedGoalWeight,
                    finalDate: `${earlierLossDay} ${earlierLossMonth}`,
                })}
            </Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === pageValue}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
