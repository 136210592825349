import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFeatureValue } from '@growthbook/growthbook-react';

import { RatingScaleWrapper, RatingButtonsScaleWrapper, RatingButton } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';
import { PLANCODE_PRIMERIO } from 'constants/trial-pay';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useLocalPlans } from 'hooks/use-local-plans.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import pointingArrow from 'assets/images/svg/trial-pay/pointing-arrow.svg';

import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';

import { useStyles } from './styles';

export const TrialPay = () => {
    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    const { t } = useTranslation();

    const rounded = localStorage.getItem(ExperimentsKeys.wl_price_rounding) as string;

    const plans = useLocalPlans(PLANCODE_PRIMERIO, rounded).plans;

    const {
        pageWrapper,
        title,
        paragraph,
        lastParagraph,
        ratingButton,
        ratingButtonText,
        supportOptionDescriptionWrapper,
        smallerBottomIndent,
        supportOption,
        arrowImage,
        bestSupportOption,
        nextButton,
    } = useStyles({ longLabel: plans[0].currencyLabel.length >= 3 });

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.TrialPay],
        defaultValue: {},
    });

    window.document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F6F6F6');

    const handleClick = () => {
        const priceInBasicUnit = pageValue.price / 100;
        const priceForAnalytics = Number.isInteger(priceInBasicUnit) ?
            String(priceInBasicUnit) :
            String(priceInBasicUnit.toFixed(2));

        pageConfiguration.handleOnPageComplete({ currency: pageValue.currency, trial_price: priceForAnalytics });
    };

    const isBestTrialOptionChosen = (plans && pageValue.trialAmount === plans[plans.length - 1]?.trialAmount) || false;

    const wl_paywall_short_versions = useFeatureValue(
        ExperimentsKeys.wl_paywall_short_versions,
        '0',
    );

    useEffect(() => {
        localStorage.setItem(
            ExperimentsKeys.wl_paywall_short_versions,
            wl_paywall_short_versions,
        );
    }, [wl_paywall_short_versions]);

    return (
        <div className={pageWrapper}>
            <Typography variant={TypographyVariants.h1} typographyNext className={title}>
                {t('trial-pay-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2} className={paragraph}>
                {t('trial-pay-helped-thousands-paragraph')}
            </Typography>

            <Typography variant={TypographyVariants.h2} className={paragraph}>
                {t('trial-pay-money-reason-paragraph')}
            </Typography>

            <Typography variant={TypographyVariants.h2} className={`${paragraph} ${lastParagraph}`}>
                <Trans i18nKey='trial-pay-able-costs-paragraph' />
            </Typography>

            <RatingScaleWrapper>
                <Typography variant={TypographyVariants.h1} className={title}>
                    {t('trial-pay-subtitle')}
                </Typography>

                <RatingButtonsScaleWrapper>
                    {
                        plans?.map((plan: any) => {
                            return <div key={plan.trialAmount}>
                                <RatingButton
                                    rating={{ ...plan, label: `${plan.currencyLabel}${plan.trialAmount / 100}` }}
                                    selected={plan.trialAmount === pageValue?.trialAmount}
                                    buttonClass={ratingButton}
                                    buttonTextClass={ratingButtonText}
                                    handleClick={() => {
                                        setPageValue(plan);
                                    }}
                                />
                            </div>
                        })
                    }
                </RatingButtonsScaleWrapper>

                <div className={`${supportOptionDescriptionWrapper} ${isBestTrialOptionChosen && smallerBottomIndent}`}>
                    <Typography variant={TypographyVariants.h3} className={supportOption}>
                        {t('trial-pay-support-option-text')}
                    </Typography>
                    <img src={pointingArrow} className={arrowImage} alt='Arrow' />
                </div>

                {isBestTrialOptionChosen && <Typography variant={TypographyVariants.h3} className={bestSupportOption}>
                    {t('trial-pay-best-support-option-text')}
                </Typography>}
            </RatingScaleWrapper>

            <NextButton className={nextButton} onClick={() => handleClick()} disabled={!pageValue.key} />
        </div>
    );
};
