import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';

import { getUnitSystem, UNIT_SYSTEM } from 'constants/body-height-weight';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { NextButton } from 'components/next-button';
import { Typography } from 'components/typography';
import { ModalWindow } from 'components/modal-window';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { useStyles } from './styles';

interface SkipTrialModalWindowProps {
    showSkipTrialModalWindow: boolean;
    monthsCountToReachGoalWeight: number;
    adjustedGoalWeight: number;
    subscriptionPeriod: string;
    handleSkipClick: () => void;
    handleUpgradeClick: () => void;
}

export const SkipTrialModalWindow: FC<SkipTrialModalWindowProps> = (
    { showSkipTrialModalWindow, monthsCountToReachGoalWeight, adjustedGoalWeight, subscriptionPeriod, handleSkipClick, handleUpgradeClick }
    ): JSX.Element => {
    const { t } = useTranslation();

    const { modalWrapper, modal, title, boldText, subtitle, listItemWrapper, listItemIcon, listItemDescription, infoWrapper, infoText, buttonsWrapper, skipButtonWrapper, skipButton, startButtonWrapper, startButton } = useStyles();

    const items = [
        'svg/upgrade-plan/skip-trial-modal-window/weights.svg', 'svg/upgrade-plan/skip-trial-modal-window/coach.svg',
        'svg/upgrade-plan/skip-trial-modal-window/cutlery.svg', 'svg/upgrade-plan/skip-trial-modal-window/graph.svg'
    ];
    const pageImages = useBackgroundImageService(items);

    const exercisePlanAnswer = localStorage.getItem(LocalStorageKeys[RoutePath.WithoutWorkout]);
    const exercisePlan = exercisePlanAnswer === 'yes' ? t('upgrade-plan-modal-what-you-will-get-list-item-exercise-text') : null;
    const unitSystem = getUnitSystem();
    const unit = unitSystem === UNIT_SYSTEM.metric ? 'kg' : 'lb';
    const subscriptionPeriodValue = subscriptionPeriod.charAt(0).toUpperCase() + subscriptionPeriod.slice(1);

    return (
        <>
        {showSkipTrialModalWindow &&
            <ModalWindow wrapperClassName={modalWrapper} modalWindowFormClassName={modal} hasCloseIcon={false}>
                <div>
                    <Typography variant={TypographyVariants.h2} className={title}>
                        <Trans i18nKey='upgrade-plan-skip-trial-modal-window-title' components={[<em className={boldText}></em>]}/>
                    </Typography>

                    <Typography variant={TypographyVariants.h2} className={subtitle}>
                        <Trans i18nKey='upgrade-plan-skip-trial-modal-window-subtitle' />
                    </Typography>

                    <div>
                        <div className={listItemWrapper}>
                            <img className={listItemIcon} src={pageImages[0]} alt='Weights' />
                            <Typography className={listItemDescription} variant={TypographyVariants.h3}>
                                <Trans i18nKey='upgrade-plan-modal-what-you-will-get-science-backed-program-list-item-text' />
                            </Typography>
                        </div>

                        <div className={listItemWrapper}>
                            <img className={listItemIcon} src={pageImages[1]} alt='Coach' />
                            <Typography className={listItemDescription} variant={TypographyVariants.h3}>
                                <Trans i18nKey='upgrade-plan-modal-what-you-will-get-coach-list-item-text' />
                            </Typography>
                        </div>

                        <div className={listItemWrapper}>
                            <img className={listItemIcon} src={pageImages[2]} alt='Cutlery' />
                            <Typography className={listItemDescription} variant={TypographyVariants.h3}>
                                <Trans i18nKey='upgrade-plan-modal-what-you-will-get-customized-diet-list-item-text' values={{ exercisePlan }} />
                            </Typography>
                        </div>

                        <div className={listItemWrapper}>
                            <img className={listItemIcon} src={pageImages[3]} alt='Graph' />
                            <Typography className={listItemDescription} variant={TypographyVariants.h3}>
                                <Trans i18nKey='upgrade-plan-modal-what-you-will-get-everything-list-item-text' />
                            </Typography>
                        </div>
                    </div>
                </div>

                <div className={infoWrapper}>
                    <Typography className={infoText} variant={TypographyVariants.h3}>
                        <Trans i18nKey='upgrade-plan-skip-trial-modal-window-info-text' values={{ monthsCountToReachGoalWeight, adjustedGoalWeight, unit }} />
                    </Typography>
                </div>

                <div className={buttonsWrapper}>
                    <NextButton
                        typographyText={t('upgrade-plan-skip-trial-modal-window-button-skip')}
                        buttonWrapperClassName={skipButtonWrapper}
                        className={skipButton}
                        onClick={handleSkipClick} />

                    <NextButton
                        typographyText={t('upgrade-plan-skip-trial-modal-window-button-start', { subscriptionPeriod: subscriptionPeriodValue })}
                        buttonWrapperClassName={startButtonWrapper}
                        className={startButton}
                        onClick={handleUpgradeClick} />
                </div>
            </ModalWindow>}
        </>
    );
};
