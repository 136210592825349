import React from 'react';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';

export const GlassesWaterTip = () => {

    const description = ['do-not-drink-any', '1-4-glasses'].includes(localStorage.getItem(LocalStorageKeys[RoutePath.GlassesWater]) as string)
        ? 'glasses-water-tip-few-description'
        : 'glasses-water-tip-normal-description';

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/glass-water.svg'
            text={{
                description: description,
                next: 'glasses-water-tip-button-next'
            }}
        />
    );
};
