import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { Typography } from 'components/typography';

import { TypographyVariants } from 'constants/typography-variants';

import { useStyles } from './styles';

export const WeightLossChart: FC = () => {
    const { t } = useTranslation();
    const {
        graphicImage,
        graphicSubTitleWrapper,
        graphicSubTitle,
    } = useStyles();

    const pageImages = useBackgroundImageService(['svg/weight-loss/weight-loss-graphic.svg']);

    return (
        <>
            <img src={pageImages[0]} alt='Weight Loss Chart' className={graphicImage} />
            <div className={graphicSubTitleWrapper}>
                <Typography variant={TypographyVariants.h3} className={graphicSubTitle}>
                    {t('weight-loss-month-one')}
                </Typography>
                <Typography variant={TypographyVariants.h3} className={graphicSubTitle}>
                    {t('weight-loss-month-six')}
                </Typography>
            </div>
        </>
    );
}
